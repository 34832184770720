import React,{useState} from 'react';
import logo from '../assets/images/logo/logo.png';
import { useWeb3React } from "@web3-react/core";
import { Link } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link';
import {SiteName} from '../config';
import {getNetworkIcon} from '../utils/contractHelpers'
import NetworkSwitchModal from './NetworkSwitchModal'; // Adjust the path as needed
const Header = ({isSetOpen}) => {
const { active, deactivate,account,chainId } = useWeb3React()


const [sideBar, setSideBar] = useState(false);


const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedNetwork, setSelectedNetwork] = useState(56);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleSwitchNetwork = (chainId) => {
    // Perform actions to switch the network
    // For example, you might update the connected network state in your Web3 context
    setSelectedNetwork(chainId);
    // Additional logic for switching the network
  };








const handleClick = () => {
if(sideBar){
setSideBar(false);
document.getElementById('menutoggle').click();
} 
};


return (
<header className="header-section">
<div className="container">
<div className="header-holder">
<div className="header-primary d-flex flex-wrap justify-content-between align-items-center">
<div className="brand-logo d-none d-lg-inline-block">
<div className="logo">
<Link to="/"><img src={logo} alt={SiteName} /></Link>
</div>
</div>
<div className="header-wrapper justify-content-lg-end">
<div className="mobile-logo d-lg-none">
<Link to="/"><img src={logo} alt={SiteName} /></Link>
</div>
<div className="menu-area">
<ul className="menu">
<li><Link onClick={handleClick} to="/">Home</Link></li>
<li><Link onClick={handleClick} to="/project">Projects</Link></li>
<li>
<a href="javascript:void(0);">Staking</a>
<ul class="submenu">
<li><Link onClick={handleClick} to="/staking">Staking</Link></li>
<li><Link onClick={handleClick} to="/farming">Farming</Link></li>
</ul>
</li>

<li><a href="https://snapshot.org/#/torkpad.eth" target="_blank" rel="noopener noreferrer">DAO-Voting</a></li>

{/*<li><HashLink onClick={handleClick} to="/#tier">Tier</HashLink></li>
<li><HashLink onClick={handleClick} to="/#tokenomics">Tokenomics</HashLink></li>
<li><HashLink onClick={handleClick} to="/#roadmap">Roadmap</HashLink></li>
*/}
<li><Link onClick={handleClick} to="/blog">Blog</Link></li>
<li><Link onClick={handleClick} to="/schedule">Schedule</Link></li>
</ul>

{active ?(
    <>
<img  onClick={handleOpenModal} src={getNetworkIcon(chainId)} style={{width: '25px'}} alt={chainId} />



      <NetworkSwitchModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        onSwitchNetwork={handleSwitchNetwork}
      />




<a href={void(0)} className="wallet-btn" onClick={isSetOpen}>
<span>{account.substring(0, 2)}...{account.substring(account.length - 4)}
</span> <i className="fa-solid fa-wallet" />

{/*
<div className="rounded-lg flex items-center">
<div className="mr-3 flex flex-col leading-tight">
<span>0 BNB</span>
<span>
<span className="text-white">0 TPAD</span>
</span>
</div>
<button type="button" className="p-0 m-0 mr-3 flex items-center outline-none btn btn-dark-outline">
<span className="UserWallet_address badge badge-pill badge-secondary">
<span>{account.substring(0, 2)}...{account.substring(account.length - 4)}
</span> <i className="fa-solid fa-copy" />
</span>
</button>
<i className="fa-solid fa-wallet" />
</div>
*/}





</a>
</>
):(
<a href={void(0)} className="wallet-btn" onClick={isSetOpen}>
<span>Connect
</span> <i className="fa-solid fa-wallet" />
</a>
)}






<div id="menutoggle"  onClick={() => setSideBar(true)}  className="header-bar d-lg-none">
<span />
<span />
<span />
</div>
</div>
</div>
</div>
</div>
</div>
</header>


)
}


export default Header;
