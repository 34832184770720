import React,{Fragment, useState, useEffect} from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { useWeb3React } from "@web3-react/core";
import './App.css';
import './assets/css/bootstrap.min.css';
import './assets/css/aos.css';
import './assets/css/swiper-bundle.min.css';
import './assets/css/style.css';
import '@fortawesome/fontawesome-free/css/all.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AOS from "aos";
import { connectors } from "./connectors/index";
import PhishingWarning from './components/PhishingWarning';
import Loader from './components/Loader';
import Header from './components/Header';
import Footer from './components/Footer';
import SelectWalletModal from './components/WalletModal';
import SelectInfoModal from './components/InfoModal';
import Home from './pages/Home';
import Project from './pages/Project';
import ProjectDetails from './pages/ProjectDetails';
import ProjectDetails2 from './pages/ProjectDetails2';
import Staking from './pages/Staking';
import Mining from './pages/Mining';
import Blog from './pages/Blog';
import Kyc from './pages/Kyc';
import Contact from './pages/Contact';
import Claims from './pages/Claims';
import Account from './pages/Account';
import Calendar from './pages/Calendar';
/*
import ApplyIgo from './pages/ApplyIgo';
import Roadmap from './pages/Roadmap';
import Teamdetails from './pages/Teamdetails';
import RankingIgo from './pages/RankingIgo';
import Farm from './pages/Farm';
import SignUp from './pages/SignUp';
import Signin from './pages/Signin';
import Forget from './pages/Forget';
import Leaderboard from './pages/Leaderboard';
import ComingSoon from './pages/ComingSoon';
*/


import Maintenance from './pages/Maintenance';
const App = () => {
const [maintenanceMode] = useState(process.env.REACT_APP_MAINTENANCE === 'true');
AOS.init();
const [modal, setModal] = useState(false);
const toggle = () => setModal(!modal);
const {library,activate,deactivate,active} = useWeb3React();

async function disconnect() {
  try {
  deactivate()
  toggle()
  window.localStorage.removeItem("provider");
  } catch (ex) {
 // console.log(ex)
  }
}


const [ spinner, setSpinner ] = useState(true);
useEffect(() => {
setTimeout(() => setSpinner(false), 1000)
const provider = window.localStorage.getItem("provider");
if (provider) activate(connectors[provider]);
}, []);


return (
<Router>
<Switch>
{maintenanceMode ? (
<Route path="/" component={Maintenance} />
) : (
<Fragment>
{spinner && <Loader/>}
<PhishingWarning/>
<Header isSetOpen={toggle} />
<Route path="/" exact component={Home} />
<Route path="/project" exact component={Project} />
<Route path="/pool/:id" exact component={ProjectDetails} />
<Route path="/staking" exact component={Staking} />
<Route path="/farming" exact component={Mining} />
<Route path="/blog" exact component={Blog} />
<Route path="/kyc" exact component={Kyc} />
<Route path="/claim" exact component={Claims} />
<Route path="/my-account" exact component={Account} />
<Route path="/schedule" exact component={Calendar} />
{/*
<Route path="/pool2/:id" exact component={ProjectDetails2} />
<Route path="/contact" exact component={Contact} />
<Route path="/igo-apply" exact component={ApplyIgo} />
<Route path="/roadmap" exact component={Roadmap} />
<Route path="/teamdetails" exact component={Teamdetails} />
<Route path="/igo-ranking" exact component={RankingIgo} />
<Route path="/farm" exact component={Farm} />
<Route path="/signup" exact component={SignUp} />
<Route path="/signin" exact component={Signin} />
<Route path="/forget" exact component={Forget} />
<Route path="/leaderboard" exact component={Leaderboard} />
*/}

{!active && modal &&
<SelectWalletModal  isOpen={modal} isSetOpen={toggle} />
}
{active &&
<SelectInfoModal  isOpen={modal} isSetOpen={toggle} disconnect={disconnect} />
}
<ToastContainer />
<Footer/>
</Fragment>
)}
</Switch>
</Router>
);
}

export default App;



