export async function addTokenToMetamask(ethereum, address, symbol,decimals) {
  try {
      await ethereum.request({
        method: 'wallet_watchAsset',
        params: {
          type: 'ERC20',
          options: {
            address: address,
            symbol: symbol,
            decimals: decimals
          }
        }
      })
    } catch (error) {
     // console.log(error)
    }
  }