import React,{Fragment,useState,useEffect} from 'react';
import Details from '../components/Project/Details';
import {getProjectList} from '../utils/contractHelpers';
import { Redirect} from "react-router-dom";
import {Helmet} from "react-helmet";

export default function Project({match}) {
const [data, setData] = useState(null);
useEffect(() => {
const getProject = async () => {
const result = await getProjectList();

var item = result.find(item => item.params === match.params.id);

setData(item);
};
getProject();
}, []);

if (!data) return null;
//if (typeof data.saleAddress === 'string' && data.saleAddress.trim().length === 0) return <Redirect to="/project" />
return (
<Fragment>
<Helmet>
<title>{data.tokenName} sale on TorkPad, The BEST Multi-Chain Launchpad</title>
</Helmet>
<Details data={data} />
</Fragment>
)
}