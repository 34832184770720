import React,{Fragment} from 'react';
import bg from '../assets/images/footer/bg.png';

import logo from '../assets/images/logo/logo.png';
import bscscan from '../assets/images/footer/bsc.svg';
import coingecko from '../assets/images/footer/coingecko.svg';
import coinmarketcap from '../assets/images/footer/coinmarketcap.svg';
import cryptorank from '../assets/images/footer/cryptorank.png';
import geckoterminal from '../assets/images/footer/geckoterminal.png';

import {SiteName,Twitter,Telegram,TelegramAnn,Medium,Discord,Linkedin,Instagram,Facebook,Reddit,Docs,Contract,Github,CoinGecko,CoinMarketCap,cryptoRank,GeckoTerminal} from '../config';

const Footer = () => {
return (
    <Fragment>
<footer className="footer" style={{ backgroundImage: 'url(' + bg + ')' }}>
  <div className="footer__wrapper padding-top padding-bottom">
    <div className="container">
      <div className="footer__content text-center">
        <a className="mb-4 d-inline-block" href="/"><img src={logo} alt="Logo" /></a>
        <ul className="social justify-content-center">
        {Twitter &&
          <li className="social__item">
            <a href={Twitter} className="social__link" target="_blank" rel="noopener noreferrer"><i className="fab fa-twitter fa-spin" /></a>
          </li>
          }
           {Telegram &&
          <li className="social__item">
            <a href={Telegram} className="social__link" target="_blank" rel="noopener noreferrer"><i className="fab fa-telegram fa-spin" /></a>
          </li>
          }
           {TelegramAnn &&
          <li className="social__item">
            <a href={TelegramAnn} className="social__link" target="_blank" rel="noopener noreferrer"><i className="fab fa-telegram fa-spin" /></a>
          </li>
          }
          {Discord &&
          <li className="social__item">
            <a href={Discord} className="social__link" target="_blank" rel="noopener noreferrer"><i className="fab fa-discord fa-spin" /></a>
          </li>
          }
         
          {Medium &&
          <li className="social__item">
            <a href={Medium} className="social__link" target="_blank" rel="noopener noreferrer"><i className="fab fa-medium-m fa-spin" /></a>
          </li>
          }
          {Linkedin &&
          <li className="social__item">
            <a href={Linkedin} className="social__link" target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin-in fa-spin" /></a>
          </li>
          }
           {Instagram &&
          <li className="social__item">
            <a href={Instagram} className="social__link" target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram fa-spin" /></a>
          </li>
          }
          {Facebook &&
          <li className="social__item">
            <a href={Facebook} className="social__link" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook-f fa-spin" /></a>
          </li>
          }
          {Reddit &&
          <li className="social__item">
            <a href={Reddit} className="social__link" target="_blank" rel="noopener noreferrer"><i className="fab fa-reddit fa-spin" /></a>
          </li>
          }
           {Github &&
          <li className="social__item">
            <a href={Github} className="social__link" target="_blank" rel="noopener noreferrer"><i className="fa-brands fa-github fa-spin" /></a>
            </li>
          }
            {Docs &&
          <li className="social__item">
            <a href={Docs} className="social__link" target="_blank" rel="noopener noreferrer"><i className="fa fa-file fa-spin" /></a>
          </li>
          }

         {Contract &&
          <li className="social__item">
            <a href={Contract} className="social__link" target="_blank" rel="noopener noreferrer"><img  style={{width: '17px', WebkitFilter: 'grayscale(100%)', filter: 'grayscale(100%)'}} src={bscscan} className="fa fa-spin" /></a>
          </li>
          }

          {CoinGecko &&
          <li className="social__item">
            <a href={CoinGecko} className="social__link" target="_blank" rel="noopener noreferrer"><img  style={{ WebkitFilter: 'grayscale(100%)', filter: 'grayscale(100%)'}} src={coingecko} className="fa fa-spin" /></a>
          </li>
          }

          {CoinMarketCap &&
          <li className="social__item">
            <a href={CoinMarketCap} className="social__link" target="_blank" rel="noopener noreferrer"><img  style={{ WebkitFilter: 'grayscale(100%)', filter: 'grayscale(100%)'}} src={coinmarketcap} className="fa fa-spin" /></a>
          </li>
          }
{GeckoTerminal &&
          <li className="social__item">
            <a href={GeckoTerminal} className="social__link" target="_blank" rel="noopener noreferrer"><img  style={{ WebkitFilter: 'grayscale(100%)', filter: 'grayscale(100%)'}} src={geckoterminal} className="fa fa-spin" /></a>
          </li>
          }
{cryptoRank &&
          <li className="social__item">
            <a href={cryptoRank} className="social__link" target="_blank" rel="noopener noreferrer"><img  style={{ WebkitFilter: 'grayscale(100%)', filter: 'grayscale(100%)'}} src={cryptorank} className="fa fa-spin" /></a>
          </li>
          }






        </ul>
      </div>
    </div>
  </div>
  <div className="footer__copyright">
    <div className="container">
      <div className="text-center py-4">
        <p className=" mb-0">{SiteName} © {new Date().getFullYear()} | All Rights Reserved </p>
      </div>
    </div>
  </div>
</footer>

</Fragment>
)
}

export default Footer
