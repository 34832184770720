
export default [
    {
      'title': 'MoneyDefiSwap',
      'start': new Date(2023, 8, 29),
      'end': new Date(2023, 9, 1),
      'allDay': true,
      'image': '/assets/images/project/moneydefiswap/logo.jpg',
    },
    
  
    {
      'title': 'GameGem',
      'start': new Date(2023, 9, 2),
      'end': new Date(2023, 9, 3),
      'allDay': true,
      'image': '/assets/images/project/gamegem/logo.png',
    },
    {
      'title': 'PropelCrypto',
      'start': new Date(2023, 9, 2),
      'end': new Date(2023, 9, 3),
      'allDay': true,
      'image': '/assets/images/project/propelcrypto/logo.png',
    },
    {
      'title': 'GSV',
      'start': new Date(2023, 9, 4),
      'end': new Date(2023, 9, 6),
      'desc': 'GSV',
      'allDay': true,
      'image': '/assets/images/project/gvsglobalgroup/logo.png',
    },
   
    {
      'title': 'AI Coinova',
      'start': new Date(2023, 9, 6),
      'end': new Date(2023, 9, 7),
      'desc': 'AI Coinova',
      'allDay': true,
      'image': '/assets/images/project/aicoinova/logo.jpg',
    },



    {
      'title': 'Evelon',
      'start': new Date(2023, 9, 7),
      'end': new Date(2023, 9,8),
      'allDay': true,
      'image': '/assets/images/project/evelon/logo.jpg',
    }
    ,
   
    {
      'title': 'Vinuchain',
      'start': new Date(2023, 9, 10),
      'end': new Date(2023, 9, 12),
      'allDay': true,
      'image': '/assets/images/project/vinuchain/logo.png',
    }
    ,
    {
      'title': 'Cinemakoin',
      'start': new Date(2023, 9, 22),
      'end': new Date(2023, 9, 24),
      'allDay': true,
      'image': '/assets/images/project/cinemakoin/logo.png',
    },
    ,
    {
      'title': 'nBTC',
      'start': new Date(2023, 9, 25),
      'end': new Date(2023, 9, 27),
      'allDay': true,
      'image': '/assets/images/project/nbtc/logo.png',
    },
    {
      'title': 'MetaLink',
      'start': new Date(2023, 10, 6),
      'end': new Date(2023, 10, 8),
      'allDay': true,
      'image': '/assets/images/project/metalink/logo.png',
    },

    {
      'title': 'QuantumX',
      'start': new Date(2023, 10, 13),
      'end': new Date(2023, 10, 14),
      'allDay': true,
      'image': '/assets/images/project/quantumx/logo.png',
    },

    {
      'title': 'VefDefi',
      'start': new Date(2023, 10, 15),
      'end': new Date(2023, 10, 16),
      'allDay': true,
      'image': '/assets/images/project/vefdefi/logo.png',
    },



    {
      'title': 'HYME',
      'start': new Date(2023, 10, 26),
      'end': new Date(2023, 10, 28),
      'allDay': true,
      'image': '/assets/images/project/hymenetwork/logo.png',
    },


    {
      'title': 'WorldToken',
      'start': new Date(2023, 10, 20),
      'end': new Date(2023, 10, 21),
      'allDay': true,
      'image': '/assets/images/project/worldchallengegame/logo.png',
    },


    {
      'title': 'MYRA',
      'start': new Date(2023, 10, 24),
      'end': new Date(2023, 10, 26),
      'allDay': true,
      'image': '/assets/images/project/myra/logo.png',
    },



    {
      'title': 'DEMOS',
      'start': new Date(2023, 11, 5),
      'end': new Date(2023, 11, 6),
      'allDay': true,
      'image': '/assets/images/project/demos/logo.png',
    },
    {
      'title': 'GPTPlus',
      'start': new Date(2023, 11, 9),
      'end': new Date(2023, 11, 11),
      'allDay': true,
      'image': '/assets/images/project/gptplus/logo.png',
    },

    {
      'title': 'Cicca',
      'start': new Date(2023, 11, 6),
      'end': new Date(2023, 11, 7),
      'allDay': true,
      'image': '/assets/images/project/cicca/logo.png',
    },

    {
      'title': 'Satoshi Airlines',
      'start': new Date(2023, 11, 4),
      'end': new Date(2023, 11, 5),
      'allDay': true,
      'image': '/assets/images/project/satoshiair/logo.png',
    },
    {
      'title': 'PaisaPad',
      'start': new Date(2023, 11, 15),
      'end': new Date(2023, 11, 16),
      'allDay': true,
      'image': '/assets/images/project/paisapad/logo.png',
    },
    {
      'title': 'Volley',
      'start': new Date(2023, 11, 16),
      'end': new Date(2023, 11, 17),
      'allDay': true,
      'image': '/assets/images/project/volleytoken/logo.png',
    },
    {
      'title': 'Smay',
      'start': new Date(2023, 11, 17),
      'end': new Date(2023, 11, 18),
      'allDay': true,
      'image': '/assets/images/project/smay/logo.png',
    },

    {
      'title': 'Nova Star',
      'start': new Date(2023, 11, 18),
      'end': new Date(2023, 11, 19),
      'allDay': true,
      'image': '/assets/images/project/novastar/logo.png',
    },



    






  ]
  

