import React from 'react';
import {isMobile} from 'react-device-detect';
import metamask from '../assets/images/wallet/metamask.svg';
import coinbase from '../assets/images/wallet/coinbase.svg';
import trust from '../assets/images/wallet/trust.svg';
import wallet from '../assets/images/wallet/wallet-connect.svg';
import safepal from '../assets/images/wallet/safepal.svg';
import {toast } from 'react-toastify';

import { useWeb3React,UnsupportedChainIdError } from "@web3-react/core";
import { NoBscProviderError } from '@binance-chain/bsc-connector'
import {NoEthereumProviderError,UserRejectedRequestError as UserRejectedRequestErrorInjected,} from '@web3-react/injected-connector'
import {UserRejectedRequestError as UserRejectedRequestErrorWalletConnect,WalletConnectConnector} from '@web3-react/walletconnect-connector'

import { connectors } from "../connectors";

const WalletModal = ({ isOpen, isSetOpen }) => {
const { activate } = useWeb3React();
const login = async (type) => {
 const connector=connectors[type];
 window.localStorage.setItem("provider", type);
 if (connector) {

   activate(connector, async (error) => {
    window.localStorage.setItem("provider", type);
     if (error instanceof UnsupportedChainIdError) {
       toast.error('Unsupported Chain Id Error', {position: toast.POSITION.TOP_RIGHT});
       } else if (error instanceof NoEthereumProviderError || error instanceof NoBscProviderError) {
      toast.error('No provider was found', {position: toast.POSITION.TOP_RIGHT});
     } else if (error instanceof UserRejectedRequestErrorInjected || error instanceof UserRejectedRequestErrorWalletConnect) {
       if (connector instanceof WalletConnectConnector) {
       // const walletConnector = WalletConnectConnector
       connector.walletConnectProvider =  null;
       }
       toast.error('Please authorize to access your account', {position: toast.POSITION.TOP_RIGHT});
     } else {
      toast.error(error.message, {position: toast.POSITION.TOP_RIGHT});
     }
   })


  } else {
    toast.error("Can't find connector", {position: toast.POSITION.TOP_RIGHT});
  }
   isSetOpen();
};


return (
<div className={`wallet-modal modal fade ${isOpen ? 'show' : ''}`} aria-hidden="true" style={{display: `${isOpen ? 'block' : 'none'}`,}}>
  <div className="modal-dialog">
    <div className="modal-content">
      <div className="modal-header">
        <h4 className="modal-title" id="choose-wallet">Connect Your Wallet</h4>
        <button type="button" className="btn-close" onClick={isSetOpen} />
      </div>
      <div className="modal-body">
        <p>Please select a wallet from below to connect to this dapp</p>
        <ul className="wallet__list">
          <li className="wallet__list-item"><a href={void(0)} onClick={() => {
                login("injected");
              }}> <span><img src={metamask} alt="metamask" />
              </span> </a>
              </li>
          <li className="wallet__list-item"><a href={void(0)} onClick={() => {
    login("injected");
  }}> <span><img src={coinbase} alt="coinbase" />
              </span> </a>
              </li>
          <li className="wallet__list-item"><a href={void(0)} onClick={() => {
                login("injected");
              }}> <span><img src={trust} alt="trust" />
              </span></a></li>
      
        <li className="wallet__list-item"><a href={void(0)} onClick={() => {
    login("injected");
  }}> <span><img src={safepal} alt="safepal Wallet" /></span></a></li>
        

          <li className="wallet__list-item"><a href={void(0)} onClick={() => {
    login("walletConnect");
  }}> <span><img src={wallet} alt=" Wallet connect" />
              </span></a>
              </li>
        </ul>
    <p>Wallets are used to send, receive, and store digital assets like Ether/BNB. Wallets come in many forms. They are either built into your browser, an extension added to your browser, a piece of hardware plugged into your computer or even an app on your phone. For more information about wallets, see <a href="https://docs.ethhub.io/using-ethereum/wallets/intro-to-ethereum-wallets/" target="_blank" rel="noopener noreferrer">this explanation</a> .</p>
      </div>
    </div>
  </div>
</div>


)
}

export default WalletModal