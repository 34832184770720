import React, { useState,useEffect } from 'react';
import './Wallet.css';
import walletAbi from "../../../Abi/externalWalletAbi.json";
import contract from '../../../utils/walletContract';
import { customError } from '../../../utils/useWeb3ReactManager'
import { useWeb3React,useContract } from '../../../hooks/useContractHelper';
import {getContractAddress} from '../../../utils/contractHelpers';
import {toast } from 'react-toastify';

const { REACT_APP_CHAIN_ID } = process.env



const ExternalWalletForm = ({blockchain, onSubmit }) => {




  const {library, chainId,account,active } = useWeb3React();
  const walletContract = useContract(getContractAddress('wallet'),walletAbi);
  const [walletAddress, setWalletAddress] = useState('');
  const [isValidAddress, setIsValidAddress] = useState(true);
  let [isLoading,setIsLoading] = useState(false);
  const [chain, setChain] = useState(Number(REACT_APP_CHAIN_ID));
  const [checkboxChecked, setCheckboxChecked] = useState(false);



  useEffect(() => {
 if(account){
  getMyWalletInfo(blockchain);
 }
      
   
    }, [account])


const getMyWalletInfo = async (blockchain) => {
try {
var result=await contract.methods.walletCompleted(blockchain).call({ from: account.toString() });
if(result){
setWalletAddress(result)
setIsValidAddress(validateAddress(result));
}
} catch (error) {
//console.log(error,'err');
}
} 


      const handleChange = (e) => {
        const address = e.target.value;
        setWalletAddress(address);
        setIsValidAddress(validateAddress(address));
      };
    
      const validateAddress = (address) => {
        const tronAddressRegex = /^T[1-9A-HJ-NP-Za-km-z]{33}$/;
      
        return tronAddressRegex.test(address);
      };
    
      const handleCheckboxChange = (e) => {
        setCheckboxChecked(e.target.checked);
      };

  const handleSubmit = async(e) => {
    e.preventDefault();
    // Add your form submission logic here
    if (isValidAddress && walletAddress) {
      // Address is valid, proceed with form submission
     // console.log('Submitting form:', walletAddress);


      if(!active) return toast.error("Connect Your Wallet!", {position: toast.POSITION.TOP_RIGHT});
      if(active && chainId===chain)
      setIsLoading(true);
      try {
      var tx=await walletContract.setWallet(blockchain, walletAddress)
      let result = await tx.wait()
      if(result && result.status){
      setIsLoading(false);
       // Invoke the onSubmit callback
 if (typeof onSubmit === 'function') {
  onSubmit();
}
      return toast.success("TRX address set success!", {position: toast.POSITION.TOP_RIGHT});





 }
      } catch (error) {
      setIsLoading(false);
      toast.error(customError(error).replace("execution reverted:",""), {position: toast.POSITION.TOP_RIGHT});
      }


    } else {
      return toast.error('Invalid TRX address:', walletAddress, {position: toast.POSITION.TOP_RIGHT});
    }
  };

  return (
    <div className="external-wallet-form">
      <div className="external-wallet-bg">
        <form className="external-wallet-content">
          <div className="external-wallet-group">
            <label className="external-wallet-label" htmlFor="externalWallet">
              Your TRX wallet address for distribution
            </label>
            <input
              type="text"
              id="externalWallet"
              className={`external-wallet-input ${isValidAddress ? '' : 'invalid'}`}
              placeholder="Enter TRX address"
              value={walletAddress}
              onChange={handleChange}
              required
            />
            {!isValidAddress && <div className="external-wallet-feedback">Invalid Tron address.</div>}
            <small className="external-wallet-text">
              We recommend using the{' '}
              <a href="https://www.tokenpocket.pro" target="_blank" rel="noreferrer">
              TokenPocket
              </a>{' '}
              (desktop, mobile, chrome extension) or{' '}
              <a href="https://trustwallet.com/" target="_blank" rel="noreferrer">
                Trustwallet
              </a>{' '}
              (mobile) wallets.{' '}
              <a
                href="https://docs.torkpad.io/contributing-to-our-ido/instructions-for-purchasing-ido/how-to-set-up-a-tron-wallet-and-link-it-to-torkpad"
                target="_blank"
                rel="noreferrer"
              >
                Read more in our docs
              </a>
            </small>
          </div>
          <div className="external-wallet-check">
            <input type="checkbox" id="acceptExternal"  className="external-wallet-checkbox" required onChange={(e) => handleCheckboxChange(e)} />
            <label htmlFor="acceptExternal" className="external-wallet-check-label">
              I confirm providing a valid TRX address. I understand that giving a wrong wallet can result in funds loss.
            </label>
          </div>
          <button type="button" disabled={!checkboxChecked || !isValidAddress} onClick={(e) =>  handleSubmit(e)} className="external-wallet-button default-btn default-btn--small">
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default ExternalWalletForm;
