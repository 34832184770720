import React,{Fragment, useState,useEffect} from 'react';
import CountUp from 'react-countup';
import WalletCards from "../components/Project/Cards/Wallet";
import "./account.css";

const MyAccount = () => {
  return (
<Fragment>
  <div className="stacking padding-top padding-bottom">
    <div className="container">
      <div className="stacking__wrapper">
        <div className="stacking__project">
          <div className="row g-4">
            <div className="col-lg-6 col-sm-6">





            <div className="my-tier-container">
      <div className="my-tier-card">
        <div className="my-tier-card-body">
          <h3 className="my-tier-title mb-3 text-gray-100">Your Account</h3>
          <h4 className="my-tier-subtitle text-white text-lg rounded uppercase inline-block mb-0">Wallet address</h4>
          <div className="my-tier-flex-container">
            <button className="my-tier-button">
              <span className="my-tier-address">
                0x74c590F3b86caB418056Ab398835e993B706cC95
              </span>
              <span className="my-tier-copied">Copied</span>
            </button>
            <a href="https://bscscan.com/address/0x74c590F3b86caB418056Ab398835e993B706cC95" target="_blank" rel="noreferrer" className="my-tier-link">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 121.378 121.333" className="my-tier-svg">
                {/* SVG Path */}
              </svg>
            </a>
          </div>
        </div>
   
        <div className="my-tier-card-body">
          <div className="my-tier-content">
            <div className="my-tier-info">
              <div className="my-tier-info-text">0 TPAD</div>
              <div className="my-tier-info-text">Your level and multiplier</div>
            </div>
            <p className="my-tier-stake-text">
              Stake <b>3,000 TPAD more</b> to reach the next level with <b>20% chance to get an allocation</b>.
            </p>
            <div className="my-tier-progress-bar">
              <div className="my-tier-progress-bar-fill" style={{ width: "0%" }}></div>
            </div>
            <div className="my-tier-chance-info">
              <div className="my-tier-chance-text">None</div>
              <div className="my-tier-chance-text">Chance</div>
            </div>
            <div className="my-tier-action">
              <div className="my-tier-action-text">0 TPAD</div>
              <div className="my-tier-action-button">
                <a href="https://pancakeswap.finance/swap?outputCurrency=0xADCFC6bf853a0a8ad7f9Ff4244140D10cf01363C" target="_blank" rel="noreferrer" className="my-tier-action-link">
                  Buy TPAD
                </a>
              </div>
              <div className="my-tier-action-text">3,000 TPAD</div>
            </div>
          </div>
        </div>
      </div>
    </div>









            </div>

            <div className="col-lg-6 col-sm-6">

<div className="my-account-card-body">
  <h3 className="my-account-heading">Your Wallets</h3>
  <div className="my-account-wallet">
    <div className="my-account-wallet-header">
      <h4 className="my-account-wallet-title">SOL wallet</h4>
      <button type="button" className="my-account-btn">
        <svg className="my-account-btn-icon" stroke="currentColor" fill="currentColor" strokeWidth={0} viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
          <path d="M290.74 93.24l128.02 128.02-277.99 277.99-114.14 12.6C11.35 513.54-1.56 500.62.14 485.34l12.7-114.22 277.9-277.88zm207.2-19.06l-60.11-60.11c-18.75-18.75-49.16-18.75-67.91 0l-56.55 56.55 128.02 128.02 56.55-56.55c18.75-18.76 18.75-49.16 0-67.91z" />
        </svg>
      </button>
    </div>
    <div className="my-account-wallet-info">
      <div className="my-account-wallet-label">Wallet Address:</div>
      <a href="#" className="my-account-wallet-address">7xKXtg2CW87d97TXJSDpbD5jBkheTqA83TZRuJosgAsU</a>
      <a href="https://solscan.io/address/7xKXtg2CW87d97TXJSDpbD5jBkheTqA83TZRuJosgAsU" target="_blank" rel="noreferrer" className="my-account-wallet-link">
        <svg className="my-account-wallet-link-icon" viewBox="0 -70 520 550" xmlns="http://www.w3.org/2000/svg">
          <path d="M84.8049 312.174C87.9978 308.984 92.3267 307.192 96.8404 307.192H513.301C520.887 307.192 524.685 316.366 519.318 321.728L437.027 403.954C433.834 407.145 429.505 408.937 424.991 408.937H8.53087C0.944258 408.937 -2.85358 399.763 2.5131 394.4L84.8049 312.174Z" fill="#00FFA3" />
          <path d="M84.8049 4.98247C87.9978 1.79211 92.3267 0 96.8404 0H513.301C520.887 0 524.685 9.17394 519.318 14.5363L437.027 96.7626C433.834 99.9529 429.505 101.745 424.991 101.745H8.53087C0.944258 101.745 -2.85358 92.5711 2.5131 87.2087L84.8049 4.98247Z" fill="#00FFA3" />
          <path d="M437.027 157.6C433.834 154.41 429.505 152.618 424.991 152.618H8.53087C0.944256 152.618 -2.85358 161.791 2.5131 167.154L84.8049 249.38C87.9978 252.57 92.3267 254.363 96.8404 254.363H513.301C520.887 254.363 524.685 245.189 519.318 239.826L437.027 157.6Z" fill="#00FFA3" />
        </svg>
      </a>
    </div>
  </div>
  <div className="my-account-wallet">
    <div className="my-account-wallet-header">
      <h4 className="my-account-wallet-title">TERRA wallet</h4>
      <button type="button" className="my-account-btn">
        <svg className="my-account-btn-icon" stroke="currentColor" fill="currentColor" strokeWidth={0} viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
          <path d="M290.74 93.24l128.02 128.02-277.99 277.99-114.14 12.6C11.35 513.54-1.56 500.62.14 485.34l12.7-114.22 277.9-277.88zm207.2-19.06l-60.11-60.11c-18.75-18.75-49.16-18.75-67.91 0l-56.55 56.55 128.02 128.02 56.55-56.55c18.75-18.76 18.75-49.16 0-67.91z" />
        </svg>
      </button>
    </div>
    <i className="my-account-wallet-not-specified">Not specified</i>
  </div>



  <WalletCards/>


</div>


            </div>
          </div>
        </div>
        <div className="stacking__details">
          <div className="stacking__title">
            <h3>Your History</h3>
          </div>
          <div className="stacking__content">
            <div className="row align-items-center g-5">
       
            </div>
            
          </div>
        </div>
      </div>
    </div>
  </div>
</Fragment>
  );
};

export default MyAccount;