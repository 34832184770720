import React, { useState, useRef, useEffect } from "react";
import "./BuyChart.css";
import geckoterminal from '../../../assets/images/explorer/geckoterminal.svg';


const BuyChart = ({ buyLinks,chartLinks }) => {


  const [dropdownVisible, setDropdownVisible] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownVisible(false);
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  return (
    <div className="buy-chart-container">
      <div className="buy-chart-bg">
        <div className="buy-chart-flex-container">

          {buyLinks.map((link, index) => (
              <a
                key={index}
                href={link.link}
                target="_blank"
                rel="noopener noreferrer"
                className="buy-chart-btn buy-chart-btn-buy"
              >
                {link.icon && (
                  <img
                    src={link.icon}
                    alt={link.name}
                    className="buy-chart-icon"
                  />
                )}
                {link.description}
              </a>
            ))}

          <div className="buy-chart-dropdown" ref={dropdownRef}>
            <button
              className="buy-chart-btn buy-chart-btn-chart"
              onClick={toggleDropdown}
            >
              <img
                src={geckoterminal}
                alt="Coingecko Terminal"
                className="buy-chart-icon"
              />
              Chart
            </button>
            <div
              className={`buy-chart-dropdown-menu ${
                dropdownVisible ? "" : "hidden"
              }`}
            >



{chartLinks.map((link, index) => (
              <a
                key={index}
                href={link.link}
                target="_blank"
                rel="noopener noreferrer"
                className="buy-chart-menu-item"
              >
                {link.icon && (
                  <img
                    src={link.icon}
                    alt={link.name}
                    className="buy-chart-icon"
                  />
                )}
                {link.name}
              </a>
            ))}
 </div>
          </div>
         </div>
      </div>
    </div>
  );
};

export default BuyChart;
