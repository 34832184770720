import React,{Fragment} from 'react';
import {SiteName,TokenSymbol} from '../../config';


import benifit1 from '../../assets/images/benifit/01.png';

import benifit2 from '../../assets/images/benifit/02.png';

import benifit3 from '../../assets/images/benifit/03.png';

import benifit4 from '../../assets/images/benifit/04.png';




function Benifit() {
return (
<Fragment>
<section className="benifit padding-top padding-bottom bg--primary-color">
    <div className="container">
      <div className="section-header section-header--middle">
        <div className="section-header__content">
          <div className="section-header__titlebar">
            <p className="section-header__subtitle"> Benifits</p>
            <h2 className="section__header__title">What We Offer</h2>
          </div>
        </div>
      </div>
      <div className="benifit__wrapper">
        <div className="row g-5">
          <div className="col-lg-3 col-sm-6">
            <div className="benifit__item">
              <div className="benifit__item-inner">
                <div className="benifit__item-thumb">
                  <img src={benifit1} alt="Farming" />
                </div>
                <div className="benifit__item-content">
                  <h4>Farming</h4>
                  <p>Participants in liquidity mining earn large mining rewards.
They are the highest level of membership and have access to IDOs. </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6">
            <div className="benifit__item">
              <div className="benifit__item-inner">
                <div className="benifit__item-thumb">
                  <img src={benifit2} alt="Staking" />
                </div>
                <div className="benifit__item-content">
                  <h4>Staking</h4>
                  <p>Longer periods of holding {TokenSymbol} tokens in the staking contract earn higher allocations and staking rewards.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6">
            <div className="benifit__item">
              <div className="benifit__item-inner">
                <div className="benifit__item-thumb">
                  <img src={benifit3} alt="Anti Whale" />
                </div>
                <div className="benifit__item-content">
                  <h4>Whale haters</h4>
                  <p>
                  1% Max Wallet/Max TX so that our community can trade without fear of being dumped on by whales. 
                    </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6">
            <div className="benifit__item">
              <div className="benifit__item-inner">
                <div className="benifit__item-thumb">
                  <img src={benifit4} alt="Fair & Square" />
                </div>
                <div className="benifit__item-content">
                  <h4>Fair & Square</h4>
                  <p>We recognise that opportunities must be distributed fairly and efficiently.
We make certain that everyone has an equal opportunity to join based on their tier without a gas war.
Higher tiers will have the opportunity to purchase first. </p>
                </div>
              </div>
            </div>
          </div>




          <div className="col-lg-3 col-sm-6">
            <div className="benifit__item">
              <div className="benifit__item-inner">
                <div className="benifit__item-thumb">
                  <img src={benifit4} alt="Mechanisms" />
                </div>
                <div className="benifit__item-content">
                  <h4>Mechanisms</h4>
                  <p>There will be no minting or inflationary mechanisms.
Smart Contract Verified by Open Source. </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6">
            <div className="benifit__item">
              <div className="benifit__item-inner">
                <div className="benifit__item-thumb">
                  <img src={benifit3} alt="Tax" />
                </div>
                <div className="benifit__item-content">
                  <h4>Tax</h4>
                  <p>There is no absurdly high tax - 8% tax in and 8% tax out.
Carefully divided in order to ensure {SiteName}'s success. </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6">
            <div className="benifit__item">
              <div className="benifit__item-inner">
                <div className="benifit__item-thumb">
                  <img src={benifit2} alt="Auto-added Liquidity" />
                </div>
                <div className="benifit__item-content">
                  <h4>Auto-added Liquidity</h4>
                  <p>To ensure price stability, 2% of all traded volume will be automatically added to the liquidity pool. </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6">
            <div className="benifit__item">
              <div className="benifit__item-inner">
                <div className="benifit__item-thumb">
                  <img src={benifit1} alt="Burns" />
                </div>
                <div className="benifit__item-content">
                  <h4>Burns</h4>
                  <p>Burns will receive 0.5% of all volume traded, implying that supply will only ever decrease. </p>
                </div>
              </div>
            </div>
          </div>








        </div>
      </div>
    </div>
  </section>
</Fragment>
)
}

export default Benifit
