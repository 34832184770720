import React,{Fragment} from 'react';
import { Link } from 'react-router-dom'
import {Helmet} from "react-helmet";
import bg from '../assets/images/error/coming.png';
import logo from '../assets/images/logo/logo.png';
function Maintenance() {
  

return (
<Fragment>
<Helmet>
<title>Site Maintenance</title>
</Helmet>
<div className="coming-soon" style={{backgroundImage: 'url(' + bg + ')'}}>
  <div className="container-fluid">
    <div className="coming-soon__wrapper text-center">
      <div className="coming-soon__content">
        <Link className="mb-4 d-inline-block" to="/"><img src={logo} alt="TorkPad logo" /></Link>
        <h2>We&rsquo;ll be back soon!</h2>
        <p>Sorry for the inconvenience but we&rsquo;re performing some maintenance at the moment. If you need to you can always <a href="mailto:hello@torkpad.io">contact us</a>, otherwise we&rsquo;ll be back online shortly!</p>
        <p>&mdash; The Team</p>
      </div>
    </div>
  </div>
</div>
</Fragment>
)
}

export default Maintenance