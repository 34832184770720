import React,{Fragment} from 'react';
import One from '../../assets/images/work/1.png';
import two from '../../assets/images/work/2.png';
import three from '../../assets/images/work/3.png';
import { Link } from 'react-router-dom'
import {TokenSymbol,TokenName} from '../../config';
function Work() {
return (
    <Fragment>

<section className="work padding-top padding-bottom bg--primary-color">
    <div className="container">
      <div className="section-header section-header--middle">
        <div className="section-header__content">
          <div className="section-header__titlebar">
            <p className="section-header__subtitle"> Getting Start </p>
            <h2 className="section__header__title"> Simple as 1,2,3</h2>
          </div>
        </div>
      </div>
      <div className="work__wrapper">
        <div className="row  justify-content-center g-4">
          <div className="col-lg-4 col-sm-6">
            <div className="work__item">
              <div className="work__item-inner">
                <div className="work__item-thumb">
                  <img src={One} alt="work Step 1" />
                </div>
                <div className="work__item-content">
                  <h4>1.Purchase {TokenSymbol} Token</h4>
                  <p>{TokenName}'s token, {TokenSymbol}, allows holders to participate in IDOs, IGOs, stake, and farm for passive income.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div className="work__item">
              <div className="work__item-inner">
                <div className="work__item-thumb">
                  <img src={two} alt="work Step 2" />
                </div>
                <div className="work__item-content">
                  <h4>2.Farm or stake your {TokenSymbol}</h4>
                  <p>Join one of our staking or farming pools to earn passive income with your {TokenSymbol}.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div className="work__item">
              <div className="work__item-inner">
                <div className="work__item-thumb">
                  <img src={three} alt="work Step 3" />
                </div>
                <div className="work__item-content">
                  <h4>3.Complete the KYC process</h4>
                  <p>It is a simple step to ensure your involvement in IDOs or IGOs.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
       {/*
        <div className="text-center mt-5">
          <Link to={`/`} className="disabled default-btn"><span>Verify KYC</span></Link>
        </div> */}
      </div>
    </div>
  </section>
    </Fragment>


)
}

export default Work
