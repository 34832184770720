import React,{Fragment} from 'react';
import {TokenSymbol} from '../../config';
import tier1 from '../../assets/images/tier/01.png';
import tier2 from '../../assets/images/tier/02.png';
import tier3 from '../../assets/images/tier/03.png';
import tier4 from '../../assets/images/tier/04.png';


function Tier() {
return (
<Fragment>
<div id="tier" className="tier padding-top padding-bottom">
    <div className="container">
      <div className="section-header section-header--middle">
        <div className="section-header__content">
          <div className="section-header__titlebar">
            <p className="section-header__subtitle"> Choose</p>
            <h2 className="section__header__title"> Tier System</h2>
          </div>
        </div>
      </div>
      <div className="section__wrapper">
        <div className="row g-4 justify-content-center row-cols-xl-4 row-cols-lg-3 row-cols-sm-2 row-cols-1">
          <div className="col">
            <div className="tier__item">
              <div className="tier__inner">
                <div className="tier__head">
                  <h4>TIER 1</h4>
                  <div className="tier__thumb">
                    <img src={tier1} alt="Icon" />
                  </div>
                </div>
                <div className="tier__body">
                  <h4>Basic</h4>
                  <ul>
                    <li>
                      <p className="tier__title">Staking Requirements</p>
                      <p className="tier__value">20,000 {TokenSymbol}</p>
                    </li>
                    <li>
                      <p className="tier__title">Allocation Type</p>
                      <p className="tier__value">Lottery (100 Among all)</p>
                    </li>
                    <li>
                      <p className="tier__title">Pool Weight</p>
                      <p className="tier__value">05</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="tier__item">
              <div className="tier__inner">
                <div className="tier__head">
                  <h4>Tier 2</h4>
                  <div className="tier__thumb">
                    <img src={tier2} alt="Icon" />
                  </div>
                </div>
                <div className="tier__body">
                  <h4>Bronze</h4>
                  <ul>
                    <li>
                      <p className="tier__title">Staking Requirements</p>
                      <p className="tier__value">30,000 {TokenSymbol}</p>
                    </li>
                    <li>
                      <p className="tier__title">Allocation Type</p>
                      <p className="tier__value">Whitelist</p>
                    </li>
                    <li>
                      <p className="tier__title">Pool Weight</p>
                      <p className="tier__value">10</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="tier__item">
              <div className="tier__inner">
                <div className="tier__head">
                  <h4>Tier 3</h4>
                  <div className="tier__thumb">
                    <img src={tier3} alt="Icon" />
                  </div>
                </div>
                <div className="tier__body">
                  <h4>Gold</h4>
                  <ul>
                    <li>
                      <p className="tier__title">Staking Requirements</p>
                      <p className="tier__value">40,000 {TokenSymbol}</p>
                    </li>
                    <li>
                      <p className="tier__title">Allocation Type</p>
                      <p className="tier__value">Guaranteed</p>
                    </li>
                   <li>
                      <p className="tier__title">Pool Weight</p>
                      <p className="tier__value">15</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="tier__item">
              <div className="tier__inner">
                <div className="tier__head">
                  <h4>Tier 4 </h4>
                  <div className="tier__thumb">
                    <img src={tier4} alt="Icon" />
                  </div>
                </div>
                <div className="tier__body">
                  <h4>Platinum</h4>
                  <ul>
                    <li>
                      <p className="tier__title">Staking Requirements</p>
                      <p className="tier__value">60,000 {TokenSymbol}</p>
                    </li>
                    <li>
                      <p className="tier__title">Allocation Type</p>
                      <p className="tier__value">Guaranteed</p>
                    </li>
                    <li>
                      <p className="tier__title">Pool Weight</p>
                      <p className="tier__value">25</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</Fragment>
)
}

export default Tier
