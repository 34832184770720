import React from 'react';
import {toast } from 'react-toastify';
import copy from "copy-to-clipboard";

import { useWeb3React } from "@web3-react/core";

const InfoModal = ({ isOpen, isSetOpen,disconnect }) => {
const { account } = useWeb3React();


    const copyToClipboard = () => {
       copy(account);
       toast.success(`You have copied "${account}"`, {position: toast.POSITION.TOP_RIGHT});
    }


return (
<div className={`wallet-modal modal fade ${isOpen ? 'show' : ''}`} aria-hidden="true" style={{display: `${isOpen ? 'block' : 'none'}`,}}>
  <div className="modal-dialog">
    <div className="modal-content">
      <div className="modal-header">
        <h4 className="modal-title" id="choose-wallet">Your wallet</h4>
        <button type="button" className="btn-close" onClick={isSetOpen} />
      </div>
      <div className="modal-body">

    <div className="mb-3 text-center">
      <h5 style={{fontSize: '17px', wordBreak: 'break-word'}} className="mb-2">
        <b>{account}</b>
        </h5>
        <a href={`https://bscscan.com/address/${account}`} target="_blank" rel="noopener noreferrer" className="me-4" style={{textDecoration: 'none'}}>
          <span className="me-1">View on BscScan</span>
          <i className="fa fa-arrow-up-right-from-square" />
          </a>
 
          <a href={void(0)} onClick={(e) => copyToClipboard()} style={{textDecoration: 'none'}}>
            <span className="me-1">Copy Address</span>
            <i className="fa-solid fa-copy" />
            </a>

            </div>

              <div className="text-center mt-4">
               <button className="default-btn default-btn--small" onClick={(e) => disconnect()} data-bs-dismiss="modal">Logout</button>
              </div>


     
</div>
    </div>
  </div>
</div>


)
}

export default InfoModal