
import Web3 from 'web3';
import moment from 'moment';
import { BigNumber } from 'bignumber.js';
import farmAbi from "../Abi/farmingAbi.json";
import bep20Abi from "../Abi/bep20Abi.json";
import pancakeAbi from "../Abi/pancakeAbi.json";
import saleAbi from "../Abi/Sale.json";
import linearVestingAbi from "../Abi/linearVesting.json";
import regularVestingAbi from "../Abi/regularVesting.json";
//import {baseURL,partnerURL} from "../config";
import web3New from './web3';
import { getWeb3Instance } from './web3Provider';
import axios from 'axios';
import fs from 'fs';
import { ethers } from 'ethers';

import {projects} from '../data';
import {partners} from '../database/Partners';
import {featured} from '../featured';
import bscImg from '../assets/images/chain/bnb.svg';
import ethImg from '../assets/images/chain/eth.svg';
import solanaImg from '../assets/images/chain/solana.svg';
import avaxImg from '../assets/images/chain/avax.svg';
import polygonImg from '../assets/images/chain/polygon.svg';
import aptosImg from '../assets/images/chain/aptos.png';
import arbitrumImg from '../assets/images/chain/arbitrum.png';
import cronosImg from '../assets/images/chain/cronos.svg';
import fantomImg from '../assets/images/chain/fantom.svg';
import algorandImg from '../assets/images/chain/algorand.svg';
import nearImg from '../assets/images/chain/near.svg';
import atomImg from '../assets/images/chain/atom.svg';
import stellarImg from '../assets/images/chain/stellar.png';
import tronImg from '../assets/images/chain/tron.svg';
import ozoneImg from '../assets/images/chain/ozone.svg';
import suiImg from '../assets/images/chain/sui.svg';
import cantoImg from '../assets/images/chain/canto.svg';
import egonImg from '../assets/images/chain/egon.png';


import {ethNetwork,bscNetwork,polygonNetwork,aptosNetwork,solNetwork,arbitrumNetwork,avalNetwork,cronosNetwork,fantomNetwork,algorandNetwork,nearNetwork,atomNetwork,stellarNetwork,tronNetwork,ozoneNetwork,suiNetwork,cantoNetwork,egonNetwork,


  PUBLIC,PRIVATE,WHITELIST,GUARANTEED,EXPLORER_URLS} from '../config';



const { REACT_APP_CHAIN_ID,REACT_APP_TOKEN_ADDRESS,REACT_APP_STAKE_ADDRESS,REACT_APP_FARM_ADDRESS,REACT_APP_LIQUIDITY_ADDRESS,REACT_APP_KYC_ADDRESS,REACT_APP_BUSD_ADDRESS,REACT_APP_USDT_ADDRESS,REACT_APP_NETWORK_URL,REACT_APP_MULTICALL_ADDRESS,REACT_APP_EXTERNAL_WALLET_ADDRESS,REACT_APP_MULTI_SENDER_ADDRESS,REACT_APP_BNB_ADDRESS,REACT_APP_PANCAKESWAP_ADDRESS } = process.env


export const getPoolData = async (saleAddress) => {
const contract = new web3New.eth.Contract(saleAbi, saleAddress);
const [totalRaise,getContributorCount] = await Promise.all([
  contract.methods.totalRaise().call(),
  contract.methods.getContributorCount().call()
]);
return [totalRaise,getContributorCount];
}


export  const calculateNextClaimTime2 = ({amount, claimed, startTime, vestingPeriod, cliff, initialRelease}) => {
    var currentTime = moment().unix();
    if (currentTime < startTime) {
        return startTime;
    } else if (currentTime >= vestingPeriod) {
        return currentTime;
    } else if (currentTime < startTime + cliff) {
        return startTime + cliff;
    } else {
        const elapsedTime = currentTime - startTime;
        const remainingAmount = amount - claimed;
        const initialReleaseAmount = initialRelease;
        const claimableAmount = ((elapsedTime - cliff) / (vestingPeriod - startTime - cliff)) * remainingAmount;
        const totalClaimableAmount = initialReleaseAmount + claimableAmount;
        const nextClaimTimeAfterCliff = startTime + cliff + (totalClaimableAmount / remainingAmount) * (vestingPeriod - startTime - cliff);
        return Math.min(vestingPeriod, nextClaimTimeAfterCliff);
    }
}


export const calculateNextClaimTime = ({amount, claimed, startTime, vestingPeriod, cliff, initialRelease}) => {
  var currentTime = moment().unix();

  if (currentTime < startTime) {
      return startTime;
  } else if (currentTime >= vestingPeriod) {
      return currentTime;
  } else if (currentTime < startTime + cliff) {
      return startTime + cliff;
  } else {
      const elapsedTime = currentTime - startTime;
      const remainingAmount = amount - claimed;
      
      // Calculate the initial release amount
      const initialReleaseAmount = Math.min(initialRelease, claimed);

      // Calculate the remaining claimable amount after deducting the initial release
      const remainingClaimableAmount = Math.max(0, amount - claimed + initialReleaseAmount);

      // Calculate the claimable amount after considering the initial release
      const claimableAmount = Math.min((elapsedTime - cliff) / (vestingPeriod - startTime - cliff), 1) * remainingClaimableAmount;

      // Calculate the total claimable amount including the initial release
      const totalClaimableAmount = initialReleaseAmount + claimableAmount;

      // Calculate the next claim time after cliff
      const nextClaimTimeAfterCliff = startTime + cliff + (totalClaimableAmount / remainingAmount) * (vestingPeriod - startTime - cliff);

      return Math.min(vestingPeriod, nextClaimTimeAfterCliff);
  }
}




export const getClaimDatax = async (item,address) => {
  const web3claim = getWeb3Instance(item?.blockchain);
const {type,claimAddress}=item.vestingDetails;
const contractABI = type === 'linear' ? linearVestingAbi : regularVestingAbi ;
  const contract = new web3claim.eth.Contract(contractABI, claimAddress);
console.log(contract.methods);



  const [vestingSchedule] = await Promise.all([
    contract.methods.vestingSchedule(address).call(),
  //  contract.methods.calculateClaimableAmount(address).call()
  ]);
  return [vestingSchedule,vestingSchedule];
  }



export const getClaimData = async (item,address) => {
  const web3claim = getWeb3Instance(item?.blockchain);
const {type,claimAddress}=item.vestingDetails;
const contractABI = type === 'linear' ? linearVestingAbi : regularVestingAbi ;
  const contract = new web3claim.eth.Contract(contractABI, claimAddress);
  const [vestingSchedule,claimableAmount] = await Promise.all([
    contract.methods.vestingSchedule(address).call(),
    contract.methods.claimableAmount(address).call()
  ]);
  return [vestingSchedule,claimableAmount];
  }


export const getTotalRaise = async (saleAddress) => {
  const contract = new web3New.eth.Contract(saleAbi, saleAddress);
  const [totalRaise,getCurrentTime] = await Promise.all([
    contract.methods.totalRaise().call(),
    contract.methods.getCurrentTime().call()
  ]);
  return [totalRaise,Number(getCurrentTime)];
}


export const getMyContribution = async (saleAddress,address) => {
  const contract = new web3New.eth.Contract(saleAbi, saleAddress);
 return await contract.methods.contributionOf(address).call();
}


export const getTotalContributors = async (saleAddress,indexLast) => {
  const contract = new web3New.eth.Contract(saleAbi, saleAddress);
  return await contract.methods.getContributors(0,indexLast).call()
}

export const allowance = async (account,amount,erc20Address,type) => {
  let contractAddress=getContractAddress(type);
  var myContract=new web3New.eth.Contract(bep20Abi, erc20Address);
  let allowanc = await myContract.methods.allowance(account, contractAddress).call();
  if(Number(allowanc.toString())>=Number(amount)){
  return false;
  }else{
  return true;
  }
} 

    export const allowanceSale = async (account,amount,saleAddress,type) => {
      let erc20Address=getContractAddress(type);
      var myContract=new web3New.eth.Contract(bep20Abi, erc20Address);
      let allowanc = await myContract.methods.allowance(account, saleAddress).call();
      if(Number(allowanc.toString())>=Number(amount)){
      return false;
      }else{
      return true;
      }
      } 

  
          export const saleClaim = async (library,account,saleAddress) => {
            const web3 = new Web3(library);
            var myContract=new web3.eth.Contract(saleAbi, saleAddress);
    let encodedData = await myContract.methods.claim().encodeABI();
    let gas = await web3.eth.estimateGas({from: account, data:encodedData,to:saleAddress })
    return await myContract
    .methods
    .claim()
    .send({ from: account,gas: web3.utils.numberToHex(gas+150000)});
  } 
    

      export const fromWeiToNumber = (weiAMount) => {
        return Web3.utils.fromWei(String(weiAMount), 'ether');
        }
        export const fromEthToWei = (ethAMount) => {
            return Web3.utils.toWei(ethAMount, 'ether');
            }
            export const formatFixedNumber = (figure, decimals) => {
              if (!decimals) decimals = 5;
              var d = Math.pow(10,decimals);
              return (parseInt(figure*d)/d).toFixed(decimals);
              }


              export const shortAddress = (addr, start = 6, end = 4) =>
              `${addr.slice(0, start)}...${addr.slice(addr.length - end, addr.length)}`;
              
              
              export const isAddress = (address) => {
              return Web3.utils.isAddress(address);
              }
              
              export const toChecksumAddress = (address) => {
              return Web3.utils.toChecksumAddress(address);
              }
              
              export const numFormatter = (num) => {
              if (num > 999 && num < 1000000) {
              return (num / 1000).toFixed(1) + "K"; // convert to K for number from > 1000 < 1 million
              } else if (num > 1000000) {
              return (num / 1000000).toFixed(1) + "M"; // convert to M for number from > 1 million
              } else if (num < 900) {
              return num; // if value < 1000, nothing to do
              }
              };


              export const numFormatter2 = (num) => {
                return Number(num).toFixed(2);
              };
              
              
              
              



              export const numberWithCommas = (x) => {
                return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                }
                

                export const isWhatPercentOf =(numA, numB) => {
                  if(numA==="TBA") return numB;
                  return (numA / numB) * 100;
                }



                export const  calculatePumpFactor =(initialPrice, finalPrice) => {
                  return (finalPrice / initialPrice).toFixed(2);
                }


/*
export const getBigNumber = (source) => {
    source += '';
    const parts = source.split(".");
    let decimals = 18;
    if (parts[1] && parts[1].length) decimals -= parts[1].length;
    let zero = "0";
    if (decimals < 0) return parts[0] + parts[1].slice(0, 18);
    return parts[0] + (parts[1]?parts[1]:"") + (zero.repeat(decimals));
}
*/



//https://api.coingecko.com/api/v3/simple/price?ids=0xpad&vs_currencies=usd&include_market_cap=false&include_24hr_vol=false&include_24hr_change=fale&include_last_updated_at=false


export const unixTimeToHuman = (timestamp) => {
var date = new Date(timestamp*1000);
return (date.getDate()+
"-"+(date.getMonth()+1)+
"-"+date.getFullYear()+
" "+date.getHours()+
":"+date.getMinutes()+
":"+date.getSeconds());
}





export const daysGone = (closeDate) => {
var currDate = moment();
var dateToTest = moment.unix(closeDate);
var result = currDate.diff(dateToTest, 'days')
return result;
}
export const daysGone2 = (givenDate) => {
  if (moment().subtract(7, 'days').valueOf() > moment.unix(givenDate).valueOf()) {
    return moment.unix(givenDate).format('llll');
  } else if (moment().subtract(1, 'days').valueOf() > moment.unix(givenDate).valueOf()) {
    return moment.unix(givenDate).calendar();
  } else {
    return moment.unix(givenDate).fromNow();
  }
}


export function getBlockchainLink(blockchain, data, type) {
  let prefix = EXPLORER_URLS[blockchain];

  if (!prefix) {
    throw new Error(`Unsupported blockchain: ${blockchain}`);
  }

  switch (type) {
    case 'transaction':
      return `${prefix}/tx/${data}`;
    case 'address':
      if (blockchain === tronNetwork) {
        return `${prefix}/#/token20/${data}`;
        
      }else if(blockchain === suiNetwork){
        return `${prefix}/object/${data}`;
      }else if(blockchain === avalNetwork){
        return `${prefix}/token/${data}`;
 
      }else{
        
      return `${prefix}/address/${data}`;
      }
      // Handle token links for other blockchains if needed
      throw new Error(`Unsupported token link for blockchain: ${blockchain}`);
    default:
      throw new Error(`Unsupported link type: ${type}`);
  }
}




export const calculateDiff = (openDate,closeDate,status) => {
let currentDate=Math.floor(new Date().getTime()/1000.0);
if(status==='0') return "Coming Soon";
if(closeDate <= currentDate){
//let dd=Math.floor(new Date(closeDate).getTime()/1000.0)
//moment.unix(closeDate).format('dddd, MMMM Do, YYYY h:mm:ss A')
//let d1=moment.unix(currentDate).format('dddd, MM DD, YYYY h:mm:ss A');
//let d1=moment.unix(currentDate).format('MM, DD, YYYY h:mm:ss A');
//moment().format('MM/DD/YYYY'); // "08/13/2019"
//console.log(d1,'day');

//var day = moment.unix(closeDate).utc();
//console.log(day,'day');


let d1=moment.unix(currentDate).format('MM, DD, YYYY h:mm:ss A');
//console.log(d1,'current day');
let d2=moment.unix(closeDate).format('MM, DD, YYYY h:mm:ss A');
//console.log(d2,'close day');
var a = moment([d1]);
var b = moment([d2]);
let c=a.diff(b, 'days') // 1
//console.log(c,'days');
//console.log(closeDate,'closeDate');
//console.log(moment.unix(closeDate).format('dddd, MMMM Do, YYYY h:mm:ss A'),'dd');
return "Sale End"; //date gone (past) // 18 Days Left
}else if(openDate >= currentDate) {
let d1=moment.unix(currentDate).format('MM, DD, YYYY h:mm:ss A');
let d2=moment.unix(openDate).format('MM, DD, YYYY h:mm:ss A');
var a = moment([d1]);
var b = moment([d2]);
//console.log(a.diff(b, 'minutes')) // 44700
//console.log(a.diff(b, 'hours')) // 745
//console.log(a.diff(b, 'days')) // 31
//console.log(a.diff(b, 'weeks')) // 4
return "Coming Soon"; //25 Hours Left
}else if( currentDate >= openDate && currentDate <= closeDate) {
return "running"; //01d 14h 05m
}
//console.log(currentDate,'currentDate');
}



//https://api.coingecko.com/api/v3/simple/price?ids=wbnb&vs_currencies=usd
//https://be.memepad.co/api/whitelists/in-whitelist?projectId=62137fa186ff9528e02ed4de&walletAddress=0x403Ff3d681d48FE7ffc67434eF85742d740623a6



export const getLPData = async (contract, address) => {
const decimals = await contract.methods.decimals().call();
const decimalsNum = new BigNumber(`1e${decimals}`);
const lpbalance = await contract.methods.balanceOf(address).call();
const lpbalanceNum = new BigNumber(lpbalance).dividedBy(decimalsNum);
const lptotal = await contract.methods.totalSupply().call();
const lptotalNum = new BigNumber(lptotal).dividedBy(decimalsNum);
const lpshareNum = new BigNumber(lpbalance).dividedBy(lptotal);
const reserves = await contract.methods.getReserves().call();
const token0Address = await contract.methods.token0().call();
const token1Address = await contract.methods.token1().call();
const token0Contract = new web3New.eth.Contract(bep20Abi, token0Address);
const token1Contract = new web3New.eth.Contract(bep20Abi, token1Address);
const token0 = await token0Contract.methods.symbol().call();
const token1 = await token1Contract.methods.symbol().call();
const token0decimals = await token0Contract.methods.decimals().call();
const token0decimalsNum = new BigNumber(`1e${token0decimals}`);
const token1decimals = await token1Contract.methods.decimals().call();
const token1decimalsNum = new BigNumber(`1e${token1decimals}`);
const token0reserveNum = new BigNumber(reserves.reserve0).dividedBy(token0decimalsNum);
const token1reserveNum = new BigNumber(reserves.reserve1).dividedBy(token1decimalsNum);
const token0BalanceNum = token0reserveNum.multipliedBy(lpshareNum);
const token1BalanceNum = token1reserveNum.multipliedBy(lpshareNum);
return [
lpbalanceNum.toFormat(12).replaceAll(',',''),
lptotalNum.toFormat(12).replaceAll(',',''),
token0,
token0BalanceNum.toFormat(6).replaceAll(',',''),
token1,
token1BalanceNum.toFormat(6).replaceAll(',',''),
];
}

export const getLPPrice = async (contractAddress, lpbalance) => {
if(!lpbalance>0) return 0;
const contract = new web3New.eth.Contract(bep20Abi, contractAddress);
const decimals = await contract.methods.decimals().call();
const decimalsNum = new BigNumber(`1e${decimals}`);
//--const lpbalance = await contract.methods.balanceOf(address).call();
const lpbalanceNum = new BigNumber(lpbalance).dividedBy(decimalsNum);
  const lptotal = await contract.methods.totalSupply().call();
  const lptotalNum = new BigNumber(lptotal).dividedBy(decimalsNum);
  const lpshareNum = new BigNumber(lpbalance).dividedBy(lptotal);
  const reserves = await contract.methods.getReserves().call();
  const token0Address = await contract.methods.token0().call();
  const token1Address = await contract.methods.token1().call();
  const token0Contract = new web3New.eth.Contract(bep20Abi, token0Address);
  const token1Contract = new web3New.eth.Contract(bep20Abi, token1Address);
  const token0 = await token0Contract.methods.symbol().call();
  const token1 = await token1Contract.methods.symbol().call();
  const token0decimals = await token0Contract.methods.decimals().call();
  const token0decimalsNum = new BigNumber(`1e${token0decimals}`);
  const token1decimals = await token1Contract.methods.decimals().call();
  const token1decimalsNum = new BigNumber(`1e${token1decimals}`);
  const token0reserveNum = new BigNumber(reserves.reserve0).dividedBy(token0decimalsNum);
  const token1reserveNum = new BigNumber(reserves.reserve1).dividedBy(token1decimalsNum);
  const token0BalanceNum = token0reserveNum.multipliedBy(lpshareNum);
  const token1BalanceNum = token1reserveNum.multipliedBy(lpshareNum);
  return token0BalanceNum.toFormat(6).replaceAll(',','');
  

  /*
  return [
  lpbalanceNum.toFormat(12).replaceAll(',',''),
  lptotalNum.toFormat(12).replaceAll(',',''),
  token0,
  token0BalanceNum.toFormat(6).replaceAll(',',''),
  token1,
  token1BalanceNum.toFormat(6).replaceAll(',',''),
  ];
*/



  }



  export const getTokenWorth = async () => {
    let bnbPrice = await calcBNBPrice() // query pancakeswap to get the price of BNB in USDT
    let bsttPrice = await calcBSTTPrice() // query pancakeswap to get the price of BSTT in BNB
    let bsttprice = bsttPrice * bnbPrice;
  return bsttprice.toFixed(10);
  //document.getElementById("bsttprice").innerHTML  = bsttprice.toFixed(10) ;
    }




export const sleep = (ms) => {
return new Promise(resolve => setTimeout(resolve, ms));
}


export const calculateTokens = (usd,idoPrice) => {
return (usd/idoPrice).toFixed(2);
}



export const percentage = (quantity, percent) => (quantity * percent) / 100;


export const calculatePercentageChange =(current, previous) =>{
  if (previous === 0) {
    return 0;
  }

  const change = ((current - previous) / previous) * 100;
  return change.toFixed(2);
}

const chainIdToIcon = {
  1: ethImg,
  56: bscImg,
  97: bscImg,
  137:polygonImg,
  43114:avaxImg
};


export const getNetworkIcon = (chainId) => {
  return chainIdToIcon[chainId] || bscImg; // Use a default icon if no match found
};


const chainIdToNetwork = {
  1: ethNetwork,
  56: bscNetwork,
  97: bscNetwork,
  137:polygonNetwork,
  43114:avalNetwork
};

export const getNetworkName = (chainId) => {
  return chainIdToNetwork[chainId] || bscNetwork; // Use a default icon if no match found
};


export const saveDataToFile = (filename, data) => {

  const dataString = data.join('\n');
  const blob = new Blob([dataString], { type: 'text/plain' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.download = filename;
  link.click();
  URL.revokeObjectURL(url);
  console.log('File saved successfully!');


};






export const getLocalTime = (unixTimestamp) => {
const utcMoment = moment.unix(unixTimestamp).utc();
const localMoment = utcMoment.local();
const formattedDateTime = localMoment.format("MMMM DD, HH:mm A");
return formattedDateTime;
}

export const getLocalTimeFull = (unixTimestamp) => {
  const utcMoment = moment.unix(unixTimestamp).utc();
  const localMoment = utcMoment.local();
  const formattedDateTime = localMoment.format("MMMM DD, YYYY HH:mm A");
  return formattedDateTime;
  }
//format('MM, DD, YYYY h:mm:ss A');

export const getTokenPrice = async (coingeckoIDs) => {
  try {
  let result= await axios.get("https://api.coingecko.com/api/v3/simple/price?ids="+coingeckoIDs+"&vs_currencies=usd&include_market_cap=false&include_24hr_vol=false&include_24hr_change=fale&include_last_updated_at=false");
  if(result.data[coingeckoIDs].usd) return result.data[coingeckoIDs].usd;
  return 0;
  } catch (error) {
  return null;
  }
  } 



  export const getMarketData = async (coingeckoIDs) => {
    try {
    let result= await axios.get("https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids="+coingeckoIDs);
    if(result.data[0]) return result.data[0];
    return 0;
    } catch (error) {
    return null;
    }
    } 



export const getStatisticsList = async () => {
  try {
const arrMarks = projects.map(rse => rse.cap);
const raised = arrMarks.reduce((prev, cur) => prev + cur); 
let completed = arrMarks.filter(Number).length;
let statistics=[{
  "raised":raised,
  "investors": "TBA",
  "completed": completed,
  "verified":"TBA"
}]
  return statistics;
  } catch (error) {
  return null;
  }
  } 



export const getPartnerList = async () => {
  try {
  return partners;
  } catch (error) {
  return null;
  }
  } 

 export const getProjectList = async () => {
  try {
  return projects;
  } catch (error) {
  return null;
  }
  } 
  
  export const getFeaturedProjectList = async () => {
    try {
    return projects.filter(item => item.ath > 0);
    } catch (error) {
    return null;
    }
    } 


  export const getProjectTrendingList = async () => {
    let currentDate=Math.floor(new Date().getTime()/1000.0);
    try {
    let result= await getProjectList();
    return result.filter( x => x.closeTime >= currentDate)
    } catch (error) {
    return null;
    }
    } 
    

    export const getProjectUpcommingList = async () => {
      let currentDate=Math.floor(new Date().getTime()/1000.0);
      try {
      let result= await getProjectList();
      //return result.filter( x => x.openTime >= currentDate)
      return result.filter( x => x.openTime === "TBA" && x.closeTime==="TBA" )


      
      } catch (error) {
      return null;
      }
      } 

      export const getProjectCompletedList = async () => {
        let currentDate=Math.floor(new Date().getTime()/1000.0);
        try {
        let result= await getProjectList();
        return result.filter( x => x.closeTime <= currentDate)
        } catch (error) {
        return null;
        }
        } 



        export const getProjectClaimList = async () => {
          let currentDate=Math.floor(new Date().getTime()/1000.0);
          try {
          let result= await getProjectList();
          return result.filter( x => x.closeTime <= currentDate && x.claimable === true)
          } catch (error) {
          return null;
          }
          } 



function makeBatchRequest(calls, callFrom) {
  let batch = new web3New.BatchRequest();
  let promises = calls.map(call => {
      return new Promise((resolve, reject) => {
          let request = call.request({from: callFrom}, (error, data) => {
              if(error) {
                  reject(error);
              } else {
                  resolve(data);
              }
          });
          batch.add(request);
      });
  });

  batch.execute();

  return Promise.all(promises);
}

export const getContractAddress = (type) => {
  switch (type) {
  case 'token':
  return REACT_APP_TOKEN_ADDRESS;
  case 'stake':
  return REACT_APP_STAKE_ADDRESS;
  case 'farm':
  return REACT_APP_FARM_ADDRESS;
  case 'liquidity':
  return REACT_APP_LIQUIDITY_ADDRESS;
  case 'kyc':
  return REACT_APP_KYC_ADDRESS;
  case 'wallet':
  return REACT_APP_EXTERNAL_WALLET_ADDRESS;
  case 'BUSD':
  return REACT_APP_BUSD_ADDRESS;
  case 'USDT':
  return REACT_APP_USDT_ADDRESS;
  case 'BNB':
  return REACT_APP_BNB_ADDRESS;
  case 'multicall':
  return REACT_APP_MULTICALL_ADDRESS;
  case 'multisender':
  return REACT_APP_MULTI_SENDER_ADDRESS;
  case 'pancakeswap':
  return REACT_APP_PANCAKESWAP_ADDRESS;
  }
  }



  async function calcBSTTPrice(){	
    let bnbToSell = web3New.utils.toWei("1", "ether") ;
    let amountOut;
    try {
        let router = await new web3New.eth.Contract(pancakeAbi, getContractAddress('pancakeswap'));
        amountOut = await router.methods.getAmountsOut(bnbToSell, [getContractAddress('token') ,getContractAddress('BNB')]).call();
        amountOut =  web3New.utils.fromWei(amountOut[1]);
    } catch (error) {}
    if(!amountOut) return 0;
    return amountOut;
}


async function calcBNBPrice(){
  let bnbToSell = web3New.utils.toWei("1", "ether") ;
  let amountOut;
  try {
      let router = await new web3New.eth.Contract( pancakeAbi, getContractAddress('pancakeswap') );
      amountOut = await router.methods.getAmountsOut(bnbToSell, [getContractAddress('BNB') ,getContractAddress('USDT')]).call();
      amountOut =  web3New.utils.fromWei(amountOut[1]);
  } catch (error) {}
  if(!amountOut) return 0;
  return amountOut;
}






  export const getReactEnv = (type) => {
    switch (type) {
    case 'chain':
    return Number(REACT_APP_CHAIN_ID);
    case 'network':
    return REACT_APP_NETWORK_URL;
    }
    }

  export const getSaleStatus = (type) => {
    var str='';
    if(type==='0'){
    str="Upcoming"
    }else if(type==='1'){
    str="Inprogress"
    }else if(type==='2'){
    str="Ended"
    }else if(type==='3'){
    str="Cancelled"
    }else if(type==='4'){
    str="Failed"
    }else{
    str="Upcoming"
    }
    return str;
    }

    export const networkIcon = (network) => {
      if (network === bscNetwork) {
        return bscImg
      } else if (network === ethNetwork) {
        return ethImg
      } else if (network === solNetwork) {
        return solanaImg
      } else if (network === avalNetwork) {
        return avaxImg
      } else if (network === polygonNetwork) {
        return polygonImg
      } else if (network === aptosNetwork) {
        return aptosImg
      } else if (network === arbitrumNetwork) {
        return arbitrumImg
      } else if (network === cronosNetwork) {
        return cronosImg
      } else if (network === fantomNetwork) {
        return fantomImg
      } else if (network === algorandNetwork) {
        return algorandImg
      } else if (network === nearNetwork) {
        return nearImg
      } else if (network === atomNetwork) {
        return atomImg
      } else if (network === stellarNetwork) {
        return stellarImg
      } else if (network === tronNetwork) {
        return tronImg
      } else if (network === ozoneNetwork) {
        return ozoneImg
      } else if (network === suiNetwork) {
        return suiImg
      } else if (network === cantoNetwork) {
        return cantoImg
      } else if (network === egonNetwork) {
        return egonImg
        

        



      } else {
        return bscImg
      }
    }