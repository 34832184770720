import React,{useState} from 'react';
import { useTimer } from 'react-timer-hook';
const zeroPad = (num, places) => String(num).padStart(places, '0')
/*
function MyTimer({ expiryTimestamp,setIsTrigger }) {
const {seconds,minutes,hours,days,isRunning} = useTimer({ expiryTimestamp, onExpire: () => setIsTrigger(true)});
return (
<div className="timer">
<ul>
<li className="days">{zeroPad(days, 2)}<span>D</span></li>
<li className="hours">{zeroPad(hours, 2)}<span>H</span></li>
<li className="minutes">{zeroPad(minutes, 2)}<span>M</span></li>
<li className="seconds">{zeroPad(seconds, 2)}<span>S</span></li>
</ul>
</div>
);
};


export default MyTimer;
*/
export const MyTimer = ({ expiryTimestamp,setIsTrigger }) => {
const {seconds,minutes,hours,days,isRunning} = useTimer({ expiryTimestamp, onExpire: () => setIsTrigger(true)});
return (
<div className="timer">
<ul>
<li className="days">{zeroPad(days, 2)}<span>D</span></li>
<li className="hours">{zeroPad(hours, 2)}<span>H</span></li>
<li className="minutes">{zeroPad(minutes, 2)}<span>M</span></li>
<li className="seconds">{zeroPad(seconds, 2)}<span>S</span></li>
</ul>
</div>
);
}
export const MyTimerCountDown = ({ expiryTimestamp }) => {
const {seconds,minutes,hours,days,isRunning} = useTimer({ expiryTimestamp, onExpire: () => console.warn('onExpire called') });
return (
<div className="timer">
<ul>
<li className="days">{zeroPad(days, 2)}<span>D</span></li>
<li className="hours">{zeroPad(hours, 2)}<span>H</span></li>
<li className="minutes">{zeroPad(minutes, 2)}<span>M</span></li>
<li className="seconds">{zeroPad(seconds, 2)}<span>S</span></li>
</ul>
</div>
    );
    }
//01d 14h 05m