import React,{Fragment,useState,useEffect} from 'react';
import { Link } from 'react-router-dom'
import logoImg from '../../assets/images/logo/soon.png';
import bannerImg from '../../assets/images/igo/item/01.jpg';
import web3 from "../../utils/web3";

import {isWhatPercentOf,getPoolData,daysGone,fromWeiToNumber,numFormatter,getContractAddress,networkIcon} from '../../utils/contractHelpers';


function ProjectCard({item}) {
const [cap, setCap] = useState(item?.cap);
const [participent, setCarticipent] = useState(item?.participent);
const [hardCap, setHardCap] = useState(item?.hardCap.replace(",",""));
useEffect(() => {
if(item && item.cap <1){
getPoolInfo(item.saleAddress);
}
}, [item])

const getPoolInfo = async (saleAddress) => {
if(!saleAddress) return;
try {
var result=await getPoolData(saleAddress);
setCap(fromWeiToNumber(result[0]));
} catch (error) {
//console.log(error,'errorerror');
}
} 

return (
<Fragment>

<div className="project__item2 position-relative aos-init" data-aos="fade-up" data-aos-duration={800}>
<div className="project__item2-inner">
  <div className="project__item2-name">
    <div className="project__item2-thumb"><img style={{width: '90px', hight: '90px'}} src={item.logoUrl?item.logoUrl:logoImg} alt="Project" />
    </div>
    <div className="project__item2-content">
      <h4><Link to={item.saleAddress?'/pool/'+item.params:"#"} className="stretched-link">{item.tokenName}</Link>
      </h4>
      <p>PRICE ({item.tokenSymbol}) = {item.idoPrice} {item.currency}</p>
    </div>
  </div>
  <div className="project__item2-chain">
  <img src={networkIcon(item.blockchain)} alt="BlockChain" />
  </div>
  <div className="project__item2-time">
    <p>{item.closeTime>0 ?daysGone(item.closeTime):"TBA"} days</p>
  </div>
  <div className="project__item-amount">
    <p>Raised Ammount</p>
    <h6><span className="color--theme-color">{cap}</span> / <span>{cap==="TBA"?cap:hardCap} {item.currency}</span>
    </h6>
    <div className="progress">
    <div className="progress-bar" role="progressbar" style={{width: isWhatPercentOf(cap,hardCap)+'%'}} aria-valuenow={isWhatPercentOf(cap,hardCap)} aria-valuemin={0} aria-valuemax={100} />
    </div>
  </div>
</div>
            </div>
  </Fragment>
)
}

export default ProjectCard
