import React,{Fragment,useEffect,useState} from 'react';
import moment from "moment";
import ToText from "../utils/ToText.js";
import axios from 'axios';
const medium = require('@giuseppecampanelli/medium-api');
const baseURL = "https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@torkpad";

function Blog() {
  const [data, setData] = useState(null);
  useEffect(() => {

    medium.publication.getPublication("torkpad").then(res => {
     console.log(res);
    })








  axios.get(baseURL).then((response) => {




 // console.log(data.data)
 /*
 const avatar = data.data.feed.image;
 const profileLink = data.data.feed.link;
 const res = data.data.items; //This is an array with the content. No feed, no info about author etc..
 const posts = res.filter(item => item.categories.length > 0);

 const title = data.data.feed.title;

 this.setState(
   (pre) => ({
     profile: {
       ...pre.profile,
       ptitle: title,
       profileurl: profileLink,
       avtar: avatar,

     },
     item: posts,
     isloading: false
*/









  setData(response.data.items);
  });
  }, []);
  if (!data) return null;

return (
<Fragment>
  <div className="blog padding-top padding-bottom">
    <div className="container">
      <div className="blog__wrapper">
        <div className="row g-4">

        {data.map((item, index) => (

          <div className="col-lg-4 col-md-6">
            <div className="blog__item">
              <div className="blog__inner">
                <div className="blog__thumb">





                  <img src={item.thumbnail} alt="Blog Images" />
                </div>
                <div className="blog__content">
                  <div className="blog__content-top">
                    <h4><a href="blog-single.html">{item.title}</a></h4>
                    <ul className="blog__meta d-flex flex-wrap align-items-center">
                      <li className="blog__meta-author">
                        <a href="#"><span><i className="fa-solid fa-user" /></span> {item.author}</a>
                      </li>
                      <li className="blog__meta-date">
                        <a href="#"><span><i className="fa-solid fa-calendar-days" /></span>
                        {moment(item.pubDate).format("MMM DD, YYYY hh:mm")}</a>
                      </li>
                    </ul>
                  </div>
                  <p>
                  
                  {`${ToText(
            item.description.substring(0, 1000)
          )}...`}
                  
                  </p>
                  <div className="blog__content-bottom">
                    <a href={item.link} className="text-btn">Read More</a>
                  </div>
                </div>
              </div>
            </div>
          </div>


))}


        </div>



        {/*
        <div className="mt-5 text-center">
          <nav aria-label="Blog Pagination">
            <ul className="pagination justify-content-center">
              <li className="page-item disabled">
                <span className="page-link"><i className="fa-solid fa-angle-left" /></span>
              </li>
              <li className="page-item"><a className="page-link" href="#">1</a></li>
              <li className="page-item active" aria-current="page">
                <span className="page-link">2</span>
              </li>
              <li className="page-item"><a className="page-link" href="#">3</a></li>
              <li className="page-item">
                <a className="page-link" href="#"><i className="fa-solid fa-angle-right" /></a>
              </li>
            </ul>
          </nav>
        </div>
*/}


      </div>
    </div>
  </div>
</Fragment>
)
}

export default Blog
