import firebase from 'firebase/app';
import 'firebase/firestore';

class FirebaseService {
  constructor() {
    const firebaseConfig = {
        apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
        authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
        databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
        projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
        storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
        messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    };

    firebase.initializeApp(firebaseConfig);
    this.firestore = firebase.firestore();
  }

  submitData = async (collectionName, data) => {
    try {
      const { address } = data;

      // Check if a document with the same address already exists
      const querySnapshot = await this.firestore
        .collection(collectionName)
        .where('address', '==', address)
        .get();

      if (!querySnapshot.empty) {
        throw new Error('Duplicate entry: An entry with the same address already exists.');
      }

      // Add the data as a new document
      await this.firestore.collection(collectionName).add(data);
      //console.log('Data submitted successfully!');
    } catch (error) {
      //console.error('Error submitting data:', error);
    }
  };

  getData = async (collectionName) => {
    try {
      const querySnapshot = await this.firestore.collection(collectionName).get();
      querySnapshot.forEach((doc) => {
       // console.log(doc.id, '=>', doc.data());
      });
    } catch (error) {
      //console.error('Error getting data:', error);
    }
  };



  getDataByAddress = async (collectionName, address) => {
    try {
      const querySnapshot = await this.firestore
        .collection(collectionName)
        .where('address', '==', address)
        .limit(1)
        .get();

      if (!querySnapshot.empty) {
        const doc = querySnapshot.docs[0];
        return { id: doc.id, data: doc.data() };
      } else {
        return null;
      }
    } catch (error) {
     // console.error('Error getting data by address:', error);
      return null; // Return null or handle the error as needed
    }
  };





}

export default new FirebaseService;
