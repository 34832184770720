import React,{Fragment, useState, useEffect} from 'react';
import logoImg from '../../assets/images/logo/soon.png';
import bannerImg from '../../assets/images/igo/item/01.jpg';
import ProjectCard from '../Card/ProjectBoxCard';
import ProjectUpComingBoxCard from '../Card/ProjectUpComingBoxCard';
import ProjectBoxCompletedCard from '../Card/ProjectBoxCompletedCard';
import { Link } from 'react-router-dom'
import CountUp from 'react-countup';

import {calculateDiff,isWhatPercentOf,getProjectTrendingList,getProjectUpcommingList,getProjectCompletedList,getStatisticsList,networkIcon} from '../../utils/contractHelpers';
//import {MyTimer} from '../Widget/MyTimer';
//import moment from 'moment';


const Explore = () => {
const [past, setPastData] = useState([]);
const [upcomming, setUpcomingData] = useState([]);
const [running, setTrendingData] = useState([]);
const [statistics, setStatisticsData] = useState([]);



useEffect(() => {
 const getProjectTrending = async () => {
   const result = await getProjectTrendingList();
   setTrendingData(result);
 };
 const getProjectUpcomming = async () => {
  const result2 = await getProjectUpcommingList();
  setUpcomingData(result2);
};
const getProjectCompleted = async () => {
const result3 = await getProjectCompletedList();
setPastData(result3.reverse());
};
const getStatistics = async () => {
  const result4 = await getStatisticsList();
  setStatisticsData(result4);
  };
getProjectTrending();
getProjectUpcomming();
getProjectCompleted();
getStatistics();
}, []);







return (
<Fragment>


<section className="project padding-top padding-bottom">
    <div className="container">
      <div className="section-header section-header--middle">
        <div className="section-header__content">
          <div className="section-header__titlebar">
            <p className="section-header__subtitle"> Projects</p>
            <h2 className="section__header__title">Ongoing IGOs/IDOs</h2>
          </div>
        </div>
      </div>
      <div className="project__wrapper">
        <div className="row g-4">


        {running.map((item, index) => (


          <div key={index} className="col-lg-4 col-sm-6">
<ProjectCard item={item}/>
          </div>
))}
        </div>
      </div>
    </div>
  </section>




  <section className="project padding-top padding-bottom">
    <div className="container">
      <div className="section-header section-header--middle">
        <div className="section-header__content">
          <div className="section-header__titlebar">
            <p className="section-header__subtitle"> Projects</p>
            <h2 className="section__header__title">Upcoming IGOs/IDOs</h2>
          </div>
        </div>
      </div>
      <div className="project__wrapper">
        <div className="row g-4">


        {upcomming.map((item, index) => (


          <div key={index} className="col-lg-4 col-sm-6">
         <ProjectUpComingBoxCard item={item}/>
          </div>
))}
        </div>
      </div>
    </div>
  </section>



  {statistics.map((item, index) => (


  <div className="counter__section">
  <div className="container">
    <div className="counter__wrapper" data-aos="fade-up" data-aos-duration={1000}>
      <div className="row g-5 justify-content-center align-items-center">
        <div className="col-lg-3 col-sm-6">
          <div className="counter__item">
<h3>$<span>   
{item.raised ==="TBA" ? 
" TBA"
: <CountUp end={item.raised} />
}
</span>{/*M*/}
            </h3>
            <p>Total Amount Raised</p>
          </div>
        </div>
        <div className="col-lg-3 col-sm-6">
          <div className="counter__item">
            <h3><span>
{item.investors ==="TBA" ? 
" TBA"
: <CountUp end={item.investors} />
}
</span>{/*M+*/}
            </h3>
            <p>Accredited investors</p>
          </div>
        </div>
        <div className="col-lg-3 col-sm-6">
          <div className="counter__item">
            <h3><span>
              {item.completed ==="TBA" ? 
" TBA"
: <CountUp end={item.completed} />
}
              
              
              
              </span>{/*+*/}
            </h3>
            <p>Project Completed</p>
          </div>
        </div>
        <div className="col-lg-3 col-sm-6">
          <div className="counter__item">
            <h3><span>
{item.verified ==="TBA" ? 
" TBA"
: <CountUp end={item.verified} />
}
</span>{/*M*/}
            </h3>
            <p>Verified User</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

))}



  <section className="project padding-top padding-bottom project--completed2">
    <div className="container">
      <div className="section-header section-header--left">
        <div className="section-header__content">
          <div className="section-header__titlebar">
            <p className="section-header__subtitle"> Completed</p>
            <h2 className="section__header__title">Previous Projects</h2>
          </div>
         
        </div>
      </div>
<div className="project__wrapper">
<div className="row g-4">
{past.map((item, index) => (
<div key={index} className="col-lg-4 col-md-6">
<ProjectBoxCompletedCard item={item}/>
</div>
))}
</div>



     {/*  <ul className="pagination mt-5 justify-content-center">
          <li className="page-item disabled">
            <span className="page-link"><i className="fa-solid fa-angle-left" /></span>
          </li>
          <li className="page-item active" aria-current="page"><a className="page-link" href="#">1</a></li>
          <li className="page-item">
            <span className="page-link">2</span>
          </li>
          <li className="page-item"><a className="page-link" href="#">3</a></li>
          <li className="page-item">
            <a className="page-link" href="#"><i className="fa-solid fa-angle-right" /></a>
          </li>
        </ul>
*/}


      </div>
    </div>
  </section>
</Fragment>

)
}

export default Explore
