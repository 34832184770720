import React,{StrictMode} from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { Web3ReactProvider, createWeb3ReactRoot } from '@web3-react/core'
import { ethers } from 'ethers'
import { NetworkContextName } from './constants'
import { isMobile } from 'react-device-detect'
import { ToastProvider } from "react-toast-notifications";


import App from './App';
import * as serviceWorker from './serviceWorker';


import reportWebVitals from './reportWebVitals';
const Web3ProviderNetwork = createWeb3ReactRoot(NetworkContextName)

function getLibrary(provider) {
  const library = new ethers.providers.Web3Provider(provider)
  library.pollingInterval = 10000
  return library
}

// Initialize Google Analytics 4
if (process.env.NODE_ENV === 'production') {
    ReactGA.initialize('G-DVQP3BVRS8')
    ReactGA.send('pageview'); // Sends a page view to GA4
    ReactGA.set({
      customBrowserType: !isMobile ? 'desktop' : window.web3 || window.ethereum ? 'mobileWeb3' : 'mobileRegular'
    })
  } else {
    ReactGA.initialize('test', { testMode: true })
  }

 // ReactGA.pageview(window.location.pathname + window.location.search)


const rootElement = document.getElementById("root");
ReactDOM.render(
<StrictMode>
<Web3ReactProvider getLibrary={getLibrary}>
<Web3ProviderNetwork getLibrary={getLibrary}>
<ToastProvider autoDismiss={true} autoDismissTimeout={2000}>
<App />
</ToastProvider>
</Web3ProviderNetwork>
</Web3ReactProvider>
</StrictMode>,
rootElement
);
reportWebVitals();
serviceWorker.unregister();