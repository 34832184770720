import React,{Fragment} from 'react';
import {Helmet} from "react-helmet";


import Banner from '../components/Home/Banner';
/*
import About from '../components/Home/About';
import Allocation from '../components/Home/Allocation';
import Project from '../components/Home/Project';
import Previous from '../components/Home/Previous';
import MultiChain from '../components/Home/MultiChain';
import Participate from '../components/Home/Participate';
import Team from '../components/Home/Team';
*/


import Partner from '../components/Home/Partner';
import Tokenomics from '../components/Home/Tokenomics';
import Work from '../components/Home/Work';
import Roadmap from '../components/Home/Roadmap';

import Tier from '../components/Home/Tier';
import Benifit from '../components/Home/Benifit';
import ProjectCompleted from '../components/Home/ProjectCompleted';
import ProjectTrending from '../components/Home/ProjectTrending';
import ProjectUpcoming from '../components/Home/ProjectUpcoming';
import ProjectFeatured from '../components/Home/ProjectFeatured';
import Cta from '../components/Home/Cta';
import Faqs from '../components/Home/Faqs';
export default function  Home() {
return (
<Fragment>
<Helmet>
<title>TorkPad - The BEST Multi-Chain Launchpad</title>
</Helmet>
<Banner/>

<ProjectTrending/>
<ProjectUpcoming/>
<ProjectCompleted/>
<Benifit/>
<Work/>
<Tokenomics/>
<Tier/>
<Roadmap/>
<Partner/>
<Faqs/>
<Cta/>
</Fragment>
)
}
