import WalletConnectProvider from '@walletconnect/web3-provider';
const { REACT_APP_TOKEN_ADDRESS } = process.env

/*
import Fortmatic from 'fortmatic';
import Torus from '@toruslabs/torus-embed';
import Authereum from 'authereum';
import UniLogin from '@unilogin/provider';
import Portis from '@portis/web3';
import Squarelink from 'squarelink';
import MewConnect from '@myetherwallet/mewconnect-web-client';
import { WalletLink } from 'walletlink';
*/
const POLLING_INTERVAL = 12000;

export const bscMainnet = {
chainId: "0x38",
chainName: "Binance Smart Chain Mainnet",
rpcUrls: ["https://bsc-dataseed1.binance.org"],
nativeCurrency: {
name: "BINANCE COIN",
symbol: "BNB",
decimals: 18,
},
blockExplorerUrls: ["https://bscscan.com/"],
iconUrls: [
  "https://bin.bnbstatic.com/image/admin_mgs_image_upload/20201110/550eda20-1b9a-4bc7-9a65-e4a329e8bb57.png"
]
};


export const bscTestnet = {
  chainId: `0x61`,
  chainName: "Binance Smart Chain Testnet",
  rpcUrls: ["https://data-seed-prebsc-2-s1.binance.org:8545/"],
  nativeCurrency: {
  name: "BNB",
  symbol: "bnb",
  decimals: 18
  },
  blockExplorerUrls: ["https://testnet.bscscan.com"],
  iconUrls: [
    "https://bin.bnbstatic.com/image/admin_mgs_image_upload/20201110/550eda20-1b9a-4bc7-9a65-e4a329e8bb57.png"
  ]
  };




export const testNetUrl = 'https://testnet.bscscan.com/';
export const mainNetUrl = 'https://bscscan.com/';


export const SiteName = 'TorkPad';
export const TokenName = 'TorkPad';
export const TokenSymbol = 'TPad';
export const TokenMaxSupply = '100,000,000';
export const Twitter='https://twitter.com/TorkPad';
export const Telegram='https://t.me/TorkPad';
export const TelegramAnn='https://t.me/TorkPadAnn';
export const Medium='https://TorkPad.medium.com';
export const Discord='';
export const Linkedin='';
export const Instagram='https://www.instagram.com/torkpad/';
export const Facebook='';
export const Reddit='';
export const Youtube='https://www.youtube.com/@TorkPad';
export const Docs='https://docs.torkpad.io';
export const Github='https://github.com/torkpad';
export const Email='mailto:hello@TorkPad.io';
export const TeamEmail='team@TorkPad.io';
export const Contract='https://bscscan.com/address/0x77972A1BA3cAb14E1E907b58BBc4971544f4719d';
export const GeckoTerminal='https://www.geckoterminal.com/bsc/pools/0xfc76c4e496d13f1b0dcec2536d9cdcbf8d3d4390';
export const TrustPilot='https://www.trustpilot.com/review/torkpad.io';
export const SafeGuardUrl='https://docs.torkpad.io/security/ido-rules';
export const SecuredIDOUrl='https://docs.torkpad.io/security/ido-rules/secured-ido';
export const AutonomyIDOUrl='https://docs.torkpad.io/security/ido-rules/autonomy-ido';
export const HowToParticipate='https://docs.torkpad.io/contributing-to-our-ido/instructions-for-purchasing-ido';
export const PancakeSwap="https://pancakeswap.finance/swap?outputCurrency="+REACT_APP_TOKEN_ADDRESS;
export const UniSwap="";
export const CoinGecko="https://www.coingecko.com/en/coins/torkpad";
export const CoinMarketCap="https://coinmarketcap.com/currencies/torkpad/";
export const GateIo="";
export const cryptoRank="https://cryptorank.io/fundraising-platforms/tork-pad";
export const kycCountry="https://docs.google.com/spreadsheets/d/1E8os41ojvZm7A9X23IacRwe2OUu74Fn-o6tnflGAQzY/edit?usp=sharing";
export const IgoForm="https://forms.gle/u79FeT9xuRLtCqJ47";
export const baseURL="/api/project.json";
export const partnerURL="/api/partner.json";
export const statisticsURL="/api/statistics.json";

export const ethNetwork = 'eth'
export const bscNetwork = 'bnb'
export const polygonNetwork = 'polygon'
export const aptosNetwork = 'aptos'
export const solNetwork = 'solana'
export const arbitrumNetwork = 'arbitrum'
export const avalNetwork = 'aval'
export const cronosNetwork = 'cronos'
export const fantomNetwork = 'fantom'
export const algorandNetwork = 'algorand'
export const nearNetwork = 'near'
export const atomNetwork = 'atom'
export const stellarNetwork = 'stellar'
export const tronNetwork = 'TRX'
export const ozoneNetwork = 'ozone'
export const suiNetwork = 'sui'
export const cantoNetwork = 'canto'
export const egonNetwork = 'egon'

export const PUBLIC = 'Public'
export const PRIVATE = 'Private'
export const WHITELIST = 'Whitelist'
export const GUARANTEED = 'Guaranteed'
export const FCFS = 'FCFS'
export const LEVELS = 'Levels'
export const DISTRIBUTION = ['Airdropped by TorkPad team','Claimed on TorkPad','Claimed on TrustSwap','TBA']
export const IDORULES = ['Secured IDO','Autonomy IDO','Private','Seed']
export const VESTINGTYPE = ['Monthly','Linear']



export const EXPLORER_URLS = {
  [bscNetwork]: 'https://bscscan.com',
  [ethNetwork]: 'https://etherscan.io',
  [polygonNetwork]: 'https://polygonscan.com',
  [aptosNetwork]: 'https://aptoscan.io',
  [solNetwork]: 'https://explorer.solana.com',
  [tronNetwork]: 'https://tronscan.io',
  [arbitrumNetwork]: 'https://arbiscan.io',
  [suiNetwork]: 'https://suiexplorer.com',
  [cantoNetwork]: 'https://suiexplorer.com',
  [avalNetwork]: 'https://snowtrace.io',
  [egonNetwork]: 'https://egonscan.com',
};