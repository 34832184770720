import React from 'react';
import { usePagination, DOTS } from '../hooks/usePagination';

const Pagination = props => {
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize
  });

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

const onNext = () => {
if(currentPage===paginationRange.length) return;
onPageChange(currentPage + 1);
};

const onPrevious = () => {
if(currentPage > 1)
onPageChange(currentPage - 1);
};


  let lastPage = paginationRange[paginationRange.length - 1];

  return (
    <ul className="pagination mt-5 justify-content-center">
      <li
        className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
        onClick={onPrevious}
      >
        <span className="page-link"><i className="fa-solid fa-angle-left" /></span>
      </li>
      {paginationRange.map((pageNumber, index) => {
       
        return (
          <li
            className={`page-item ${pageNumber === currentPage ? "active" : ""}`}
            onClick={() => onPageChange(pageNumber)}
            key={index}
          >

          {pageNumber === currentPage ? <span className="page-link">{pageNumber}</span>:<a className="page-link" >{pageNumber}</a>}

          </li>
        );
      })}
      <li
      className={`page-item ${currentPage === lastPage ? "disabled" : ""}`}
        onClick={onNext}
      >
       <span className="page-link"> <i className="fa-solid fa-angle-right" /></span>
      </li>
    </ul>
  );
};

export default Pagination;
