import {TokenMaxSupply} from '../../config';
import React,{Fragment} from 'react';

import piechart from '../../assets/images/token/chart.png';


function Tokenomics() {
return (
<Fragment>
<section id="tokenomics" className="token padding-top padding-bottom">
  <div className="container">
    <div className="section-header section-header--left">
      <div className="section-header__content">
        <div className="section-header__titlebar">
          <p className="section-header__subtitle"> Statistics</p>
          <h2 className="section__header__title">Tokenomics</h2>
        </div>
      </div>
    </div>
    <div className="token__wrapper">
      <div className="row g-5">
        <div className="col-lg-6">
          <div className="token__info">
            <ul className="token__info-list">
              <li className="token__info-list-item">
                <p className="token__info-list-name">Total Supply</p>
                <p className="token__info-list-value">{TokenMaxSupply}</p>
              </li>
              <li className="token__info-list-item">
                <p className="token__info-list-name">Public sale</p>
                <p className="token__info-list-value">19%</p>
              </li>
              <li className="token__info-list-item">
                <p className="token__info-list-name">Community Rewards</p>
                <p className="token__info-list-value">28%</p>
              </li>
              <li className="token__info-list-item">
                <p className="token__info-list-name">Airdrop</p>
                <p className="token__info-list-value">2%</p>
              </li>
              <li className="token__info-list-item">
                <p className="token__info-list-name">Reserve</p>
                <p className="token__info-list-value">5%</p>
              </li>
              <li className="token__info-list-item">
                <p className="token__info-list-name">Team & Advisors</p>
                <p className="token__info-list-value">13%</p>
              </li>
              <li className="token__info-list-item">
                <p className="token__info-list-name">Private Sale</p>
                <p className="token__info-list-value">5%</p>
              </li>
              <li className="token__info-list-item">
                <p className="token__info-list-name">Liquidity</p>
                <p className="token__info-list-value">10%</p>
              </li>
              <li className="token__info-list-item">
                <p className="token__info-list-name">Marketing</p>
                <p className="token__info-list-value">10%</p>
              </li>
              <li className="token__info-list-item">
                <p className="token__info-list-name">Development</p>
                <p className="token__info-list-value">8%</p>
              </li>
            </ul>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="token__allocation  bg--shade1">
            <div className="token__allocation-inner text-center">
              <h4 className="mb-4">Sale Proceed Allocation</h4>
              <div className="token__allocation-thumb">
                <img src={piechart} alt="Tokenomics" />
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

</Fragment>
)
}

export default Tokenomics