import React from 'react';
import {calculatePercentageChange} from '../../../utils/contractHelpers';

const PercentageChange = ({ current, previous }) => {
const percentageChange = calculatePercentageChange(current, previous);
const isPositiveChange = percentageChange > 0;
const color = isPositiveChange ? '#10b981d9' : '#ef4444d9';
return (
<>
<span style={{ color }}>
${current}({isPositiveChange ? '+' : ''}{percentageChange}%)
</span>
</>
  );
};

export default PercentageChange;
