import React,{Fragment, useState,useEffect} from 'react';
import logoUrl from '../assets/images/logo/logo2.png';
import contract from '../utils/farmContract';
import LPContract from '../utils/LpBNBContract';
import CountUp from 'react-countup';
import { fromEthToWei,fromWeiToNumber,formatFixedNumber,stakesLp,unStakeLp,claimLp,allowance,getContractAddress,getTokenPrice,getLPPrice } from '../utils/contractHelpers'; //getLPData,sleep,getLPPrice,
import { TokenSymbol } from '../config';
//import PancakeLPAbi from "../utils/bep20Abi.json";
import {toast } from 'react-toastify';
import farmAbi from "../Abi/farmingAbi.json";

import { useWeb3React, useTokenContract,useContract } from '../hooks/useContractHelper'
import { increaseGasLimit,customError } from '../utils/useWeb3ReactManager'



const { REACT_APP_CHAIN_ID } = process.env



function Mining() {
const [stake, setStake] = useState("0");
const [Yield, setYield] = useState("0");
const [Minyield, setMinYield] = useState("0.001");
const [showStakingPopup, setShowStakingPopup] = useState(false);
const openStakingPopup = () => setShowStakingPopup(!showStakingPopup);
const [totalStakePrice, setTotalStakePrice] = useState(0);
//const [data, setData] = useState([]);
const [myStake, setMyStake] = useState(0);
const [currentStake, setCurrentStake] = useState("0");
const [mytotalStakePrice, setMyTotalStakePrice] = useState(0);
const [myBalance, setMyBalance] = useState(0);
const {library, chainId,account,active} = useWeb3React();
const [approved, setApproved] = useState(true);
const [chain, setChain] = useState(Number(REACT_APP_CHAIN_ID));

const liquidityContract = useTokenContract(getContractAddress('liquidity'))
const farmContract = useContract(getContractAddress('farm'),farmAbi)
let [isLoading,setIsLoading] = useState(false);
let [isLoading2,setIsLoading2] = useState(false);
let [isLoading3,setIsLoading3] = useState(false);






const onChangeHandler = async (amt) => {
setStake(amt);
//console.log(amt);
if(active && chainId===chain)
if(amt>0){
  var amount=fromEthToWei(amt);
  let liquidityAddress=getContractAddress('liquidity');
  var res= await allowance(account,amount,liquidityAddress,'farm');
  //console.log(res);
  setApproved(res);
}else{
  setApproved(true);
}
}


useEffect(() => {
getStakeInfo();
if(account){
getTokenBalance(account);
getMyDeposit(account);
}
}, [account])



const getStakeInfo = async () => {
  try {
    const result = await contract.methods.totalStakedToken().call();
  setTotalStakePrice(formatFixedNumber(Number(fromWeiToNumber(result))));
 // var drl=  await getLPPrice('0xfc76c4e496d13f1b0dcec2536d9cdcbf8d3d4390',9539);

  } catch (error) {

  }
  } 

  //

  const getMyDeposit = async (account) => {
    let newData={};
    try {
    const result = await contract.methods.userStakes(account).call();
    const orderHistory = result;
    newData.amount=orderHistory.amount;
    newData.lastClaimed=orderHistory.lastClaimed;
    newData.pendingRewards=orderHistory.amount >0? await contract.methods.getRewards(account).call():orderHistory.pendingRewards;
    newData.rewardsClaimed=orderHistory.rewardsClaimed;
   setCurrentStake(newData.amount);
   setYield(newData.pendingRewards)
    } catch (error) {
    } 
    }



const getTokenBalance = async (address) => {
try {
const result = await LPContract.methods.balanceOf(address).call();
setMyBalance(fromWeiToNumber(result));
} catch (error) {
} 
}



const stakeHandle = async () =>{
  if(!active) return toast.error("Connect Your Wallet!", {position: toast.POSITION.TOP_RIGHT});
  if(active && chainId===chain)
  if(stake>0){
  if(stake > myBalance)return toast.error("Insufficient balance!", {position: toast.POSITION.TOP_RIGHT});
  setIsLoading(true);
  var amountInWei=fromEthToWei(stake);
  try {
    const gasEstimated = await farmContract.estimateGas.stake(amountInWei);
    var tx=  await farmContract.stake(amountInWei,{gasLimit: increaseGasLimit(gasEstimated)})
    let result = await tx.wait()
   if(result && result.status){
      toast.success("Stake Success!", {position: toast.POSITION.TOP_RIGHT});
      await getTokenBalance(account);
      await getMyDeposit(account)
      setApproved(false);
    }
    setIsLoading(false);
  } catch (error) {
    setIsLoading(false);
    toast.error(customError(error), {position: toast.POSITION.TOP_RIGHT});
    }
  }
  }

const unStakeHandle = async () =>{
  if(!active) return toast.error("Connect Your Wallet!", {position: toast.POSITION.TOP_RIGHT});
  if(active && chainId===chain)
  if (myStake>0){
  var amountInWei=fromEthToWei(myStake);
  if(amountInWei > currentStake)return toast.error("Insufficient balance!", {position: toast.POSITION.TOP_RIGHT});
  setIsLoading2(true);
  try {
  const gasEstimated = await farmContract.estimateGas.withdraw(amountInWei);
  var tx=  await farmContract.withdraw(amountInWei,{gasLimit: increaseGasLimit(gasEstimated)})
  let result = await tx.wait()
  if(result && result.status){
  toast.success("Unstake Success!", {position: toast.POSITION.TOP_RIGHT});
  await getTokenBalance(account);
  await getMyDeposit(account)
  }
  setIsLoading2(false);
} catch (error) {
  setIsLoading2(false);
  toast.error(customError(error), {position: toast.POSITION.TOP_RIGHT});
  }
  }
  }


const claimHandle = async () =>{
  if(!active) return toast.error("Connect Your Wallet!", {position: toast.POSITION.TOP_RIGHT});
  if(active && chainId===chain)
  setIsLoading3(true);
  try {
    const gasEstimated = await farmContract.estimateGas.claim();
    var tx=  await farmContract.claim({gasLimit: increaseGasLimit(gasEstimated)})
    let result = await tx.wait()
  if(result && result.status){
  toast.success("Claimed Success!", {position: toast.POSITION.TOP_RIGHT});
  await getTokenBalance(account);
  await getMyDeposit(account)
  }
  setIsLoading3(false);
  } catch (error) {
    setIsLoading3(false);
    toast.error(customError(error), {position: toast.POSITION.TOP_RIGHT});
  }
  
  }


const approveHandle = async () =>{
  if(!active) return toast.error("Connect Your Wallet!", {position: toast.POSITION.TOP_RIGHT});
  if(active && chainId===chain)
  if(stake>0){
    var amountInWei=fromEthToWei(stake);
    setIsLoading(true);
    try {
    var tx=await liquidityContract.approve(getContractAddress('liquidity'), amountInWei)//ethers.constants.MaxUint256
    await tx.wait()
    setApproved(false);
    setIsLoading(false);
  } catch (error) {
    setIsLoading(false);
    toast.error(customError(error), {position: toast.POSITION.TOP_RIGHT});
    }
  }
}


const setMax = async (type) =>{
  if(type==='deposit'){
  setStake(myBalance);
  onChangeHandler(myBalance);
  }else{
  setMyStake(fromWeiToNumber(currentStake));
  }
  
  
  
}


return (
<Fragment>
  <div className="farming padding-top padding-bottom">
    <div className="container">
      <div className="farming__wrapper">
        <div className="row g-5">

          <div className="col-12">
            <div className="farming__item">
              <div className="farming__item-header" onClick={(e) => openStakingPopup()}>
                <div className="farming__thumb">
              <img src={logoUrl} />
                </div>
                <div className="farming__content">
                  <div className="farming__content-title">
                    <h4>BNB-TPAD LP Staking</h4>
                    <p>BNB-TPAD</p>
                  </div>
                  <p>TorkPad is a decentralized multi-chain fundraising platform that allows ventures to raise funds while also providing early stage investors with security.</p>
                  <ul className="farming__list">
                    <li className="farming__list-item">
                      <h5>${currentStake>0?formatFixedNumber(Number(fromWeiToNumber(currentStake))):currentStake} LP</h5>
                      <p>Staked</p>
                    </li>
                    <li className="farming__list-item">
                      <h5>{Yield>0?formatFixedNumber(Number(fromWeiToNumber(Yield))):Yield} {TokenSymbol}</h5>
                      <p>Earned</p>
                    </li>
                    <li className="farming__list-item">
                      <h3 className="color--theme-color">
                      <CountUp end={(12000 /100)} />%
                      </h3>
                      <p>APY</p>
                    </li>
                    <li className="farming__list-item">
                      <h5>${mytotalStakePrice}</h5>
                      <p>LP Price</p>
                    </li>
                    <li className="farming__list-item">
                      <h5>${totalStakePrice}</h5>
                      <p>Total Value Locked</p>
                    </li>
                  </ul>
                </div>
              </div>



              <div className="farming__item-body" style={{ display: showStakingPopup ? "block" : '' }}  >
                <div className="farming__item-content">
                  <div className="row g-5">
                    <div className="col-lg-4 col-md-6">
                      <div className="farming__deposit">
                        <div className="farming__deposit-field">
                          <h6 className="form-label">Deposit</h6>
                          <div className="input-group mb-3">
                            <input type="text" className="form-control" value={stake} onChange={(e) => onChangeHandler(e.target.value)} placeholder={0.00} aria-label="Deposit input" />
                            <span className="input-group-text" onClick={(e) => setMax('deposit')}>max</span>
                          </div>
                          <div className="text-end">

{isLoading?(
<button type="button" className="default-btn default-btn--small" disabled> <i class="fas fa-spinner fa-spin"></i> {approved?"Approve":"Stake"}</button>
):(
<button type="button" onClick={(e) =>  approved?approveHandle():stakeHandle()} className="default-btn default-btn--small">{approved?"Approve":"Stake"}</button>
)}


</div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <div className="farming__withdraw">
                        <div className="farming__withdraw-field">
                          <h6 className="form-label">Withdraw</h6>
                          <div className="input-group mb-3">
                            <input type="text" className="form-control" placeholder={0.00} value={myStake} onChange={(e) => setMyStake(e.target.value)} aria-label="Withdraw input" />
                            <span className="input-group-text" onClick={(e) => setMax('withdraw')}>max</span>
                          </div>
                          <div className="text-end">
{isLoading2?(
<button type="button" className="default-btn default-btn--small" disabled><i class="fas fa-spinner fa-spin"></i> Withdraw</button>
):(
<button type="button" onClick={(e) => unStakeHandle()} className="default-btn default-btn--small">Withdraw</button>
)}

</div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <div className="farming__claim">
                        <div className="farming__claim-title">
                          <h6>Pending Rewards</h6>
                          <h4>{Yield>0?formatFixedNumber(Number(fromWeiToNumber(Yield))):Yield} {TokenSymbol}</h4>
                        </div>
                        <div className="text-end">
                          



{isLoading3?(
<button className="default-btn default-btn--small" disabled><i class="fas fa-spinner fa-spin"></i> Claim</button>
):(
<button disabled={Yield>Minyield?false:true} className="default-btn default-btn--small" onClick={(e) => claimHandle()}>Claim</button>
)}







                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>




        </div>
      </div>
    </div>
  </div>

</Fragment>
)
}

export default Mining