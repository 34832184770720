export const partners = [{
  "name":"Smart Chain",
  "logoUrl": "/assets/images/partners/1.png",
  "website": "#"
},
{
  "name":"Cognitos",
  "logoUrl": "/assets/images/partners/2.png",
  "website": "https://cognitos.io/"
},
{
  "name":"Wise Crypto",
  "logoUrl": "/assets/images/partners/3.png",
  "website": "https://wisecrypto.co/"
},
{
  "name":"Securi Lab",
  "logoUrl": "/assets/images/partners/4.png",
  "website": "https://securi-lab.com/"
}
,
{
  "name":"Blade Community",
  "logoUrl": "/assets/images/partners/5.png",
  "website": "https://twitter.com/Bladecommunityy"
}
,
{
  "name":"Nuls",
  "logoUrl": "/assets/images/partners/6.png",
  "website": "https://nuls.io/"
}
,
{
  "name":"Nerve",
  "logoUrl": "/assets/images/partners/7.png",
  "website": "https://nerve.network/"
}
,
{
  "name":"Vital Block",
  "logoUrl": "/assets/images/partners/8.png",
  "website": "https://vitalblock.org/"
}
,
{
  "name":"Meta Star",
  "logoUrl": "/assets/images/partners/9.png",
  "website": "https://mobile.twitter.com/META_STARx"
}
,
{
  "name":"Star Venture",
  "logoUrl": "/assets/images/partners/10.png",
  "website": "https://twitter.com/StarVenture6"
}
,
{
  "name":"MarsPad",
  "logoUrl": "/assets/images/partners/11.png",
  "website": "https://marspad.pro/"
}
,
{
  "name":"ContractWolf",
  "logoUrl": "/assets/images/partners/12.png",
  "website": "https://contractwolf.io/"
}
,
{
  "name":"SecureWise",
  "logoUrl": "/assets/images/partners/13.png",
  "website": "https://securewise.info"
}
,
{
  "name":"Psalms Capital",
  "logoUrl": "/assets/images/partners/14.png",
  "website": "https://www.psalmscapital.com/"
}
,
{
  "name":"AstrobiaTech",
  "logoUrl": "/assets/images/partners/15.png",
  "website": "https://astrobiatech.in/"
}
];
