import React,{Fragment, useState,useEffect} from 'react';
//import {SiteName,TokenSymbol} from '../config';
import { useWeb3React } from "@web3-react/core";

    const Kyc = () => {
        const {library, chainId,account,active} = useWeb3React();
    const [currentTab, setCurrentTab] = useState(0);


    useEffect(() => {
        showTab(0);
        
        }, [account])
        
   
        const onChangeHandler = async () => {
           
            }
            


    function showTab(n) {
        // This function will display the specified tab of the form...
        var x = document.getElementsByClassName("step");
        x[n].style.display = "block";
        //... and fix the Previous/Next buttons:
        if (n == 0) {
            document.getElementById("prevBtn").style.display = "none";
        } else {
            document.getElementById("prevBtn").style.display = "inline";
        }
        if (n == (x.length - 1)) {
            document.getElementById("nextBtn").innerHTML = "Submit";
        } else {
            document.getElementById("nextBtn").innerHTML = "Next";
        }
        //... and run a function that will display the correct step indicator:
        fixStepIndicator(n)
    }
    const nextPrev = async (n) => {
        // This function will figure out which tab to display
        var x = document.getElementsByClassName("step");
        // Exit the function if any field in the current tab is invalid:
        if (n == 1 && !validateForm()) return false;
        // Hide the current tab:
        x[currentTab].style.display = "none";
        // Increase or decrease the current tab by 1:
        
        var newcurrentTab = currentTab + n;
        setCurrentTab(newcurrentTab);
        // if you have reached the end of the form...
        if (newcurrentTab >= x.length) {
            // ... the form gets submitted:
            document.getElementById("kycForm").submit();
            return false;
        }
        // Otherwise, display the correct tab:
        showTab(newcurrentTab);
    }
    
    function validateForm() {
        // This function deals with validation of the form fields
    
        var x, y, i, valid = true;
        x = document.getElementsByClassName("step");
        y = x[currentTab].getElementsByTagName("input");
        // A loop that checks every input field in the current tab:
        for (i = 0; i < y.length; i++) {
            // If a field is empty...
            if (y[i].value == "") {
                // add an "invalid" class to the field:
                y[i].className += " invalid";
                // and set the current valid status to false
    
                // valid=false 
    
                //set the valid value false like above for input validation 
                //we did valid true only just for demo purpose
                valid = true;
            }
        }
    
    
        // If the valid status is true, mark the step as finished and valid:
        if (valid) {
            document.getElementsByClassName("stepIndicator")[currentTab].className += " finish";
        }
        return valid; // return the valid status
    }
    
    function fixStepIndicator(n) {
        // This function removes the "active" class of all steps...
        var i, x = document.getElementsByClassName("stepIndicator");
        for (i = 0; i < x.length; i++) {
            x[i].className = x[i].className.replace(" active", "");
        }
        //... and adds the "active" class on the current step:
        x[n].className += " active";
    }












return (
<Fragment>
<section className="kyc padding-top padding-bottom">
    <div className="container">
      <div className="kyc__wrapper">
        <form className="kyc__form" id="kycForm" action="#!">
          <div className="form-header d-flex">
            <span className="stepIndicator">Account Setup</span>
            <span className="stepIndicator">Social Profiles</span>
            <span className="stepIndicator">Personal Details</span>
          </div>
          <div className="step">
            <h4 className="text-center">Choose Verification Option</h4>
            <div className="form-group mb-5">
              <h6>Select Region</h6>
              <select className="form-select" aria-label="Select Region">
                <option>USA</option>
                <option>UK</option>
                <option>Canada</option>
                <option>France</option>
                <option>Spain</option>
              </select>
            </div>
            <div className="form-group mb-4">
              <h6>Select Identity Type</h6>
              <div className="select-group">
                <div className="form-check">
                  <input className="form-check-input" type="radio" name="identy-check" id="nid-check" />
                  <label className="form-check-label" htmlFor="nid-check">
                    National ID
                  </label>
                </div>
                <div className="form-check">
                  <input className="form-check-input" type="radio" name="identy-check" id="passport-check" defaultChecked />
                  <label className="form-check-label" htmlFor="passport-check">
                    Passport
                  </label>
                </div>
                <div className="form-check">
                  <input className="form-check-input" type="radio" name="identy-check" id="license-check" defaultChecked />
                  <label className="form-check-label" htmlFor="license-check">
                    Driving License
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="step">
            <h4 className="text-center">Upload Documents</h4>
            <ul className="upload__list mb-5">
              <li className="upload__item">
                <div className="custom-upload">
                  <span><i className="fa-solid fa-cloud-arrow-up" /></span>
                  <input className="fileUp" type="file" name="nid-front" />
                </div>
                <p>NID Front Photo</p>
              </li>
              <li className="upload__item">
                <div className="custom-upload">
                  <span><i className="fa-solid fa-cloud-arrow-up" /></span>
                  <input className="fileUp" type="file" name="nid-back" />
                </div>
                <p>NID Back Photo</p>
              </li>
              <li className="upload__item">
                <div className="custom-upload">
                  <span><i className="fa-solid fa-cloud-arrow-up" /></span>
                  <input className="fileUp" type="file" name="selfie-with-nid" />
                </div>
                <p>Selfie With NID</p>
              </li>
            </ul>
            <ul className="rules__list mb-5">
              <li className="rules__item"> <span><i className="fa-solid fa-square-check" /></span> File
                Accepted: JPEG/JPG/PNG (Max size: 10 MB) </li>
              <li className="rules__item rules__item--active"> <span><i className="fa-solid fa-square-check" /></span> Document
                should be good condition </li>
              <li className="rules__item"> <span><i className="fa-solid fa-square-check" /></span> Face
                must be
                clear visible</li>
              <li className="rules__item"> <span><i className="fa-solid fa-square-check" /></span>
                Document must
                be valid period</li>
            </ul>
            <div className="form-group mb-5">
              <h6>Enter NID Number</h6>
              <input className="form-control" type="text" name="nid-number" placeholder="eg: 29348 9348 398" />
            </div>
          </div>
          <div className="step">
            <h4 className="text-center">Personal Information</h4>
            <div className="form-group mb-4">
              <h6>Full Name</h6>
              <input className="form-control" type="text" name="Full Name" placeholder="eg: Alex Hales" />
            </div>
            <div className="form-group mb-4">
              <h6>State/Provience</h6>
              <input className="form-control" type="text" name="state Name" placeholder="eg: Los Angeles" />
            </div>
            <div className="form-group mb-4">
              <h6>Address Line</h6>
              <input className="form-control" type="text" name="address line" placeholder="eg: 653 Jett Lane" />
            </div>
            <div className="form-group mb-4">
              <h6>City Name</h6>
              <input className="form-control" type="text" name="city name" placeholder="eg: Los Angeles" />
            </div>
            <div className="form-group mb-4">
              <h6>Postal Code</h6>
              <input className="form-control" type="text" name="Postal code" placeholder="eg: 90017" />
            </div>
          </div>
          <div className="form-footer d-flex">
            <button type="button" id="prevBtn" onClick={(e) => nextPrev(-1)}>Previous</button>
            <button type="button" id="nextBtn" onClick={(e) => nextPrev(1)}>Next</button>
          </div>
        </form>
      </div>
    </div>
  </section>

</Fragment>
)
}

export default Kyc
//https://paidnetwork.com/