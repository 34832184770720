//import {SiteName,TokenSymbol} from '../config';
import React,{Fragment, useState, useEffect} from 'react';

import bg from '../../assets/images/header/bg.jpg';
import logoImg from '../../assets/images/logo/soon.png';
import bannerImg from '../../assets/images/igo/item/01.jpg';
import CountUp from 'react-countup';
import {toast } from 'react-toastify';

import Parser from 'html-react-parser';
import { Link } from 'react-router-dom'

import { ethers } from 'ethers'
import { isMobile } from 'react-device-detect'
import { useWeb3React, useTokenContract,useContract } from '../../hooks/useContractHelper'
import { increaseGasLimit,customError,getRPCErrorMessage } from '../../utils/useWeb3ReactManager'
import saleAbi from "../../Abi/Sale.json";

import web3 from "../../utils/web3";
import { createWatcher } from '@makerdao/multicall';



import {isWhatPercentOf,getPoolData,getTotalRaise,getTotalContributors,fromEthToWei,fromWeiToNumber,formatFixedNumber,allowanceSale, getContractAddress,saleClaim,numFormatter,networkIcon} from '../../utils/contractHelpers';

import {MyTimer} from '../Widget/MyTimer';
import moment from 'moment';

const { REACT_APP_CHAIN_ID } = process.env
const defaultWatcher = createWatcher([], {});

const Details = ({data}) => {
const {library, chainId,account,active } = useWeb3React();
const tokenContract = useTokenContract(getContractAddress(data?.currency));
const saleContract = useContract(data?.saleAddress,saleAbi);
const [isTrigger, setIsTrigger] = useState(false);
const [amount, setAmount] = useState("0");
const [approved, setApproved] = useState(true);
const [claimToken, setClaimToken] = useState(data?.claimToken);
const [saleAddress, setSaleAddress] = useState(data?.saleAddress);
const [maxAmount, setMaxAmount] = useState("0");
const [cap, setCap] = useState(data?.cap);
const [hardCap, setHardCap] = useState(data?.hardCap.replace(",",""));
const [chain, setChain] = useState(Number(REACT_APP_CHAIN_ID));

let [isLoading,setIsLoading] = useState(false);
let [isSaleEnd,setIsSaleEnd] = useState(true);


useEffect(() => {
 if(data){
 setMaxAmount(data.maxAllocation.replace(data.currency,""))
 getPoolInfo(data.saleAddress);
 //getContributorsFiles(data.saleAddress,40);
  }
  }, [data])
  
  useEffect(() => {
if(data && !data.saleAddress) return;
const multiCallConfig = {
web3,
multicallAddress: getContractAddress("multicall"),
interval: 10000
 };
 defaultWatcher.stop();

 defaultWatcher.recreate(
[
  {
 target: data.saleAddress,
 call: ['totalRaise()(uint256)'],
 returns: [['totalRaise', (val) => val.toString()]]
  }
],
multiCallConfig
 );
defaultWatcher.subscribe((update) => {
setCap(fromWeiToNumber(update.value));
});
 defaultWatcher.start();
  }, [web3]);

const getPoolInfo = async (saleAddress) => {
  if(!saleAddress) return;
try {
var result=await getTotalRaise(saleAddress);
setCap(fromWeiToNumber(result[0]));
//setIsCurrentDateTime(result[1]);
//console.log(result[2][0]);
//console.log(result[2][1]);
if(result[1] <data.closeTime){
setIsSaleEnd(false);
}
} catch (error) {
console.log(error,'err');
}
}



const getContributorsFiles = async (saleAddress,indexLast) => {
try {
var result=await getTotalContributors(saleAddress,indexLast);
result[0].forEach((address, index) => {
const amount = result[1][index];
let tgeamount=amount/100*10



var fortyPer=fromWeiToNumber(tgeamount);
//console.log(fortyPer);
//console.log(fromWeiToNumber(amount));
//console.log(fortyPer/0.2);
console.log(address, (fortyPer/0.0075).toFixed());
//console.log(address, fromWeiToNumber(amount));
//console.log(address, (fortyPer/100*50)/0.0075);
});
} catch (error) {
console.log(error,'err');
}
}

 const onChangeHandler = async (amt) => {
setAmount(amt);
if(active && chainId===chain)
if(amt>0){
  var amount=fromEthToWei(amt);
  var res= await allowanceSale(account,amount,data?.saleAddress,data?.currency);
  setApproved(res);
}else{
  setApproved(true);
}
}


const contributeHandle = async () =>{
  if(!active) return toast.error("Connect Your Wallet!", {position: toast.POSITION.TOP_RIGHT});
  if(active && chainId===chain)
  if(amount>0){
  var amountInWei=fromEthToWei(amount);
  setIsLoading(true);
try {
 const gasEstimated = await saleContract.estimateGas.contribute(amountInWei);
 var tx=  await saleContract.contribute(amountInWei,{gasLimit: increaseGasLimit(gasEstimated)})
 let result = await tx.wait()
 if(result && result.status){
toast.success("Contributed Success!", {position: toast.POSITION.TOP_RIGHT});
  await getPoolInfo(saleAddress);
setApproved(false);
 }
setIsLoading(false);
  } catch (error) {
setIsLoading(false);
toast.error(customError(error).replace("execution reverted:",""), {position: toast.POSITION.TOP_RIGHT});
 }
}else{
return toast.error("Please enter amount!", {position: toast.POSITION.TOP_RIGHT});
}
}
  

  
const claimHandle = async () =>{
if(active && chainId===chain)
try {
var result=await saleClaim(library,account,data?.saleAddress);
if(result && result.status){
toast.success("Claimed Success!", {position: toast.POSITION.TOP_RIGHT});
}
} catch (error) {
 var err= (
 error.response && error.response.data.message
 ? error.response.data.message
 : error.message);
 if (error && error.message) {
 let isExist = error.message.includes("Internal JSON-RPC error.");
 if(isExist){
 let errorTxt=error.message.replace("Internal JSON-RPC error.", "");
 let obj=JSON.parse(errorTxt);
 return toast.error(obj.message.replace("execution reverted:",""), {position: toast.POSITION.TOP_RIGHT});
 }
 }
 toast.error(err, {position: toast.POSITION.TOP_RIGHT});
 }

  }
  
const approveHandle = async () =>{
if(!active) return toast.error("Connect Your Wallet!", {position: toast.POSITION.TOP_RIGHT});
if(active && chainId===chain)
if(amount>0){
var amountInWei=fromEthToWei(amount);
setIsLoading(true);
try {
var tx=await tokenContract.approve(data?.saleAddress, amountInWei)//ethers.constants.MaxUint256
await tx.wait()
setApproved(false);
setIsLoading(false);
} catch (error) {
setIsLoading(false);
toast.error(customError(error).replace("execution reverted:",""), {position: toast.POSITION.TOP_RIGHT});
}
}else{
return toast.error("Please enter amount!", {position: toast.POSITION.TOP_RIGHT});
}
}
  
const switchNetworkHandle = async () =>{
if (window.ethereum.networkVersion !== chainId) {
try {
await window.ethereum.request({
method: 'wallet_switchEthereumChain',
params: [{ chainId: "0x38" }]
});
} catch (err) {
if (err.code === 4902) {
await window.ethereum.request({
method: 'wallet_addEthereumChain',
params: [
{
chainName: 'Binance Smart Chain Mainnet',
chainId: "0x38",
nativeCurrency: {
name: "BINANCE COIN",
symbol: "BNB",
decimals: 18,
},
rpcUrls: ["https://bsc-dataseed1.binance.org"],
blockExplorerUrls: ["https://bscscan.com/"],
iconUrls: [
"https://bin.bnbstatic.com/image/admin_mgs_image_upload/20201110/550eda20-1b9a-4bc7-9a65-e4a329e8bb57.png"
]
}
]
});
}
}
}
}

return (


 <Fragment>


  <section className="pro-details padding-top padding-bottom">
 <div className="container">
<div className="pro-details__wrapper">
  <div className="pro-details__block mb-4">
 <div className="pro-details__block-inner">
<div className="row g-5 align-items-center">
  <div className="col-lg-7">
 <div className="pro-details__item-wrap">
<div className="pro-details__item">
  <div className="pro-details__item-thumb">
 <img src={data.logoUrl} alt="IGO Project" />
  </div>




  <div className="project__item-thumb" style={{overflow: 'visible'}}>
 <span className="badge">
 <img src={networkIcon(data.blockchain)} alt="BlockChain" />

  
  
 </span>
  </div>




<div className="pro-details__item-content">
<h4>{data.tokenName} <span>({data.tokenSymbol})</span></h4>




{data.description &&
<p>{Parser(data.description)}</p>
}
  </div>
</div>
<div className="btn-group mt-4 d-flex flex-wrap gap-20">
{data?.claimToken &&

<a href={void(0)} onClick={(e) =>  claimHandle()} className="default-btn default-btn--small"><span>Claim
Token</span></a>
  }
{data.whitelistUrl &&
  <a href={data.whitelistUrl} target="_blank" rel="noopener noreferrer" className="default-btn default-btn--small default-btn--secondary"><span>Register Now</span></a>
}


</div>
 </div>
  </div>
  <div className="col-lg-5">
 <div className="pro-details__info">
<div className="row g-3">

{isSaleEnd ? (
<>
<div className="col-sm-6">
 <div className="pro-details__info-item">
<h6 className="pro-details__info-name">
  Total Supply
</h6>
<p className="pro-details__info-value">
  {data.totalSupply} {data.tokenSymbol}
</p>
 </div>
  </div>
  <div className="col-sm-6">
 <div className="pro-details__info-item">
<h6 className="pro-details__info-name">
  FDV
</h6>
<p className="pro-details__info-value">
  {data.initialFDV}
</p>
 </div>
  </div>
  <div className="col-sm-6">
 <div className="pro-details__info-item">
<h6 className="pro-details__info-name">
  Initial Supply
</h6>
<p className="pro-details__info-value">
  {data.initialSupply} {data.tokenSymbol}
</p>
 </div>
  </div>
  <div className="col-sm-6">
 <div className="pro-details__info-item">
<h6 className="pro-details__info-name">
  Initial Market Cap
</h6>
<p className="pro-details__info-value">
  {data.initialMarketCap}
</p>
 </div>
  </div>
</>
) : (
<>

<div className="col-sm-12">
<div className="pro-details__info-item"> 
<ul className="project__infolist">
  <li className="project__infolist-item">
 <p className="project__infolist-name">


 {data?.openTime * 1000 > Date.now() ? (
<>
Project Start
</>
) : data?.closeTime * 1000 > Date.now() ? (
<>
Project End
</>
) : (
<>
Sale
</>
)}

 </p>
 <p className="project__infolist-data">



 {data?.openTime==="TBA" ? (
<>
TBA
</>
) : data?.openTime * 1000 > Date.now() ? (
<>
<MyTimer expiryTimestamp={new Date(data?.openTime*1000)} setIsTrigger={setIsTrigger} />
</>
) : data?.closeTime * 1000 > Date.now() ? (
<>
<MyTimer expiryTimestamp={new Date(data?.closeTime*1000)} setIsTrigger={setIsTrigger} />
</>
) : (
<>
Completed
</>
)}

</p>
  </li>
</ul>
 
 <div className="project__item-amount mb-3">
  <h6><span className="color--theme-color">
 
 
  {numFormatter(cap)} </span> / <span> {cap==="TBA"?cap:numFormatter(hardCap)} {data.currency}
  
  
  </span>
  </h6>
  <div className="progress mb-2">
 <div className="progress-bar" role="progressbar" style={{width: isWhatPercentOf(cap,hardCap)+'%'}} aria-valuenow={isWhatPercentOf(cap,hardCap)} aria-valuemin={0} aria-valuemax={100} />
  </div>

  <div className="input-group">
 <input type="text" className="form-control"  value={amount} onChange={(e) => onChangeHandler(e.target.value)} placeholder={0.00} style={{backgroundColor: '#02121d', borderColor: '#02121d', color: '#c1c7cb', paddingInlineEnd: '10px'}}/>
 <span className="input-group-text" onClick={(e) => onChangeHandler(maxAmount)} style={{backgroundColor: '#02121d', color: 'rgba(193,199,203,.3)', borderColor: '#02121d'}}>Max</span>
  </div>
</div>

<div className="text-center">

{chainId===Number(REACT_APP_CHAIN_ID)?(
<>
{isLoading?(
<button  className="default-btn default-btn--small" disabled> <i className="fas fa-spinner fa-spin"></i>  {approved?"Approve":"Contribute"}</button>
):(
<button  className="default-btn default-btn--small"  onClick={(e) =>  approved?approveHandle():contributeHandle()}>{approved?"Approve":"Contribute"}</button>
)}
</>
):(
<>
{account?(
<button  className="default-btn default-btn--small"  onClick={(e) =>  switchNetworkHandle()}>Switch Network</button>
):(
null
)}

</>






)}







</div>
</div>
</div>
</>
)}

</div>
 </div>
  </div>
</div>
 </div>
  </div>
  <div className="pro-details__token mb-5">
 <div className="row g-4 row-cols-auto ">
<div className="col">
  <div className="pro-details__token-item d-flex flex-wrap justify-content-between align-items-center gap-40">
 <div className="pro-details__token-title">
<span>$</span>
<h6>IDO Price :</h6>
 </div>
 <div className="pro-details__token-value">
<p>({data.tokenSymbol}) {data.idoPrice} <sub>{data.currency}</sub></p>
 </div>
  </div>
</div>
<div className="col">
  <div className="pro-details__token-item">
 <p>{data.tokenDistribution}</p>
  </div>
</div>
<div className="col">
  <div className="pro-details__token-item d-flex flex-wrap justify-content-between align-items-center gap-40">
 <div className="pro-details__token-title">
<span>$</span>
<h6>Listing Price :</h6>
 </div>
 <div className="pro-details__token-value">
<p>({data.tokenSymbol}) {data.listingPrice} <sub>{data.currency}</sub></p>
 </div>
  </div>
</div>
 </div>
  </div>
  <div className="pro-details__desc">
 <div className="row flex-lg-row-reverse g-5">
<div className="col-lg-8">
  <div className="pro-details__desc-content">
 <div data-bs-spy="scroll" data-bs-target="#pro-details-navlist" data-bs-smooth-scroll="true" data-bs-root-margin="0px 0px -60%" className="scrollspy-example" tabIndex={0}>
 {data.about &&
  <section id="pro-details-about">
  <h4>About {data.tokenName}</h4>
  {Parser(data.about)}
</section>
 }


{data.tokenInfo &&
<section id="pro-details-token">
  <h4> {data.tokenName} Token Details</h4>
  {Parser(data.tokenInfo)}
</section>
 }
{data.roadmap &&

<section id="pro-details-roadmap">
  <h4> {data.tokenName} Roadmap</h4>
  {Parser(data.roadmap)}
</section>
 }

{data.backers &&
<section id="pro-details-backers">
  <h4> Our Backers</h4>
  {Parser(data.backers)}
</section>
 }



 </div>
  </div>
</div>
<div className="col-lg-4">
  <aside>
 <div className="pro-details__desc-nav">
<div className="list-group bg--primary-color mb-4" id="pro-details-navlist">
{data.about &&
  <a className="list-group-item list-group-item-action" href="#pro-details-about"><i className="fa-solid fa-window-restore" /> About</a>
}
{data.tokenInfo &&
  <a className="list-group-item list-group-item-action" href="#pro-details-token"> <i className="fa-solid fa-diagram-project" /> Token</a>
}
{data.roadmap &&
  <a className="list-group-item list-group-item-action" href="#pro-details-roadmap">
 <i className="fa-solid fa-road" /> Roadmap</a>
 }{data.backers &&
  <a className="list-group-item list-group-item-action" href="#pro-details-backers"><i className="fa-solid fa-user-astronaut" /> Backers</a>
}
</div>

<div className="pro-details__links">
  <div className="pro-details__links-item">
 <div className="pro-details__links-title">
<h6>Social Media</h6>
 </div>
 <div className="pro-details__links-content">
<ul className="social">


{data.twitter &&
  <li className="social__item">
 <a href={data.twitter} target="_blank" rel="noopener noreferrer" className="social__link"><i className="fab fa-twitter" /></a>
  </li>
 }{data.discord &&
  <li className="social__item">
 <a href={data.discord} target="_blank" rel="noopener noreferrer" className="social__link"><i className="fab fa-discord" /></a>
  </li>
 }{data.telegram &&
  <li className="social__item">
 <a href={data.telegram} target="_blank" rel="noopener noreferrer" className="social__link"><i className="fab fa-telegram" /></a>
  </li>
  }{data.telegramAnn &&
 <li className="social__item">
<a href={data.telegramAnn} target="_blank" rel="noopener noreferrer" className="social__link"><i className="fab fa-telegram" /></a>
 </li>
 }{data.instagram &&
  <li className="social__item">
 <a href={data.instagram} target="_blank" rel="noopener noreferrer" className="social__link"><i className="fab fa-instagram" /></a>
  </li>
  }{data.linkedin &&
  <li className="social__item">
 <a href={data.linkedin} target="_blank" rel="noopener noreferrer" className="social__link"><i className="fab fa-linkedin-in" /></a>
  </li>
  }{data.facebook &&
  <li className="social__item">
 <a href={data.facebook} target="_blank" rel="noopener noreferrer" className="social__link"><i className="fab fa-facebook-f" /></a>
  </li>
  }
  {data.medium &&
  <li className="social__item">
 <a href={data.medium} target="_blank" rel="noopener noreferrer" className="social__link"><i className="fab fa-medium-m" /></a>
  </li>
 }
{data.reddit &&
  <li className="social__item">
 <a href={data.reddit} target="_blank" rel="noopener noreferrer" className="social__link"><i className="fab fa-reddit" /></a>
  </li>
 }

</ul>
 </div>
  </div>


  {data.website &&
  <div className="pro-details__links-item">
 <div className="pro-details__links-title">
<h6>Website Link</h6>
 </div>
 <div className="pro-details__links-content">
<a className="pro-details__links-btn" target="_blank" rel="noopener noreferrer" href={data.website}>{data.website} <span><i className="fa-solid fa-globe" /></span>
</a>
 </div>
  </div>
}
{data.whitepaperUrl &&
  <div className="pro-details__links-item">
 <div className="pro-details__links-title">
<h6>Download Whitepaper</h6>
 </div>
 <div className="pro-details__links-content">
<a className="pro-details__links-btn" target="_blank" rel="noopener noreferrer" href={data.whitepaperUrl}>Whitepaper <span><i className="fa-solid fa-cloud-arrow-down" /></span>
</a>
 </div>
  </div>
}



</div>
 </div>
  </aside>
</div>
 </div>
  </div>
</div>
 </div>
  </section>


</Fragment>

)
}

export default Details
