import Web3 from 'web3';
import {ethNetwork,bscNetwork,polygonNetwork,aptosNetwork,solNetwork,arbitrumNetwork,avalNetwork,cronosNetwork,fantomNetwork,algorandNetwork,nearNetwork,atomNetwork,stellarNetwork,tronNetwork,ozoneNetwork,suiNetwork,cantoNetwork} from '../config';



const networkUrls = {
    //[bscNetwork]: "https://bsc-dataseed1.binance.org:443",
    [bscNetwork]: "https://data-seed-prebsc-1-s1.binance.org:8545/",
    [ethNetwork]: "https://mainnet.infura.io/v3/YOUR_INFURA_API_KEY",
    [polygonNetwork]:"https://rpc-mainnet.maticvigil.com",
    [avalNetwork]:"https://api.avax.network/ext/bc/C/rpc",

};

const getWeb3Instance = (network) => {
  if (!networkUrls[network]) {
    throw new Error(`Network '${network}' is not supported.`);
  }

  return new Web3(new Web3.providers.HttpProvider(networkUrls[network]));
};

export { getWeb3Instance };
