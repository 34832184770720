import React,{Fragment,useState,useEffect} from 'react';
import { Link } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import ProjectCard from '../Card/ProjectBoxCard';
import {getProjectTrendingList} from '../../utils/contractHelpers';

const ProjectUpcoming = () => {
  const [data, setData] = useState([]);
  useEffect(() => {
   const getProject = async () => {
     const result = await getProjectTrendingList();
     setData(result);
   };
   getProject();
 }, []);

return (
<Fragment>

  <section className="project ">
    <div className="container">
      <div className="section-header section-header--left">
        <div className="section-header__content">
          <div className="section-header__titlebar">
            <p className="section-header__subtitle">
            Trending
            </p>
            <h2 className="section__header__title">Most Popular IGO/IDO Projects</h2>
          </div>
          <Link to="/project" className="default-btn default-btn--small"><span>View all</span> </Link>
        </div>
      </div>
      <div className="project__wrapper">
        <div className="swiper project__slider py-2">
         

  <Swiper

className="swiper-wrapper"

      modules={[Pagination]}
      spaceBetween={20}
      slidesPerView={1}
      speed={1200}
      loop={false}
      pagination={{el:".project__slider-pagination",clickable:true,}}
   //   pagination={{ clickable: true }}

   autoplay={{delay:3500,disableOnInteraction:false,}}

      breakpoints={{
        576: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        992: {
          slidesPerView: 3,
          spaceBetween: 24,
        },
        
      }}
    >

      {data.map((item, index) => (

<SwiperSlide key={index} className="swiper-slide">
<ProjectCard item={item}/>
</SwiperSlide>

))}






    </Swiper>

{data.length>0  ?
<div className="project__slider-pagination mt-4 text-center" />
:
null
}

    



        </div>
      </div>
    </div>
  </section>









</Fragment>
)
}

export default ProjectUpcoming
