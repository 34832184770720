import React,{Fragment} from 'react';

function Loader() {
return (
<Fragment>
<div className="preloader">
  <div className="preloader__inner">
    <div className="preloader__icon">
      <span />
      <span />
    </div>
  </div>
</div>
</Fragment>
)
}

export default Loader
