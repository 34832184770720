import React,{Fragment, useState,useEffect} from 'react';
import contract from '../utils/stakeContract';
import token from '../utils/tokenContract';
import { fromEthToWei,fromWeiToNumber,formatFixedNumber,allowance,getContractAddress,getReactEnv,getTokenPrice,getTokenWorth} from '../utils/contractHelpers'
import { TokenSymbol } from '../config';
import CountUp from 'react-countup';
import {toast } from 'react-toastify';
import stakeAbi from "../Abi/stakingAbi";

import { useWeb3React, useTokenContract,useContract } from '../hooks/useContractHelper'
import { increaseGasLimit,customError } from '../utils/useWeb3ReactManager'
import formatBigNumber from "../utils/bigNumber";

const { REACT_APP_CHAIN_ID } = process.env

const Staking = () => {
const [stake, setStake] = useState("0");
const [Yield, setYield] = useState("0");
const [Minyield, setMinYield] = useState("0.001");
const [currentStake, setCurrentStake] = useState("0");
const [lockTime, setLockTime] = useState(0);
const [activeTab, setActiveTab] = useState("7");
const [data, setData] = useState([]);
const [myStake, setMyStake] = useState(0);
const [totalStake, setTotalStake] = useState(0);
const [totalStaker, setTotalStaker] = useState(0);
const [totalStakePrice, setTotalStakePrice] = useState(0);
const [myBalance, setMyBalance] = useState(0);
const {library, chainId,account,active} = useWeb3React();
const [approved, setApproved] = useState(true);
const [chain, setChain] = useState(Number(REACT_APP_CHAIN_ID));
const tokenContract = useTokenContract(getContractAddress('token'))
const stakeContract = useContract(getContractAddress('stake'),stakeAbi)
let [isLoading,setIsLoading] = useState(false);
let [isLoading2,setIsLoading2] = useState(false);
let [isLoading3,setIsLoading3] = useState(false);


const onChangeHandler = async (amt) => {
setStake(amt);
if(active && chainId===chain)
if(amt>0){
  var amount=fromEthToWei(amt);
  let tokenAddress=getContractAddress('token');
  var res= await allowance(account,amount,tokenAddress,'stake');
  setApproved(res);
}else{
  setApproved(true);
}
}


useEffect(() => {
getStakeInfo();
if(account){
getTokenBalance(account);
getMyDeposit(account)
}
}, [account])


const getStakeInfo = async () => {
  try {

  const [totalStakers,totalStakedToken,balanceOf] = await Promise.all([
    contract.methods.totalStakers().call(),
    contract.methods.totalStakedToken().call(),
    token.methods.balanceOf(getContractAddress('stake')).call()
  ]);
 // setTotalStake(totalStakedToken)
  setTotalStaker(totalStakers);

let currentToken=fromWeiToNumber(balanceOf);


let currentPrice=await getTokenWorth();
setTotalStake(currentToken);
setTotalStakePrice(fromWeiToNumber(balanceOf)*currentPrice);
  } catch (error) {

  }

  } 


  


  //const filteredResult = dataStructs.find((e) => e.userAddress == address.toLowerCase());
//let myData= dataStructs.filter(data => data.refAddress === address.toLowerCase());

  const getMyDeposit = async (account) => {
    var newArray=[];
    
    try {
    const result = await contract.methods.getUserStakeInfo(account).call();
var days= [7,30,180,365];
for (let index = 0; index < result.length; index++) {
  let newData={};
  const orderHistory = result[index];
  newData.amount=orderHistory.amount;
  newData.lastClaimed=orderHistory.lastClaimed;
  newData.lockTime=orderHistory.lockTime;
  newData.lockupDuration=days[index];
  newData.pendingRewards=orderHistory.amount >0? await contract.methods.getRewards(account,days[index]).call():orderHistory.pendingRewards;
  newData.rewardsClaimed=orderHistory.rewardsClaimed;
  if(Number(activeTab)=== newData.lockupDuration){
    setYield(newData.pendingRewards);
    setCurrentStake(newData.amount);
    setLockTime(newData.lockTime);
  }else if(Number(activeTab)===newData.lockupDuration){
    setYield(newData.pendingRewards);
    setCurrentStake(newData.amount);
    setLockTime(newData.lockTime);
  }else if(Number(activeTab)=== newData.lockupDuration){
    setYield(newData.pendingRewards);
    setCurrentStake(newData.amount);
    setLockTime(newData.lockTime);
  }else if(Number(activeTab)=== newData.lockupDuration){
    setYield(newData.pendingRewards);
    setCurrentStake(newData.amount);
    setLockTime(newData.lockTime);
  }
  newArray.push(newData);

}
setData(newArray);
    } catch (error) {
//console.log(error,'getMyDeposit');
    } 
    }

const getTokenBalance = async (address) => {
try {
const result = await token.methods.balanceOf(address).call()
setMyBalance(fromWeiToNumber(result));
} catch (error) {
//console.log(error,'getTokenBalance');
} 
}



const stakeHandle = async () =>{
  if(!active) return toast.error("Connect Your Wallet!", {position: toast.POSITION.TOP_RIGHT});
if(active && chainId===chain)
if(stake>0){
if(stake > myBalance)return toast.error("Insufficient balance!", {position: toast.POSITION.TOP_RIGHT});
setIsLoading(true);
var amountInWei=fromEthToWei(stake);
try {
  const gasEstimated = await stakeContract.estimateGas.stake(activeTab,amountInWei);
  var tx=  await stakeContract.stake(activeTab,amountInWei,{gasLimit: increaseGasLimit(gasEstimated)})
  let result = await tx.wait()
 if(result && result.status){
    toast.success("Stake Success!", {position: toast.POSITION.TOP_RIGHT});
    await getTokenBalance(account);
    await getMyDeposit(account)
    setApproved(false);
  }
  setIsLoading(false);
} catch (error) {
  setIsLoading(false);
    toast.error(customError(error), {position: toast.POSITION.TOP_RIGHT});
  }
}
}

const unStakeHandle = async () =>{
if(!active) return toast.error("Connect Your Wallet!", {position: toast.POSITION.TOP_RIGHT});
if(active && chainId===chain)
if (myStake>0){
  setIsLoading2(true);
var amountInWei=fromEthToWei(myStake);
if(amountInWei > currentStake)return toast.error("Insufficient balance!", {position: toast.POSITION.TOP_RIGHT});
try {
const gasEstimated = await stakeContract.estimateGas.withdraw(activeTab,amountInWei);
var tx=  await stakeContract.withdraw(activeTab,amountInWei,{gasLimit: increaseGasLimit(gasEstimated)})
let result = await tx.wait()
if(result && result.status){
toast.success("Unstake Success!", {position: toast.POSITION.TOP_RIGHT});
await getTokenBalance(account);
await getMyDeposit(account)
}
setIsLoading2(false);
} catch (error) {
  setIsLoading2(false);
  toast.error(customError(error), {position: toast.POSITION.TOP_RIGHT});
  }
}
}

const claimHandle = async () =>{
if(!active) return toast.error("Connect Your Wallet!", {position: toast.POSITION.TOP_RIGHT});
if(active && chainId===chain)
setIsLoading3(true);
try {
const gasEstimated = await stakeContract.estimateGas.claim(activeTab);
var tx=  await stakeContract.claim(activeTab,{gasLimit: increaseGasLimit(gasEstimated)})
let result = await tx.wait()
if(result && result.status){
toast.success("Claimed Success!", {position: toast.POSITION.TOP_RIGHT});
await getTokenBalance(account);
await getMyDeposit(account)
}
setIsLoading3(false);
} catch (error) {
setIsLoading3(false);
toast.error(customError(error), {position: toast.POSITION.TOP_RIGHT});
}
}

const approveHandle = async () =>{
  if(!active) return toast.error("Connect Your Wallet!", {position: toast.POSITION.TOP_RIGHT});
  if(active && chainId===chain)
  if(stake>0){
    var amountInWei=fromEthToWei(stake);
    setIsLoading(true);
    try {
    var tx=await tokenContract.approve(getContractAddress('stake'), amountInWei)//ethers.constants.MaxUint256
    await tx.wait()
    setApproved(false);
    setIsLoading(false);
    } catch (error) {
    setIsLoading(false);
    toast.error(customError(error), {position: toast.POSITION.TOP_RIGHT});
    }
  }
}


const setMax = async (type) =>{
if(type==='deposit'){
setStake(myBalance);
onChangeHandler(myBalance);
}else{
const filteredResult = data.find((e) => e.lockupDuration === activeTab);
if(filteredResult){
setMyStake(fromWeiToNumber(filteredResult.amount));
}else{
setMyStake(0);
}
}
}


const setChangeTab = async (activeTab) =>{
setActiveTab(activeTab);
const filteredResult = data.find((e) => e.lockupDuration === Number(activeTab));
if(filteredResult){
setYield(filteredResult.pendingRewards);
setCurrentStake(filteredResult.amount);
setLockTime(filteredResult.lockTime);
}else{
setYield(0);
setCurrentStake(0);
setLockTime(0);
}
}


return (
<Fragment>
  <div className="stacking padding-top padding-bottom">
    <div className="container">
      <div className="stacking__wrapper">
        <div className="stacking__project">
          <div className="row g-4">
            <div className="col-lg-4 col-sm-6">
              <div className="stacking__project-item">
                <div className="stacking__project-itemInner">
                  <h3>$ <span><CountUp end={(totalStakePrice)} /></span> </h3>
                  <p>Total Value Locked</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="stacking__project-item">
                <div className="stacking__project-itemInner">
                  <h3><span><CountUp end={(totalStake)} /> {TokenSymbol}</span> </h3>
                  <p>Total Staked</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="stacking__project-item">
                <div className="stacking__project-itemInner">
                  <h3><span><CountUp end={(totalStaker)} /></span> </h3>
                  <p>Number of Stakers</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="stacking__details">
          <div className="stacking__title">
            <h3>Participate IGO/IDO Stake</h3>
          </div>
          <div className="stacking__content">
            <div className="row align-items-center g-5">
              <div className="col-lg-7">
                
                <div className="stacking__period">
                  <ul className="stacking__period-list nav nav-pills" id="stackingPeriod" role="tablist">
                    <li className="nav-item">
                      <button type="button" className={"nav-link "+ (activeTab === "7" ? "active" : "")} onClick={(e) => setChangeTab("7")}>7 Days</button>
                    </li>
                    <li className="nav-item">
                      <button type="button" className={"nav-link "+(activeTab === "30" ? "active" : "")} onClick={(e) => setChangeTab("30")}>30 Days</button>
                    </li>
                    <li className="nav-item">
                      <button type="button" className={"nav-link "+(activeTab === "180" ? "active" : "")} onClick={(e) => setChangeTab("180")}>180 Days</button>
                    </li>
                    <li className="nav-item">
                      <button type="button" className={"nav-link "+(activeTab === "365" ? "active" : "")} onClick={(e) => setChangeTab("365")}>365 Days</button>
                    </li>
                  </ul>
                  <div className="tab-content" id="myTabContent">
                    
                    <div className={"tab-pane fade "+(activeTab === "7" ? "show active" : "")}>
                      <div className="stacking__info">
                        <div className="row align-items-center g-5">
                          <div className="col-sm-8">
                            <ul className="stacking__info-list">
                              <li className="stacking__info-item">
                                <p className="stacking__info-name">Lock Period:
                                  <span className="stacking__info-value">7 Days</span>
                                </p>
                              </li>
                              <li className="stacking__info-item">
                                <p className="stacking__info-name">Re-locks on registration:
                                  <span className="stacking__info-value">Yes</span>
                                </p>
                              </li>
                              <li className="stacking__info-item">
                                <p className="stacking__info-name">Early unstake fee:
                                  <span className="stacking__info-value">5%</span>
                                </p>
                              </li>
                              <li className="stacking__info-item">
                                <p className="stacking__info-name">Status:
                                  <span className="stacking__info-value">Unlocked</span>
                                </p>
                              </li>
                              <li className="stacking__info-item">
                                <p className="stacking__info-name">Yield:
                                  <span className="stacking__info-value">{Yield>0?formatFixedNumber(Number(fromWeiToNumber(Yield))):Yield}</span>
                                </p>
                              </li>
                              
                              </ul>
                          </div>
                          <div className="col-sm-4">
                            <div className="stacking__apy">
                              <p>APY Rate </p>
                              <h3>20%</h3>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>


                    <div className={"tab-pane fade "+(activeTab === "30" ? "show active" : "")}>
                      <div className="stacking__info">
                        <div className="row align-items-center g-5">
                          <div className="col-sm-8">
                            <ul className="stacking__info-list">
                              <li className="stacking__info-item">
                                <p className="stacking__info-name">Lock Period:
                                  <span className="stacking__info-value">30 Days</span>
                                </p>
                              </li>
                              <li className="stacking__info-item">
                                <p className="stacking__info-name">Re-locks on registration:
                                  <span className="stacking__info-value">Yes</span>
                                </p>
                              </li>
                              <li className="stacking__info-item">
                                <p className="stacking__info-name">Early unstake fee:
                                  <span className="stacking__info-value">7%</span>
                                </p>
                              </li>
                              <li className="stacking__info-item">
                                <p className="stacking__info-name">Status:
                                  <span className="stacking__info-value">Unlocked</span>
                                </p>
                              </li>
                              <li className="stacking__info-item">
                                <p className="stacking__info-name">Yield:
                                  <span className="stacking__info-value">{Yield>0?formatFixedNumber(Number(fromWeiToNumber(Yield))):Yield}</span>
                                </p>
                              </li>
                            </ul>
                          </div>
                          <div className="col-sm-4">
                            <div className="stacking__apy">
                              <p>APY Rate </p>
                              <h3>40%</h3>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={"tab-pane fade "+(activeTab === "180" ? "show active" : "")}>
                      <div className="stacking__info">
                        <div className="row align-items-center g-5">
                          <div className="col-sm-8">
                            <ul className="stacking__info-list">
                              <li className="stacking__info-item">
                                <p className="stacking__info-name">Lock Period:
                                  <span className="stacking__info-value">180 Days</span>
                                </p>
                              </li>
                              <li className="stacking__info-item">
                                <p className="stacking__info-name">Re-locks on registration:
                                  <span className="stacking__info-value">Yes</span>
                                </p>
                              </li>
                              <li className="stacking__info-item">
                                <p className="stacking__info-name">Early unstake fee:
                                  <span className="stacking__info-value">15%</span>
                                </p>
                              </li>
                              <li className="stacking__info-item">
                                <p className="stacking__info-name">Status:
                                  <span className="stacking__info-value">Unlocked</span>
                                </p>
                              </li>
                              <li className="stacking__info-item">
                                <p className="stacking__info-name">Yield:
                                  <span className="stacking__info-value">{Yield>0?formatFixedNumber(Number(fromWeiToNumber(Yield))):Yield}</span>
                                </p>
                              </li>
                            </ul>
                          </div>
                          <div className="col-sm-4">
                            <div className="stacking__apy">
                              <p>APY Rate </p>
                              <h3>70%</h3>
                            </div>

                      
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={"tab-pane fade "+(activeTab === "365" ? "show active" : "")}>
                      <div className="stacking__info">
                        <div className="row align-items-center g-5">
                          <div className="col-sm-8">
                            <ul className="stacking__info-list">
                              <li className="stacking__info-item">
                                <p className="stacking__info-name">Lock Period:
                                  <span className="stacking__info-value">365 Days</span>
                                </p>
                              </li>
                              <li className="stacking__info-item">
                                <p className="stacking__info-name">Re-locks on registration:
                                  <span className="stacking__info-value">Yes</span>
                                </p>
                              </li>
                              <li className="stacking__info-item">
                                <p className="stacking__info-name">Early unstake fee:
                                  <span className="stacking__info-value">30%</span>
                                </p>
                              </li>
                              <li className="stacking__info-item">
                                <p className="stacking__info-name">Status:
                                  <span className="stacking__info-value">Unlocked</span>
                                </p>
                              </li>
                              <li className="stacking__info-item">
                                <p className="stacking__info-name">Yield:
                                  <span className="stacking__info-value">{Yield>0?formatFixedNumber(Number(fromWeiToNumber(Yield))):Yield}</span>
                                </p>
                              </li>
                            </ul>
                          </div>
                          <div className="col-sm-4">
                            <div className="stacking__apy">
                              <p>APY Rate </p>
                              <h3>100%</h3>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-5 col-md-8">
                <div className="stacking__approve">
                  <div className="stacking__approve-field mb-5">
                    <label htmlFor="approve-stack" className="form-label">Balance: <span>{myBalance} {TokenSymbol}</span>
                    </label>
                    <div className="input-group">
                      <input type="text" className="form-control" value={stake} onChange={(e) => onChangeHandler(e.target.value)} placeholder={0.00} />
                      <span className="input-group-text" onClick={(e) => setMax("deposit")}>Max</span>
{isLoading?(
<button className="input-group-btn" disabled> <i class="fas fa-spinner fa-spin"></i> {approved?"Approve":"Stake"}</button>
):(
<button className="input-group-btn" onClick={(e) =>  approved?approveHandle():stakeHandle()}>{approved?"Approve":"Stake"}</button>
)}





                    </div>
                  </div>
                  <div className="stacking__approve-withdraw">
                    <label htmlFor="withdraw-stack" className="form-label">Staked: <span>{fromWeiToNumber(currentStake)} {TokenSymbol}</span>
                    </label>
                    <div className="input-group">
                      <input type="text" className="form-control" value={myStake} onChange={(e) => setMyStake(e.target.value)} placeholder={0.00} />
                      <span className="input-group-text" onClick={(e) => setMax("withdraw")}>Max</span>


{isLoading2?(
<button className="input-group-btn withdraw-btn" disabled><i class="fas fa-spinner fa-spin"></i> {lockTime * 1000 > Date.now()? "Withdraw (penalty)":"Withdraw"}</button>
):(
<button className="input-group-btn withdraw-btn" disabled={currentStake> 0? false:true} onClick={(e) => unStakeHandle()}>{lockTime * 1000 > Date.now()? "Withdraw (penalty)":"Withdraw"}</button>
)}





                    </div>
                  </div>

                  <div className="farming__claim">
                        <div className="farming__claim-title">
                          <h6>Pending Rewards</h6>
                          <h4>{Yield>0?formatFixedNumber(Number(fromWeiToNumber(Yield))):Yield} {TokenSymbol}</h4>
                        </div>
                        <div className="text-end">


{isLoading3?(
<button className="default-btn default-btn--small" disabled><i class="fas fa-spinner fa-spin"></i> Claim</button>
):(
<button disabled={fromWeiToNumber(Yield)>Minyield?false:true} className="default-btn default-btn--small" onClick={(e) => claimHandle()}>Claim</button>
)}


</div>
                      </div>


                </div>





               
                     
             










              </div>
            </div>
            <p className="note-text"><strong>Note:</strong>By staking, I agree that the tokens will be locked until the timeframe displayed expires, unless I pay the early withdrawal fee to exit my stake sooner. </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</Fragment>
)
}

export default Staking


//https://github.com/andrew-fleming/pmkn-farm/blob/main/contracts/PmknFarm.sol


//https://igo.gamestarter.com/staking2

//https://bscscan.com/address/0xf7c34f2cdbd3093883292d6b2cbca2b4b686336d#code<staking upgrade