import React,{Fragment,useState,useEffect} from 'react';
import { useWeb3React, useTokenContract,useContract } from '../../hooks/useContractHelper'
import { increaseGasLimit,customError,getRPCErrorMessage } from '../../utils/useWeb3ReactManager'
import { addTokenToMetamask } from '../../utils/addTokenToMetamask';
import { Link } from 'react-router-dom'
import {toast } from 'react-toastify';
import logoImg from '../../assets/images/logo/soon.png';
import bannerImg from '../../assets/images/igo/item/01.jpg';
import web3 from "../../utils/web3";
import linearVestingAbi from "../../Abi/linearVesting.json";
import regularVestingAbi from "../../Abi/regularVesting.json";
import ReactTooltip from "react-tooltip";
import {getClaimData,fromWeiToNumber,fromEthToWei,numFormatter,getContractAddress,getNetworkName,getLocalTime,getLocalTimeFull,calculateNextClaimTime} from '../../utils/contractHelpers';


function ProjectCard({item}) {
  const {library, chainId,account,active } = useWeb3React();
  const [data, setData] = useState(null);
  const [NextClaimTime, setNextClaimTime] = useState(null);

  let [isLoading,setIsLoading] = useState(false);
  const [claimAddress, setClaimAddress] = useState(item?.vestingDetails?.claimAddress);
  const claimContract = useContract(item?.vestingDetails?.claimAddress,item?.vestingDetails?.type === 'linear' ? linearVestingAbi : regularVestingAbi);
useEffect(() => {
if(item && item.vestingDetails && account){
getClaimInfo(item,account);
}
}, [item,account])

const getClaimInfo = async (item,address) => {
if(!item.vestingDetails.claimAddress) return;
try {

var result=await getClaimData(item,address);
console.log(result);
var resultArray=result[0];
var resultJson ={}
if(item?.vestingDetails?.type === 'linear'){
  resultJson = {
    "amount": Number(fromWeiToNumber(resultArray[0])),
    "claimed": Number(fromWeiToNumber(resultArray[1])),
    "startTime": Number(resultArray[2]),
    "vestingPeriod": Number(resultArray[3]),
    "cliff": Number(resultArray[4]),
    "initialRelease": Number(fromWeiToNumber(resultArray[5])),
    "claimableAmount": Number(fromWeiToNumber(result[1])),
  };
  var newtime=calculateNextClaimTime(resultJson);
  resultJson.startTime=newtime;
}else{
  resultJson = {
    "amount": Number(fromWeiToNumber(resultArray[0])),
    "claimed": Number(fromWeiToNumber(resultArray[1])),
    "lastClaimed": Number(resultArray[2]),
    "nextUnlockDate": Number(resultArray[3]),
    "claimableAmount": Number(fromWeiToNumber(resultArray[4])),
    "startTime": Number(resultArray[5]),
  };
  resultJson.startTime=Number(resultArray[3]);
}
//v.amount, v.claimed, v.lastClaimed, nextUnlockDate, nextClaimableAmount, startTime



setData(resultJson);
} catch (error) {
console.log(error,'errorerror');
}
} 

const claimHandle = async () =>{
  if(!active) return toast.error("Connect Your Wallet!", {position: toast.POSITION.TOP_RIGHT});
setIsLoading(true);
var amountInWei=fromEthToWei(data?.claimableAmount.toString());
try {
 const gasEstimated = await claimContract.estimateGas.claim();
 var tx=  await claimContract.claim({gasLimit: increaseGasLimit(gasEstimated)})
 let result = await tx.wait()
 if(result && result.status){
toast.success("Claimed Success!", {position: toast.POSITION.TOP_RIGHT});
await getClaimInfo(claimAddress,account);
 }
setIsLoading(false);
  } catch (error) {
setIsLoading(false);
toast.error(customError(error).replace("execution reverted:",""), {position: toast.POSITION.TOP_RIGHT});
 }

}


const handleAddToken = () => {
  if(active){
    const ethereum = window.ethereum;
    addTokenToMetamask(ethereum, item?.tokenAddress, item?.tokenSymbol,item?.tokenDecimals);
  }
};


return (
<Fragment>

<div className="IDO-row-left">
<div className="logo-outer">
<img src={item?.logoUrl} alt={item?.tokenName} />
</div>
<p>{item.tokenName}</p>
</div>
<div className="IDO-row-right">
<div className="Info-title">
<h5>Your allocation</h5>
<div className="Info-block">
<p>{data?.amount}</p>
</div>
</div>
<div className="Info-title">

<h5>Claim start date</h5>

<div className="Info-block">
{data?.startTime && (
  <p>{getLocalTime(data.startTime)}</p>
)}
</div>
</div>

{account &&
<>

  {getNetworkName(chainId)===item.blockchain && data?.claimableAmount >0?(
  <>
  {isLoading?(
  <button  className="default-btn default-btn--small" disabled> <i className="fas fa-spinner fa-spin"></i>  Claim ({data?.claimableAmount})</button>
  ):(
  <button  disabled={!claimAddress || !account} className="default-btn default-btn--small"  onClick={(e) =>  claimHandle()}>Claim ({data?.claimableAmount})</button>
  )}
  </>
  ):(
  <button className="un-btn">Unavailable</button>
  )}

  
  

</>
}











{item.metamask && active &&
<div className="info-part" data-tip="Add this token to your metamask." currentitem="false" onClick={(e) =>  handleAddToken()}>
<svg stroke="currentColor" fill="currentColor" strokeWidth={0} viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M3 16c0 1.103.897 2 2 2h3.586L12 21.414 15.414 18H19c1.103 0 2-.897 2-2V4c0-1.103-.897-2-2-2H5c-1.103 0-2 .897-2 2v12zM5 4h14v12h-4.414L12 18.586 9.414 16H5V4z" /><path d="M11 14h2v-3h3V9h-3V6h-2v3H8v2h3z" /></svg>
<ReactTooltip place="top" type="dark" effect="float"/>
</div>
}







</div>
  </Fragment>
)
}

export default ProjectCard
