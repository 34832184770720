import React,{Fragment} from 'react';



import arrowIcon from '../../assets/images/roadmap/arrow-icon.png';
import {TokenName} from '../../config';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';


/*
The Team focuses on Delivering and not just making promises. Hence the
Launchpad is live on Binance Smart Chain even before the Public Sale
Round.
*/


const Roadmap = () => {

return (
<Fragment>
  <section className="roadmap padding-bottom" id="roadmap">
  <div className="container">
    <div className="section-header section-header--middle">
      <div className="section-header__content">
        <div className="section-header__titlebar">
          <p className="section-header__subtitle"> Roadmap</p>
          <h2 className="section__header__title">How All Started</h2>
        </div>
      </div>
    </div>
    <div className="roadmap__wrapper">
      <div className="swiper roadmap__slider">
      <Swiper

className="swiper-wrapper"

      modules={[Navigation]}
      spaceBetween={20}
      slidesPerView={1}
      speed={1200}
      loop={true}


   autoplay={{delay:3500,disableOnInteraction:false,}}
   navigation={{nextEl:".roadmap__slider-next",prevEl:".roadmap__slider-prev",}}
      breakpoints={{
        576: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        992: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
        1200: {
          slidesPerView: 4,
          spaceBetween: 24,
        },
        
      }}
      
    >
          <SwiperSlide className="swiper-slide">
            <div className="roadmap__item" data-aos="fade-up" data-aos-duration={1000}>
              <div className="roadmap__item-inner roadmap__item-inner--vertical-line">
                <div className="roadmap__item-year">
                  <h6>Phase 1</h6>
                  <img src={arrowIcon} alt="Arrow Icon" />
                </div>
                <div className="roadmap__item-content">
                  <div className="roadmap__item-icon">1</div>
                  <div className="roadmap__item-text">
                    <p>
&nbsp;- &nbsp;&nbsp;Conceptualization<br/>
&nbsp;- &nbsp;&nbsp;Team Recruitment<br/>
&nbsp;- &nbsp;&nbsp;Website Launch<br/>
&nbsp;- &nbsp;&nbsp;White paper Release<br/>
&nbsp;- &nbsp;&nbsp;Staking & LP Farming Mechanism<br/>
&nbsp;- &nbsp;&nbsp;Smart Contract Development<br/>
&nbsp;- &nbsp;&nbsp;Airdrop Program
</p>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className="swiper-slide">
            <div className="roadmap__item" data-aos="fade-up" data-aos-duration={1500}>
              <div className="roadmap__item-inner roadmap__item-inner--vertical-line">
                <div className="roadmap__item-year">
                  <h6>Phase 2</h6>
                  <img src={arrowIcon} alt="Arrow Icon" />
                </div>
                <div className="roadmap__item-content">
                  <div className="roadmap__item-icon">2</div>
                  <div className="roadmap__item-text">
                    <p>
                    &nbsp;- &nbsp;&nbsp;Strategic Partnership<br/>
                    &nbsp;- &nbsp;&nbsp;Expanding {TokenName} Community<br/>
                    &nbsp;- &nbsp;&nbsp;First Smart Contract Audit<br/>
                    &nbsp;- &nbsp;&nbsp;Marketing<br/>
                    &nbsp;- &nbsp;&nbsp;Private sale round<br/>
                    &nbsp;- &nbsp;&nbsp;Community sale round<br/>
                    &nbsp;- &nbsp;&nbsp;PancakeSwap Listing
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className="swiper-slide">
            <div className="roadmap__item" data-aos="fade-up" data-aos-duration={2000}>
              <div className="roadmap__item-inner roadmap__item-inner--vertical-line">
                <div className="roadmap__item-year">
                  <h6>Phase 3</h6>
                  <img src={arrowIcon} alt="Arrow Icon" />
                </div>
                <div className="roadmap__item-content">
                  <div className="roadmap__item-icon">3</div>
                  <div className="roadmap__item-text">
                  <p>
                    &nbsp;- &nbsp;&nbsp;Tiers Development<br/>
                    &nbsp;- &nbsp;&nbsp;Upcoming IDO Projects Listings<br/>
                    &nbsp;- &nbsp;&nbsp;Full KYC Integration<br/>
                    &nbsp;- &nbsp;&nbsp;CoinMarketCap Listing<br/>
                    &nbsp;- &nbsp;&nbsp;CoinGecko Listing<br/>
                    &nbsp;- &nbsp;&nbsp;Second Smart Contract Audit<br/>
                    &nbsp;- &nbsp;&nbsp;Integration of POLYGON
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className="swiper-slide">
            <div className="roadmap__item" data-aos="fade-up" data-aos-duration={2500}>
              <div className="roadmap__item-inner roadmap__item-inner--vertical-line">
                <div className="roadmap__item-year">
                  <h6>Phase 4</h6>
                  <img src={arrowIcon} alt="Arrow Icon" />
                </div>
                <div className="roadmap__item-content">
                  <div className="roadmap__item-icon">4</div>
                  <div className="roadmap__item-text">
                  <p>
                    &nbsp;- &nbsp;&nbsp;First Cex Listing<br/>
                    &nbsp;- &nbsp;&nbsp;Allows anyone to create a token<br/>
                    &nbsp;- &nbsp;&nbsp;Liquidity Locker<br/>
                    &nbsp;- &nbsp;&nbsp;TorkSwap<br/>
                    &nbsp;- &nbsp;&nbsp;{TokenName} 2.0<br/>
                    &nbsp;- &nbsp;&nbsp;Website Upgrade<br/>
                    &nbsp;- &nbsp;&nbsp;To Be Continued
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
      <div className="roadmap__slider-prev"><i className="fas fa-angle-left" /></div>
      <div className="roadmap__slider-next"><i className="fas fa-angle-right" /></div>
    </div>
  </div>
</section>






</Fragment>
)
}

export default Roadmap
