import React,{Fragment,useState,useMemo,useEffect} from 'react';
import "./claim.css";
import ProjectClaimCard from '../components/Card/ProjectClaimCard';
import {getProjectClaimList,networkIcon} from '../utils/contractHelpers';
import {ethNetwork,bscNetwork,polygonNetwork,aptosNetwork,solNetwork,arbitrumNetwork,avalNetwork,cronosNetwork,fantomNetwork,algorandNetwork,nearNetwork,atomNetwork,stellarNetwork,VESTINGTYPE} from '../config';
import ReactTooltip from "react-tooltip";
import Pagination from "../utils/Pagination";
let PageSize = 5;

const Claims = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [activeNetwork, setActiveNetwork] = useState(bscNetwork);
  const [loading, setLoading] = useState(true);
  const [selection, setSelection] = useState(VESTINGTYPE[1]);
  const [searchQuery, setSearchQuery] = useState('');



  useEffect(() => {
    const fetchData = async () => {
      const result = await getProjectClaimList();
      setData(result.reverse());
      setLoading(false);
    };
    fetchData();
  }, []);


  useEffect(() => {
    if (activeNetwork && data.length > 0) {
      setFilteredData(data.filter(item => item.blockchain === activeNetwork && item.vestingDetails.type === selection));
      setCurrentPage(1);
    }
  }, [activeNetwork, data, selection]);

  const handleNetworkClick = (network) => {
    setActiveNetwork(network === activeNetwork ? activeNetwork : network);
    setCurrentPage(1);
  };

  const handleSelectionChange = (selectedType) => {
    setSelection(selectedType);
    setCurrentPage(1);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1);
  };


/*
  const currentDataList = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return filteredData?.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, filteredData]);
*/
const currentDataList = useMemo(() => {
  const filteredBySearch = filteredData.filter(item =>
    item.tokenName.toLowerCase().includes(searchQuery.toLowerCase())
  );
  const firstPageIndex = (currentPage - 1) * PageSize;
  const lastPageIndex = firstPageIndex + PageSize;
  return filteredBySearch.slice(firstPageIndex, lastPageIndex);
}, [currentPage, filteredData, searchQuery]);


return (
<Fragment>
<div className="claim-banner">
<div className="container_cust">
<div className="row_cust">
<div className="select-network">
<p>Select Network</p>
<div className="network-list">
<button data-tip="BSC" currentitem="false" onClick={() => handleNetworkClick(bscNetwork)} className={activeNetwork === bscNetwork ? 'active' : ''}>
<img src={networkIcon(bscNetwork)} alt={bscNetwork} />
</button>
<button data-tip="Polygon" currentitem="false"  onClick={() => handleNetworkClick(polygonNetwork)} className={activeNetwork === polygonNetwork ? 'active' : ''}>
<img src={networkIcon(polygonNetwork)} alt={polygonNetwork} />
</button>
<button data-tip="Avalanche" currentitem="false" onClick={() => handleNetworkClick(avalNetwork)} className={activeNetwork === avalNetwork ? 'active' : ''}>
<img src={networkIcon(avalNetwork)} alt={avalNetwork} />
</button>
<button data-tip="Ethereum" currentitem="false" onClick={() => handleNetworkClick(ethNetwork)} className={activeNetwork === ethNetwork ? 'active' : ''}>
<img src={networkIcon(ethNetwork)} alt={ethNetwork} />
</button>
<button data-tip="Fantom" currentitem="false" onClick={() => handleNetworkClick(fantomNetwork)} className={activeNetwork === fantomNetwork ? 'active' : ''}>
<img src={networkIcon(fantomNetwork)} alt={fantomNetwork} />
</button>
<button data-tip="Solana " currentitem="false" onClick={() => handleNetworkClick(solNetwork)} className={activeNetwork === solNetwork ? 'active' : ''}>
<img src={networkIcon(solNetwork)} alt={solNetwork} />
</button>
</div>
</div>

<ReactTooltip place="top" type="dark" effect="float"/>
<div className="top-right">
<div className="search-block">
<div className="search-outer">
<div className="search-inner">
<i class="fa fa-search" ></i>
<input
type="text"
placeholder="Type to Search"
value={searchQuery}
onChange={handleSearchChange}
/>
<b />
</div>
</div>
</div>
</div>

<div className="Choose-block">
<button className={selection === VESTINGTYPE[0] ? 'active' : ''} onClick={() => handleSelectionChange(VESTINGTYPE[0])}>Monthly</button>
<button className={selection === VESTINGTYPE[1] ? 'active' : ''} onClick={() => handleSelectionChange(VESTINGTYPE[1])}>Linear</button>
</div>
<div className="title">
<h1>Claim your IGO/IDO tokens</h1>
</div>

{currentDataList.length === 0 ? (
<div className="text-center">
<b>No data available</b>
</div>
) : (
currentDataList.map((item, index) => (
<div key={index} className="IDO-row">
<ProjectClaimCard item={item} />
</div>
))
)}

<div className="custom-accordion">
<div>
<div className="mobile-block" style={{position: 'relative', overflow: 'hidden', width: '100%', height: '214px'}}>
<div style={{position: 'absolute', inset: '0px', overflow: 'scroll', marginRight: '0px', marginBottom: '0px'}} className="view">
<div className="table-responsive">
<table className="info-table" cellSpacing={0} cellPadding={0}>
<thead>
<tr>
<th>Vesting No.</th>
<th>Vesting %</th>
<th>Amount</th>
<th>Start Date</th>
<th />
</tr>
</thead>
<tbody />
</table>
</div>
</div>
<div style={{position: 'absolute', height: '6px', transition: 'opacity 200ms ease 0s', opacity: 0, display: 'none'}} className="track-horizontal">
<div style={{position: 'relative', display: 'block', height: '100%'}} className="thumb-horizontal" />
</div>
<div style={{position: 'absolute', width: '6px', transition: 'opacity 200ms ease 0s', opacity: 0, display: 'none'}} className="track-vertical">
<div style={{position: 'relative', display: 'block', width: '100%'}} className="thumb-vertical" />
</div>
</div>
</div>
</div>




</div>
<b />
<b />


<div className="text-center mt-5 aos-init" data-aos="fade-up" data-aos-duration={800}>
        <Pagination
                        currentPage={currentPage}
                        totalCount={filteredData?.length}
                        pageSize={PageSize}
                        onPageChange={page => setCurrentPage(page)}
                    />
        </div>
        
            </div>
            <div />
            <div />
            </div>

      </Fragment>
      )
}
export default Claims