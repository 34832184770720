import { ethers } from 'ethers'
import UncheckedJsonRpcSigner from './signer'
import ERC20_ABI from '../constants/abis/erc20'
import { getParsedEthersError } from "@enzoferey/ethers-error-parser";
import {toast } from 'react-toastify';

export function isAddress(value) {
  try {
    return ethers.utils.getAddress(value.toLowerCase())
  } catch {
    return false
  }
}
export function calculateGasMargin(value, margin) {
  const offset = value.mul(margin).div(ethers.utils.bigNumberify(10000))
  return value.add(offset)
}

export function increaseGasLimit (estimatedGasLimit) {
  return estimatedGasLimit.mul(130).div(100) // increase by 30%
}

// account is optional
export function getProviderOrSigner(library, account) {
  return account ? new UncheckedJsonRpcSigner(library.getSigner(account)) : library
}

// account is optional
export function getContract(address, ABI, library, account) {
  if (!isAddress(address) || address === ethers.constants.AddressZero) {
    throw Error(`Invalid 'address' parameter '${address}'.`)
  }

  return new ethers.Contract(address, ABI, getProviderOrSigner(library, account))
}

// get the token allowance
export async function getTokenAllowance(address, tokenAddress, spenderAddress, library) {
  if (!isAddress(address) || !isAddress(tokenAddress) || !isAddress(spenderAddress)) {
    throw Error(
      "Invalid 'address' or 'tokenAddress' or 'spenderAddress' parameter" +
        `'${address}' or '${tokenAddress}' or '${spenderAddress}'.`
    )
  }

  return getContract(tokenAddress, ERC20_ABI, library).allowance(address, spenderAddress)
}


export function customError(error) {
const parsedEthersError = getParsedEthersError(error);
if(parsedEthersError && parsedEthersError.errorCode && parsedEthersError.errorCode==="EXECUTION_REVERTED"){
return parsedEthersError.context;
}else if(parsedEthersError.errorCode==="REJECTED_TRANSACTION"){
return "user rejected transaction";
}else{
return parsedEthersError.context;
}
}