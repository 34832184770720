import React,{Fragment,useState,useMemo,useEffect} from 'react';
import { Link } from 'react-router-dom'
import logoImg from '../../assets/images/logo/soon.png';

import {isWhatPercentOf,daysGone,getProjectCompletedList} from '../../utils/contractHelpers';
import ProjectVerticalCompletedCard from '../Card/ProjectVerticalCompletedCard';
import Pagination from "../../utils/Pagination";

let PageSize = 5;



const ProjectCompleted = () => {
  const [data, setData] = useState([]);


  const [currentPage, setCurrentPage] = useState(1);
  //const [users, setUsers] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
   const getProject = async () => {
     const result = await getProjectCompletedList();
     setData(result.reverse());
   };
   getProject();
 }, []);


 const currentDataList = useMemo(() => {
  const firstPageIndex = (currentPage - 1) * PageSize;
  const lastPageIndex = firstPageIndex + PageSize;
  return data?.slice(firstPageIndex, lastPageIndex);
}, [currentPage, data]);





return (
<Fragment>




    <section className="project padding-bottom project--completed2">
    <div className="container">
      <div className="section-header section-header--middle">
        <div className="section-header__content">
          <div className="section-header__titlebar">
            <p className="section-header__subtitle"> Previous</p>
            <h2 className="section__header__title">Completed Project</h2>
          </div>
        </div>
      </div>
      <div className="project__wrapper">
        <div className="row g-4">
        {currentDataList.map((item, index) => (

          <div key={index} className="col-12">
<ProjectVerticalCompletedCard item={item}/>
          </div>
))} 

        </div>










        <div className="text-center mt-5 aos-init" data-aos="fade-up" data-aos-duration={800}>
        <Pagination
                        currentPage={currentPage}
                        totalCount={data?.length}
                        pageSize={PageSize}
                        onPageChange={page => setCurrentPage(page)}
                    />
        </div>
      </div>
    </div>
  </section>
</Fragment>
)
}

export default ProjectCompleted
