import React,{Fragment,useState,useEffect,} from 'react';
import { Link } from 'react-router-dom'
import logoImg from '../../assets/images/logo/soon.png';
import bannerImg from '../../assets/images/igo/item/01.jpg';
import web3 from "../../utils/web3";

import { createWatcher } from '@makerdao/multicall';

import {isWhatPercentOf,getPoolData,fromWeiToNumber,numFormatter,getContractAddress,networkIcon,calculateDiff,calculateTokens} from '../../utils/contractHelpers';
import {MyTimer} from '../Widget/MyTimer';


import "./ProjectBoxCard.css"


function ProjectBoxCard({item}) {
const [isTrigger, setIsTrigger] = useState(false);
const [cap, setCap] = useState(item?.cap);
const [hardCap, setHardCap] = useState(item?.hardCap.replace(",",""));

useEffect(() => {
if(item && item.saleAddress){
getPoolInfo(item.saleAddress);
}
}, [item])


useEffect(() => {
  if (!web3 || !item || !item.saleAddress) return;
  const multiCallConfig = {
    web3,
    multicallAddress: getContractAddress("multicall"),
    interval: 10000,
  };

  const defaultWatcher = createWatcher([], {});

  defaultWatcher.recreate(
    [
      {
        target: item.saleAddress,
        call: ["totalRaise()(uint256)"],
        returns: [["totalRaise", (val) => val.toString()]],
      },
    ],
    multiCallConfig
  );

  defaultWatcher.subscribe((update) => {
    const totalRaise = update.value;
    if (totalRaise !== "0") {
      setCap(fromWeiToNumber(totalRaise));
    }
  });

  defaultWatcher.start();

  return () => {
    defaultWatcher.stop();
  };
}, [web3, item]);

const getPoolInfo = async (saleAddress) => {
if(!saleAddress) return;
try {
var result=await getPoolData(saleAddress);
setCap(fromWeiToNumber(result[0]));
} catch (error) {
console.log(error,'errorerror');
}
} 



return (
<Fragment>



    <div className="project__item">


    <Link to={'/pool/'+item.params}>
    <div className="project__item-inner">
      <div className="project__item-thumb">
        <img src={item.bannerUrl?item.bannerUrl:bannerImg} alt="IGO cover" />
        <span className="badge">
  
<img src={networkIcon(item.blockchain)} alt="BlockChain" />

          
          
          </span>
      </div>
      <div className="project__item-content">
        <div className="project__item-top">
          <div className="project__item-author">
           <a href={void(0)}><img src={item.logoUrl? item.logoUrl:logoImg} alt="author image" /></a>
            <h4>{item.tokenName}</h4>
           
          </div>
        </div>




        <div className="project__item-middle">

        <div className="social-media-container">
        <ul className="social">

        {item.website &&
  <li className="social__item">
 <a href={item.website} target="_blank" rel="noopener noreferrer" className="social__link"><i className="fa fa-globe" /></a>
  </li>
 }
{item.twitter &&
  <li className="social__item">
 <a href={item.twitter} target="_blank" rel="noopener noreferrer" className="social__link"><i className="fab fa-twitter" /></a>
  </li>
 }{item.discord &&
  <li className="social__item">
 <a href={item.discord} target="_blank" rel="noopener noreferrer" className="social__link"><i className="fab fa-discord" /></a>
  </li>
 }{item.telegram &&
  <li className="social__item">
 <a href={item.telegram} target="_blank" rel="noopener noreferrer" className="social__link"><i className="fab fa-telegram" /></a>
  </li>
  }{item.telegramAnn &&
 <li className="social__item">
<a href={item.telegramAnn} target="_blank" rel="noopener noreferrer" className="social__link"><i className="fab fa-telegram" /></a>
 </li>
 }{item.instagram &&
  <li className="social__item">
 <a href={item.instagram} target="_blank" rel="noopener noreferrer" className="social__link"><i className="fab fa-instagram" /></a>
  </li>
  }{item.linkedin &&
  <li className="social__item">
 <a href={item.linkedin} target="_blank" rel="noopener noreferrer" className="social__link"><i className="fab fa-linkedin-in" /></a>
  </li>
  }{item.facebook &&
  <li className="social__item">
 <a href={item.facebook} target="_blank" rel="noopener noreferrer" className="social__link"><i className="fab fa-facebook-f" /></a>
  </li>
  }
  {item.medium &&
  <li className="social__item">
 <a href={item.medium} target="_blank" rel="noopener noreferrer" className="social__link"><i className="fab fa-medium-m" /></a>
  </li>
 }
{item.reddit &&
  <li className="social__item">
 <a href={item.reddit} target="_blank" rel="noopener noreferrer" className="social__link"><i className="fab fa-reddit" /></a>
  </li>
 }

</ul>
    <div className="project__item-right">
    <span className="badge">{item.saleType}</span>
    </div>
  </div>
  <div className="project__item-description">
    <p>{item.description}</p>
  </div>



  <ul className="project-infolist">


  <li className="project-infolist-item">
    <p className="project-infolist-name">Hard Cap</p>
    <p className="project-infolist-data">{item.hardCap} {item.currency}</p>
  </li>
  <li className="project-infolist-item">
    <p className="project-infolist-name">Swap Rate</p>
    <p className="project-infolist-data">1 {item.tokenSymbol} = {item.idoPrice} {item.currency}</p>
  </li>
  <li className="project-infolist-item">
    <p className="project-infolist-name">Deposit</p>
    <p className="project-infolist-data">{item.currency}</p>
  </li>

<li className="project-infolist-item">
<p className="project-infolist-name">Sale Starts In</p>
<p className="project-infolist-data">
{item.openTime==="TBA"?item.openTime:calculateDiff(item.openTime,item.closeTime,item.status)} 
</p>
</li>

<li className="project-infolist-item">
<p className="project-infolist-name">
Tokens
</p>
<p className="project-infolist-data">
{calculateTokens(hardCap,item.idoPrice)} {item.tokenSymbol}
</p>
</li>

</ul>
{/*
<div className="project__item-amount">
<div className="progress">
<div className="progress-bar" role="progressbar" style={{width: isWhatPercentOf(cap,hardCap)+'%'}} aria-valuenow={isWhatPercentOf(cap,hardCap)} aria-valuemin={0} aria-valuemax={100} />
</div>
</div>
*/}

</div>
</div>
    </div>
    </Link>


  </div>

  </Fragment>
)
}

export default ProjectBoxCard
