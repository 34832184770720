import React from 'react';

const NetworkSwitchModal = ({ isOpen, onClose, onSwitchNetwork }) => {
  const handleNetworkSwitch = (chainId) => {
    onSwitchNetwork(chainId);
    onClose();
  };

  return (
    <div className={`modal ${isOpen ? 'is-active' : ''}`}>
      <div className="modal-background" onClick={onClose}></div>
      <div className="modal-content">
        <h2>Switch Network</h2>
        <button onClick={() => handleNetworkSwitch(1)}>Ethereum Mainnet</button>
        <button onClick={() => handleNetworkSwitch(56)}>BSC Mainnet</button>
        {/* Add buttons for other networks */}
      </div>
      <button className="modal-close is-large" onClick={onClose} aria-label="close"></button>
    </div>
  );
};

export default NetworkSwitchModal;
