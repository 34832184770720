import React,{Fragment, useState} from 'react';
import { Link } from 'react-router-dom';
import {IgoForm} from '../../config';
function Cta() {
return (
<Fragment>
<section className="cta  padding-bottom" data-aos="fade-up" data-aos-duration={1000}>
    <div className="container">
      <div className="cta__wrapper">
        <div className="cta__content">
          <p className="section-header__subtitle"> Have any Project?</p>
          <h2 className="section__header__title"> Apply For IGO/IDO</h2><a href={IgoForm} className="default-btn"><span>Apply to launch</span></a>
        </div>
      </div>
    </div>
  </section>

</Fragment>
)
}

export default Cta
