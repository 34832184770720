import {SafeGuardUrl,HowToParticipate,bscNetwork,GUARANTEED,FCFS,LEVELS,IDORULES,SecuredIDOUrl,AutonomyIDOUrl, PRIVATE} from '../../config';
import React,{Fragment, useState, useEffect} from 'react';

import bscscan from '../../assets/images/footer/bsc.svg';
import coingecko from '../../assets/images/footer/coingecko.svg';
import coinmarketcapImg from '../../assets/images/footer/coinmarketcap.svg';
import metamaskImg from '../../assets/images/explorer/metamask.svg';



//Your Allocation: 0x = $0

//FCFS opens in 19 hours, 25 minutes

import CountUp from 'react-countup';
import {toast } from 'react-toastify';
import Parser from 'html-react-parser';
import { Link,useHistory } from 'react-router-dom'
import axios from "axios";
import { ethers } from 'ethers'
import { isMobile } from 'react-device-detect'
import { useWeb3React, useTokenContract,useContract } from '../../hooks/useContractHelper'
import { increaseGasLimit,customError,getRPCErrorMessage } from '../../utils/useWeb3ReactManager'
import saleAbi from "../../Abi/Sale.json";
import walletContract from '../../utils/walletContract';
import firebaseService from '../../service/FirebaseService';
import web3 from "../../utils/web3";
import { createWatcher } from '@makerdao/multicall';

import "./Details.css";
import WhitelistCards from "./Cards/Whitelist";
import VestingTableCards from "./Cards/VestingTable";
import BuyChartCards from "./Cards/BuyChart";
import WalletCards from "./Cards/WalletComponent";
import { addTokenToMetamask } from '../../utils/addTokenToMetamask';
import {isWhatPercentOf,getMarketData,percentage,saveDataToFile,calculateTokens,getLocalTime,getLocalTimeFull,getBlockchainLink,daysGone,getPoolData,getTotalRaise,getMyContribution,getTotalContributors,fromEthToWei,fromWeiToNumber,formatFixedNumber,allowanceSale, getContractAddress,saleClaim,numFormatter,numFormatter2,networkIcon} from '../../utils/contractHelpers';

import {MyTimer} from '../Widget/MyTimer';
import moment from 'moment';
import Badges from './Cards/Badges';
import PercentageChange  from './Cards/PercentageChange';
const { REACT_APP_CHAIN_ID,REACT_APP_KYCAID_KEY,REACT_APP_KYC_URL } = process.env
const defaultWatcher = createWatcher([], {});

const Details = ({data}) => {
const {library, chainId,account,active } = useWeb3React();
const tokenContract = useTokenContract(getContractAddress(data?.currency));
const saleContract = useContract(data?.saleAddress,saleAbi);
const [isTrigger, setIsTrigger] = useState(false);
const [amount, setAmount] = useState(0);
const [approved, setApproved] = useState(true);
const [claimToken, setClaimToken] = useState(data?.claimToken);
const [saleAddress, setSaleAddress] = useState(data?.saleAddress);
const [blockchainNetwork, setBlockchainNetwork] = useState(data?.blockchain);
const [maxAmount, setMaxAmount] = useState(0);
const [minAmount, setMinAmount] = useState(0);
const [cap, setCap] = useState(data?.cap);
const [hardCap, setHardCap] = useState(data?.hardCap.replace(",",""));
const [chain, setChain] = useState(Number(REACT_APP_CHAIN_ID));
let [isLoading,setIsLoading] = useState(false);
const [edit, setEdit] = useState(false);
const [isValidAddress, setIsValidAddress] = useState(true);
const [isWhitelistAddress, setIsWhitelistAddress] = useState(false);
const [walletAddress, setWalletAddress] = useState('');
const [contributeAmount, setMyContributionAmount] = useState(0);
const [ath, setATH] = useState(0);
const [currentPrice, setCurrentPrice] = useState(0);




const history = useHistory();

//const firebaseService = new FirebaseService();
//Registration will open soon
//The sale will open soon
//Registration closed





useEffect(() => {

if(data){
setMaxAmount(data.maxAllocation.replace(data.currency,""))
setMinAmount(data.minAllocation.replace(data.currency,""))
if(data && data.cap <1){
getPoolInfo(data.saleAddress);
}

if(data && data.coingecko){
getTokenPrice(data.coingecko);
}

//getContributorsFiles(data.saleAddress,20,100);//sale contract,indexLast,Tge Percentage

if(data?.walletRegister && account){
getMyWalletInfo(data?.blockchain,account);
}

if(data?.saleAddress && account){
getMyTotalContribution(data.saleAddress,account);
}




}
}, [data,account])
  
  useEffect(() => {
if(data && !data.saleAddress) return;
const multiCallConfig = {
web3,
multicallAddress: getContractAddress("multicall"),
interval: 10000
 };
 defaultWatcher.stop();

 defaultWatcher.recreate(
[
  {
 target: data.saleAddress,
 call: ['totalRaise()(uint256)'],
 returns: [['totalRaise', (val) => val.toString()]]
  }
],
multiCallConfig
 );
defaultWatcher.subscribe((update) => {
if (data.cap >0) return;
setCap(fromWeiToNumber(update.value));
});
 defaultWatcher.start();
  }, [web3]);

const getPoolInfo = async (saleAddress) => {
  if(!saleAddress) return;
try {
var result=await getTotalRaise(saleAddress);
setCap(fromWeiToNumber(result[0]));
//setIsCurrentDateTime(result[1]);
//console.log(result[2][0]);
//console.log(result[2][1]);
} catch (error) {
console.log(error,'err');
}
}





const getTokenPrice = async (coingeckoID) => {
  if(!coingeckoID) return;
try {
var result=await getMarketData(coingeckoID);
setATH(result.ath);
setCurrentPrice(result.current_price.toFixed(4))
} catch (error) {
console.log(error,'err');
}
}


const getContributorsFiles = async (saleAddress,indexLast,tgePercentage) => {
try {
let investors=[];
var result=await getTotalContributors(saleAddress,indexLast);
result[0].forEach(async (address, index) => {
//console.log(address)
var walletadd= await getMyWalletAddress(data?.blockchain,address);
const amount = fromWeiToNumber(result[1][index]);
//var investorsTokens=calculateTokens(amount,data?.idoPrice);

//var tokenamount=percentage(investorsTokens,tgePercentage);

//investors.push(address+','+fromEthToWei(String(tokenamount)));//tge percentage
//investors.push(address+','+percentage(investorsTokens,tgePercentage));//tge percentage
//investors.push(address+','+amount);//tge percentage
investors.push(address+','+amount+','+walletadd);//tge percentage
});

console.log(investors);

saveDataToFile(data.tokenName+'.txt', investors);
} catch (error) {
console.log(error,'err');
}
}

 const onChangeHandler = async (amt) => {
setAmount(amt);
if(active && chainId===chain)
if(amt>0){
  var amount=fromEthToWei(amt);
  var res= await allowanceSale(account,amount,data?.saleAddress,data?.currency);
  setApproved(res);
}else{
  setApproved(true);
}
}


const contributeHandle = async () =>{
  if(!active) return toast.error("Connect Your Wallet!", {position: toast.POSITION.TOP_RIGHT});
  if(Number(amount)<Number(minAmount)) return toast.error("Minimum contribution is "+minAmount+" "+data.currency, {position: toast.POSITION.TOP_RIGHT});
  if(Number(amount)>Number(maxAmount)) return toast.error("Maximum contribution is "+maxAmount+" "+data.currency, {position: toast.POSITION.TOP_RIGHT});
  if(active && chainId===chain)
  if(amount>0){
  var amountInWei=fromEthToWei(amount);
  setIsLoading(true);
try {
 const gasEstimated = await saleContract.estimateGas.contribute(amountInWei);
 var tx=  await saleContract.contribute(amountInWei,{gasLimit: increaseGasLimit(gasEstimated)})
 let result = await tx.wait()
 if(result && result.status){
toast.success("Contributed Success!", {position: toast.POSITION.TOP_RIGHT});
await getPoolInfo(saleAddress);
await getMyTotalContribution(saleAddress,account);
setApproved(false);
 }
setIsLoading(false);
  } catch (error) {
setIsLoading(false);
toast.error(customError(error).replace("execution reverted:",""), {position: toast.POSITION.TOP_RIGHT});
 }
}else{
return toast.error("Please enter amount!", {position: toast.POSITION.TOP_RIGHT});
}
}

const claimHandle = async () =>{
if(active && chainId===chain)
try {
var result=await saleClaim(library,account,data?.saleAddress);
if(result && result.status){
toast.success("Claimed Success!", {position: toast.POSITION.TOP_RIGHT});
}
} catch (error) {
 var err= (
 error.response && error.response.data.message
 ? error.response.data.message
 : error.message);
 if (error && error.message) {
 let isExist = error.message.includes("Internal JSON-RPC error.");
 if(isExist){
 let errorTxt=error.message.replace("Internal JSON-RPC error.", "");
 let obj=JSON.parse(errorTxt);
 return toast.error(obj.message.replace("execution reverted:",""), {position: toast.POSITION.TOP_RIGHT});
 }
 }
 toast.error(err, {position: toast.POSITION.TOP_RIGHT});
 }

  }
  
const approveHandle = async () =>{
if(!active) return toast.error("Connect Your Wallet!", {position: toast.POSITION.TOP_RIGHT});
if(Number(amount)<Number(minAmount)) return toast.error("Minimum contribution is "+minAmount+" "+data.currency, {position: toast.POSITION.TOP_RIGHT});
if(Number(amount)>Number(maxAmount)) return toast.error("Maximum contribution is "+maxAmount+" "+data.currency, {position: toast.POSITION.TOP_RIGHT});
if(active && chainId===chain)
if(amount>0){
var amountInWei=fromEthToWei(amount);
setIsLoading(true);
try {
var tx=await tokenContract.approve(data?.saleAddress, amountInWei)//ethers.constants.MaxUint256
await tx.wait()
setApproved(false);
setIsLoading(false);
} catch (error) {
setIsLoading(false);
toast.error(customError(error).replace("execution reverted:",""), {position: toast.POSITION.TOP_RIGHT});
}
}else{
return toast.error("Please enter amount!", {position: toast.POSITION.TOP_RIGHT});
}
}
  
const switchNetworkHandle = async () =>{
if (window.ethereum.networkVersion !== chainId) {
try {
await window.ethereum.request({
method: 'wallet_switchEthereumChain',
params: [{ chainId: "0x38" }]
});
} catch (err) {
if (err.code === 4902) {
await window.ethereum.request({
method: 'wallet_addEthereumChain',
params: [
{
chainName: 'Binance Smart Chain Mainnet',
chainId: "0x38",
nativeCurrency: {
name: "BINANCE COIN",
symbol: "BNB",
decimals: 18,
},
rpcUrls: ["https://bsc-dataseed1.binance.org"],
blockExplorerUrls: ["https://bscscan.com/"],
iconUrls: [
"https://bin.bnbstatic.com/image/admin_mgs_image_upload/20201110/550eda20-1b9a-4bc7-9a65-e4a329e8bb57.png"
]
}
]
});
}
}
}
}

const verifyKYC = async ()=>{
//  getDataByAddress();
  try {
let xreg= await firebaseService.getDataByAddress('kycData', account);

//console.log(xreg);


if(xreg){
//console.log(xreg.data.verification_id,'found');
//var vx='5b02fa9504aa6545701802f62120837f2ebe';
let response= await axios.get("https://api.kycaid.com/verifications/"+xreg.data.verification_id,{
    headers: {
        'Authorization': 'Token '+REACT_APP_KYCAID_KEY,
        'content-type': 'application/json'
    }})
// console.log(response.data);

if(response.data.status=="unused"){


window.location.href = xreg.data.form_uri;

}else if(response.data.status=="pending") {

}else if(response.data.status=="completed") {
//verified

}








}else{

//console.log('new submit');



/*
  let response= await axios.post("https://api.kycaid.com/applicants",{
    "type": "PERSON",
  },{
      headers: {
          'Authorization': 'Token '+REACT_APP_KYCAID_KEY,
          'content-type': 'application/json'
      }})
    console.log(response.data);
  
  
    let response2= await axios.post(REACT_APP_KYC_URL,{
      "applicant_id": response.applicant_id,
      "external_applicant_id": "0xxx",
      "redirect_url": "https://torkpad.io/success-kyc"
      },{
        headers: {
            'Authorization': 'Token '+REACT_APP_KYCAID_KEY,
            'content-type': 'application/json'
        }})
    
        console.log(response2.data,'response2');


 const dataToSubmit = { applicant_id: response.data.applicant_id, verification_id: response2.data.verification_id, form_uri:response2.data.form_url,address:account };
 console.log(dataToSubmit,'dataToSubmit');
 */
 
 // firebaseService.submitData('kycData', dataToSubmit);

}






/*

https://api.kycaid.com/applicants/{applicant_id} \
-H 'Authorization: Token <API_TOKEN>'
-X GET


https://api.kycaid.com/forms/46ab44c505fe654962184fb666264c80cb31/urls
//

{
  "applicant_id": "oxd",
  "external_applicant_id": "{external_applicant_id}",
  "redirect_url": "https://torkpad.io/success-kyc"
  },

curl https://api.kycaid.com/applicants \
    -H 'Authorization: Token <API_TOKEN>' \
    -H 'Content-Type: application/json' \
    -X POST \
    -d '{
        "type": "PERSON",
        "first_name": "John",
        "last_name": "Doe",
        "dob": "1970-10-25",
        "residence_country": "GB",
        "email": "john.doe@mail.com"
    }'
*/



/*
 let response= await axios.post("https://api.kycaid.com/applicants",{
  "type": "PERSON",
},{
    headers: {
        'Authorization': 'Token 7c86e3a9075775409a18f476db2a0f731d73',
        'content-type': 'application/json'
    }})
  console.log(response);


  let response2= await axios.post("https://api.kycaid.com/forms/46ab44c505fe654962184fb666264c80cb31/urls",{
    "applicant_id": response.applicant_id,
    "external_applicant_id": "0xxx",
    "redirect_url": "https://torkpad.io/success-kyc"
    },{
      headers: {
          'Authorization': 'Token 7c86e3a9075775409a18f476db2a0f731d73',
          'content-type': 'application/json'
      }})
  
      console.log(response2,'response2');
      
URL: https://api.kycaid.com/verifications/{verification_id}


*/
/*
let response= await axios.get("https://api.kycaid.com/verifications/89e015460beda548bb19b8a6205bf5e3cc44",{
    headers: {
        'Authorization': 'Token 7c86e3a9075775409a18f476db2a0f731d73',
        'content-type': 'application/json'
    }})
  console.log(response);
*/
  } catch (error) {
    console.log(error,'err kyc');
  }
  


}

const formatLocalDate = (unixTimestamp) => {
  const date = new Date(unixTimestamp * 1000);
  const options = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  };

  return new Intl.DateTimeFormat(navigator.language, options).format(date);
};


const getMyWalletAddress = async (blockchain,senderAddress) => {
  try {
  var result=await walletContract.methods.walletCompleted(blockchain).call({ from: senderAddress });
  return result;
  } catch (error) {
  console.log(error,'err');
  }

  } 
  


const getMyWalletInfo = async (blockchain,senderAddress) => {
  try {
  var result=await walletContract.methods.walletCompleted(blockchain).call({ from: senderAddress });
  if(result){
  setWalletAddress(result)
  }
  } catch (error) {
  //console.log(error,'err');
  }

  } 
  

  const getMyTotalContribution = async (saleAddress,senderAddress) => {
    try {
      var result=await(getMyContribution(saleAddress,senderAddress));
    if(result){
  const amount = fromWeiToNumber(result);
  setMyContributionAmount(amount);
}
    } catch (error) {
    //console.log(error,'err');
    }
  
    } 


const handleDataSubmit = () => {
    setEdit(false); // Callback function to close "edit" when data is submitted
    getMyWalletInfo(blockchainNetwork,account);
};


const handleAddToken = () => {
  if(active){
    const ethereum = window.ethereum;
    addTokenToMetamask(ethereum, data?.tokenAddress, data?.tokenSymbol,data?.tokenDecimals);
  }
  
  
};



return (


 <Fragment>









  <section className="pro-details padding-top padding-bottom">
 <div className="container">
<div className="pro-details__wrapper">
  

  <div className="pro-details__desc">
 <div className="row g-5">


 <div className="col-lg-4">

  <div className="list-group bg--primary-color mb-4">



<div className="pro-details__info">
<div className="row g-3">



<div className="col-sm-12">
<div className="pro-details__info-item"> 

<div className="container-custom">
<div className="avatar-custom">
<img src={data?.logoUrl} alt="avatar" />
</div>
<div className="details-custom">
<h2 className="name-custom">{data?.tokenName}</h2>
<div className="currency-custom">{data.tokenSymbol} / {data?.currency}</div>

<Badges data={data}/>


        



      </div>
</div>


    <div className="pool-details">



{data?.whitelistRegister &&
<div className="card1-header">
<b>Whitelist</b>


{isWhitelistAddress ? (
<>
<p>whitelisted <i className="fa-solid fa-check" style={{color: '#008000'}} /></p>
</>
) : (
<>
<p>Non-whitelisted <i className="fa-solid fa-xmark" style={{color: '#ff0000'}} /></p>
</>
)}
</div>
}



{data?.openTime==="TBA" ? (
<>
<div className="sale-message">
<p>The sale will open soon</p>
</div>
</>
) : data?.openTime * 1000 > Date.now() ? (
<>

</>
) : data?.closeTime * 1000 > Date.now() ? (
<>
</>
) : (
<>
<div className="sale-message">
<p>The sale is finished</p>
</div>
</>
)}

{data.kycRegister &&
<div className="kyc-message">
<span>This IDO requires KYC verification.</span>
<div className="text-center"><button  className="default-btn default-btn--small"  disabled={!account} onClick={(e) =>  verifyKYC()}>Verify KYC</button></div>
</div>
}

{data.buyChart &&
<BuyChartCards  buyLinks={data?.buyLinks} chartLinks={data?.chartLinks}/>
}

{data.walletRegister && edit &&
<WalletCards blockchain={data?.blockchain} onSubmit={handleDataSubmit} />
}





<div className="pool-details-footer">
<a href={HowToParticipate} target="_blank" rel="noreferrer">How to participate</a>
</div>
</div>


<ul className="project-infolist">

  <li className="project-infolist-item">
    <p className="project-infolist-name">1 {data.currency} = {calculateTokens(1,data.idoPrice)} {data.tokenSymbol}</p>
  </li>
  <li className="project-infolist-item">
    <p className="project-infolist-name">1 {data.tokenSymbol} = {data.idoPrice} {data.currency}</p>
  </li>


{Date.now() > data?.closeTime * 1000 &&
  <li className="project-infolist-item">
    <p className="project-infolist-name">Finished {data.closeTime>0 ?daysGone(data.closeTime):"TBA"} days ago</p>
    <p className="project-infolist-data">{isWhatPercentOf(cap,hardCap).toFixed()+'%'}</p>
  </li>
}


{data.walletRegister && active &&
  <li className="project-infolist-item">
    <p className="project-infolist-name">{data?.blockchain} Wallet</p>
    <p className="project-infolist-data">
{edit ? <i onClick={() => setEdit(!edit)} className="fa-solid fa-xmark" /> : <i onClick={() => setEdit(!edit)} className="fa-solid fa-edit" />}
</p>
  </li>
}

<li className="project-infolist-item">
    <p className="project-infolist-name">
    {data?.openTime * 1000 > Date.now() ? (
<>
Sale Starts In
</>
) : data?.closeTime * 1000 > Date.now() ? (
<>
Sale Ends In
</>
) : (
<>
</>
)}

    </p>
    <p className="project-infolist-data">
    {data?.openTime==="TBA" ? (
<>
</>
) : data?.openTime * 1000 > Date.now() ? (
<>
<MyTimer expiryTimestamp={new Date(data?.openTime*1000)} setIsTrigger={setIsTrigger} />
</>
) : data?.closeTime * 1000 > Date.now() ? (
<>
<MyTimer expiryTimestamp={new Date(data?.closeTime*1000)} setIsTrigger={setIsTrigger} />
</>
) : (
<>

</>
)}

</p>
  </li>

  <li className="project-infolist-item">
    <p className="project-infolist-name">
<span className="color--theme-color">
{numFormatter2(cap)} </span> / <span> {cap==="TBA"?cap:numFormatter(hardCap)} {data.currency}
</span>

    </p>

    <p className="project-infolist-data">
<span className="color--theme-color">
{numFormatter(calculateTokens(cap,data.idoPrice))} </span> / <span> {numFormatter(calculateTokens(hardCap,data.idoPrice))} {data.tokenSymbol}
</span>
</p>





  </li>
</ul>


<div className="project__item-amount mb-3">

 <div className="progress mb-2">
<div className="progress-bar" role="progressbar" style={{width: isWhatPercentOf(cap,hardCap)+'%'}} aria-valuenow={isWhatPercentOf(cap,hardCap)} aria-valuemin={0} aria-valuemax={100} />
 </div>


 {Date.now() > data?.openTime * 1000 &&   Date.now() < data?.closeTime * 1000 &&
 <div className="input-group">
<input type="text" className="form-control"  value={amount} onChange={(e) => onChangeHandler(e.target.value)} placeholder={0.00} style={{backgroundColor: '#02121d', borderColor: '#02121d', color: '#c1c7cb', paddingInlineEnd: '10px'}}/>
<span className="input-group-text" onClick={(e) => onChangeHandler(maxAmount)} style={{backgroundColor: '#02121d', color: 'rgba(193,199,203,.3)', borderColor: '#02121d'}}>Max</span>
 </div>
}



</div>


{account &&
<>
{data.walletRegister && !walletAddress ? (
  <div className="sale-message">
  <p>Please set your {data?.blockchain} wallet address.</p>
  </div>
  ) : (
    <>
  {Date.now() > data?.openTime * 1000 &&   Date.now() < data?.closeTime * 1000 &&
  <div className="text-center">
  {chainId===Number(REACT_APP_CHAIN_ID)?(
  <>
  {isLoading?(
  <button  className="default-btn default-btn--small" disabled> <i className="fas fa-spinner fa-spin"></i>  {approved?"Approve":"Contribute"}</button>
  ):(
  <button  disabled={!saleAddress || !account} className="default-btn default-btn--small"  onClick={(e) =>  approved?approveHandle():contributeHandle()}>{approved?"Approve":"Contribute"}</button>
  )}
  </>
  ):(
  <button  className="default-btn default-btn--small"  onClick={(e) =>  switchNetworkHandle()}>Switch Network</button>
  )}
  </div>
  }
  
  
  
    </>
  )}
</>
}













<div class="event-card">
  <div class="event-header">
    <i className="fa-solid fa-wifi event-icon" style={{color: '#ffffff'}} />
    <h2 class="event-title">  
{data.blockchain===bscNetwork?(
<>IDO and Distribution on BNB Chain</>
):(
<>IDO on BNB Chain, distribution on {data.blockchain.toUpperCase()}</>
)}
</h2>
</div>

  <table class="event-details">
    <tbody>
    {data.openRegTime !=="TBA" && data.openRegTime >0 &&
      <tr>
        <td class="event-details-label">📝 Register:</td>
        <td class="event-details-value">{getLocalTime(data?.openRegTime)} -  {getLocalTime(data?.closeRegTime)}</td>
      </tr>
}

      
      {data.fcfs !=="TBA" && data.fcfs &&
        <tr>
        <td class="event-details-label">🏃‍♂ FCFS:</td>
        <td class="event-details-value">{moment(data?.fcfs*1000).format('LLLL')}</td>
      </tr>
      }
     
<tr>
<td class="event-details-label">⏰ Start:</td>
<td class="event-details-value">
{data?.openTime==="TBA" ? (
<>
TBA
</>
) : (
<>
{moment(data?.openTime*1000).format('LLLL')}
</>
)}
</td>
</tr>

{data.closeTime !=="TBA" &&
<tr>
<td class="event-details-label">⏰ End:</td>
<td class="event-details-value">
<>
{moment(data?.closeTime*1000).format('LLLL')}
</>
</td>
</tr>
}

    </tbody>
  </table>
</div>











</div>





<div className="mt-3">
  <div className="pro-details__info-item">
    <div className="pro-details__info-title">
      <h6>Your Contribution</h6>
    </div>
    <div className="pro-details__info-content">



    <div class="event-card">
  <table class="event-details">
    <tbody>
   <tr>
        <td class="event-details-label">💰 Tokens Purchased:</td>
        <td class="event-details-value">{calculateTokens(contributeAmount,data?.idoPrice)} {data.tokenSymbol}</td>
      </tr>

    
      
      <tr>
        <td class="event-details-label">💲 Swap Amount:</td>
        <td class="event-details-value">{contributeAmount} {data.currency}</td>
      </tr>

     
<tr>
<td class="event-details-label">💸 Allocation:</td>
<td class="event-details-value">
{calculateTokens(maxAmount,data?.idoPrice)} {data.tokenSymbol}
</td>
</tr>


    </tbody>
  </table>
</div>


    </div>
  </div>

</div>





















</div>













</div>
</div>









</div>






</div>




















<div className="col-lg-8">
  <div className="pro-details__desc-content">
 <div data-bs-spy="scroll" data-bs-target="#pro-details-navlist" data-bs-smooth-scroll="true" data-bs-root-margin="0px 0px -60%" className="scrollspy-example" tabIndex={0}>
 {data.description &&
  <section id="pro-details-about">



    <div className="flex-container">
  <div className="flex-item">
    <div className="image-container">
      <img src={data.logoUrl} alt={data.tokenSymbol} className="image" />
    </div>
    <h2 className="title">{data.tokenName}</h2>
  </div>




  <div className="flex-item">
    <img src={networkIcon(data.blockchain)} alt="BNB Chain" className="icon" />




  </div>
</div>
<div className="mb_details">
{Parser(data.description)}
</div>

{!data.protection && !data.protectionType &&
<div className="mb_details">Due to the <a href={SafeGuardUrl} target="_blank" rel="noreferrer">SafeGuarded Launch Protocol's</a> regulations, your investment is protected in this sale.</div>
}

{data.protectionType ===IDORULES[0] &&
<div className="mb_details">Your investment is secure in this sale due to <a href={SecuredIDOUrl} target="_blank" rel="noreferrer">{data.protectionType}'s</a> rules.</div>
}
{data.protectionType ===IDORULES[1] &&
<div className="mb_details"><a href={AutonomyIDOUrl} target="_blank" rel="noreferrer">{data.protectionType}'s</a> rules prioritize your investment protection, putting the decision-making power in your hands.</div>
}
{data.protectionType ===IDORULES[2] &&
<div className="mb_details protection-note">Please note that there is no price protection for the {data.protectionType} sale. Investors should thoroughly research and consider the risks involved before participating.</div>
}




  <ul className="social mb_details">

  {data.website &&
  <li className="social__item">
 <a href={data.website} target="_blank" rel="noopener noreferrer" className="social__link"><i className="fa-solid fa-globe" /></a>
  </li>}

{data.twitter &&
  <li className="social__item">
 <a href={data.twitter} target="_blank" rel="noopener noreferrer" className="social__link"><i className="fab fa-twitter" /></a>
  </li>
 }{data.discord &&
  <li className="social__item">
 <a href={data.discord} target="_blank" rel="noopener noreferrer" className="social__link"><i className="fab fa-discord" /></a>
  </li>
 }{data.telegram &&
  <li className="social__item">
 <a href={data.telegram} target="_blank" rel="noopener noreferrer" className="social__link"><i className="fab fa-telegram" /></a>
  </li>
  }{data.telegramAnn &&
 <li className="social__item">
<a href={data.telegramAnn} target="_blank" rel="noopener noreferrer" className="social__link"><i className="fab fa-telegram" /></a>
 </li>
 }{data.instagram &&
  <li className="social__item">
 <a href={data.instagram} target="_blank" rel="noopener noreferrer" className="social__link"><i className="fab fa-instagram" /></a>
  </li>
  }{data.linkedin &&
  <li className="social__item">
 <a href={data.linkedin} target="_blank" rel="noopener noreferrer" className="social__link"><i className="fab fa-linkedin-in" /></a>
  </li>
  }{data.facebook &&
  <li className="social__item">
 <a href={data.facebook} target="_blank" rel="noopener noreferrer" className="social__link"><i className="fab fa-facebook-f" /></a>
  </li>
  }
  {data.medium &&
  <li className="social__item">
 <a href={data.medium} target="_blank" rel="noopener noreferrer" className="social__link"><i className="fab fa-medium-m" /></a>
  </li>
 }
{data.reddit &&
  <li className="social__item">
 <a href={data.reddit} target="_blank" rel="noopener noreferrer" className="social__link"><i className="fab fa-reddit" /></a>
  </li>
 }
  {data.whitepaperUrl &&
  <li className="social__item">
 <a href={data.whitepaperUrl} target="_blank" rel="noopener noreferrer" className="social__link"><i className="fa-solid fa-file" /></a>
  </li>}


  


  {data.metamask && active &&
  <li className="social__item">
 <button class="metamask-button" onClick={(e) =>  handleAddToken()}><img src={metamaskImg} alt="Metamask" className="metamask-icon"/>Add to Metamask</button>
  </li>
}




</ul>


<div className="projects-details-container">
  <h2 className="projects-details-heading">Pool Details</h2>
  <ul className="projects-details-list">
    <li className="projects-details-item">
      <div className="projects-details-label">Access Type:</div>
      <div className="projects-details-value">
        
        
      

<>
  {data?.accessType === LEVELS ? (
    <>
      {data?.accessType}
    </>
  ) : data?.saleType === PRIVATE ? (
    <>
      {data?.accessType}
    </>
  ) : (
    <>
      {data?.saleType}
    </>
  )}
</>

      
      
      </div>
    </li>
    <li className="projects-details-item">
      <div className="projects-details-label">Hard Cap:</div>
      <div className="projects-details-value">${data?.hardCap}</div>
    </li>
    
    <li className="projects-details-item">
      <div className="projects-details-label">Swap Rate:</div>
      <div className="projects-details-value">1 {data?.tokenSymbol} = ${data?.idoPrice} | {calculateTokens(1,data?.idoPrice)} {data?.tokenSymbol} per 1 {data?.currency}</div>
    </li>
    <li className="projects-details-item">
      <div className="projects-details-label">Start/End:</div>
      <div className="projects-details-value">
{data?.openTime==="TBA" ? (
<>
TBA
</>
) : (
<>
{getLocalTime(data?.openTime)} -  {getLocalTime(data?.closeTime)}
</>
)}
</div>
    </li>

{data.fcfs &&
    <li className="projects-details-item">
    <div className="projects-details-label">FCFS Opens:</div>
    <div className="projects-details-value">{getLocalTime(data?.fcfs)}</div>
  </li>
}


{data.baseAllocation && data.accessType===LEVELS &&
  <li className="projects-details-item">
  <div className="projects-details-label">Base Allocation:</div>
  <div className="projects-details-value">{data?.baseAllocation}</div>
  </li>
}


{data.minAllocation && data.maxAllocation && data.accessType===FCFS &&
  <li className="projects-details-item">
  <div className="projects-details-label">Allocation:</div>
  <div className="projects-details-value">Minimum = {data?.minAllocation} - Maximum = {data?.maxAllocation}</div>
  </li>
}

</ul>
</div>






<div className="projects-details-container">
    <div className="price-container">
    <h2 className="projects-details-heading">Price</h2>

    
    
    <div className="price-links">
    {data?.coingecko &&
    <a href={`https://www.coingecko.com/en/coins/`+data?.coingecko} rel="noopener noreferrer nofollow" target="_blank">
    <img src={coingecko} className="price-link-image" />
    </a>
    }


    {data?.coinmarketcap &&
    <a href={data?.coinmarketcap} rel="noopener noreferrer nofollow" target="_blank">
    <img src={coinmarketcapImg} className="price-link-image" />
    </a>
}


    </div>
    </div>






  <ul className="projects-details-list">
    <li className="projects-details-item">
      <div className="projects-details-label">IDO:</div>
      <div className="projects-details-value">${data?.idoPrice}</div>
    </li>
    <li className="projects-details-item">
      <div className="projects-details-label">Listing:</div>
      <div className="projects-details-value">${data?.listingPrice}</div>
    </li>  

    




    {ath >0 &&
    <li className="projects-details-item">
      <div className="projects-details-label">ATH:</div>
      <div className="projects-details-value">
      <PercentageChange current={ath} previous={data?.idoPrice} />
      </div>
    </li>
}

{currentPrice >0 &&
  <li className="projects-details-item">
      <div className="projects-details-label">Current:</div>
      <div className="projects-details-value"> 
<PercentageChange current={currentPrice} previous={data?.idoPrice} /></div>
</li>
}

  </ul>
</div>
<div className="projects-details-container">
  <h2 className="projects-details-heading">Token</h2>
  <ul className="projects-details-list">
    <li className="projects-details-item">
      <div className="projects-details-label">Token:</div>
      <div className="projects-details-value">{data?.tokenName} ({data?.tokenSymbol}) on {data.blockchain.toUpperCase()} Chain</div>
    </li>
    {data.totalSupply >0 &&
    <li className="projects-details-item">
      <div className="projects-details-label">Total Supply:</div>
      <div className="projects-details-value">{data?.totalSupply} {data?.tokenSymbol}</div>
    </li>
    }
   {data.initialSupply >0 &&
    <li className="projects-details-item">
      <div className="projects-details-label">Initial Supply:</div>
      <div className="projects-details-value">{data?.initialSupply} {data?.tokenSymbol}</div>
    </li>
  }
    {data.listingDetails &&
    <li className="projects-details-item">
      <div className="projects-details-label">Token Listing:</div>
      <div className="projects-details-value">{data?.listingDetails}</div>
    </li>
  }
    {data.tokenAddress &&
    <li className="projects-details-item">
      <div className="projects-details-label">Address [{data?.blockchain.toUpperCase()}]:</div>
      <div className="projects-details-value">
      
      <a href={getBlockchainLink(data?.blockchain,data?.tokenAddress,'address')} target="_blank" rel="noreferrer">{data?.tokenAddress}</a>
      
      
      </div>
    </li>
}








  </ul>
</div>
<div className="projects-details-container">
  <h2 className="projects-details-heading">Distribution</h2>
  <ul className="projects-details-list">
    <li className="projects-details-item">
      <div className="projects-details-label">Distribution:</div>
      <div className="projects-details-value">{data?.distribution}</div>
    </li>
    <li className="projects-details-item">
      <div className="projects-details-label">Vesting:</div>
      <div className="projects-details-value">{data?.vesting}</div>
    </li>
  </ul>
</div>

{/*
<VestingTableCards/>
<WhitelistCards/>
*/}














</section>
 }

 </div>
  </div>
</div>

 </div>
  </div>



</div>
 </div>
  </section>


</Fragment>

)
}

export default Details
