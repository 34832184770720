import { InjectedConnector } from "@web3-react/injected-connector";
import { NetworkConnector } from "@web3-react/network-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import { WalletLinkConnector } from "@web3-react/walletlink-connector";
import { BscConnector } from '@binance-chain/bsc-connector';


const POLLING_INTERVAL = 10000


export const bscConnector = new BscConnector({ supportedChainIds: [56] })

export const network = new NetworkConnector({

  urls: { 
    1: "https://mainnet.infura.io/v3/YOUR_INFURA_API_KEY",
    56: "https://bsc-dataseed1.binance.org:443",
    97: "https://data-seed-prebsc-1-s1.binance.org:8545/",
    137: "https://rpc-mainnet.maticvigil.com",
    43114: "https://api.avax.network/ext/bc/C/rpc"
  },
 
  defaultChainId: 56,
  pollingInterval: POLLING_INTERVAL * 3
});

// MetaMask
const injected = new InjectedConnector({
  supportedChainIds: [1,56,97,137,43114] 
});

const walletconnect = new WalletConnectConnector({
  rpc: {
    1: "https://mainnet.infura.io/v3/YOUR_INFURA_API_KEY",
    56: "https://bsc-dataseed1.binance.org:443",
    97: "https://data-seed-prebsc-1-s1.binance.org:8545/",
    137: "https://rpc-mainnet.maticvigil.com",
    43114: "https://api.avax.network/ext/bc/C/rpc"
  },
  bridge: "https://bridge.walletconnect.org",
  qrcode: true,
  pollingInterval: POLLING_INTERVAL
});

const walletlink = new WalletLinkConnector({
  urls: {
    1: "https://mainnet.infura.io/v3/YOUR_INFURA_API_KEY",
    56: "https://bsc-dataseed.binance.org/",
    97: "https://data-seed-prebsc-1-s1.binance.org:8545/",
    137: "https://rpc-mainnet.maticvigil.com",
    43114: "https://api.avax.network/ext/bc/C/rpc",
    // Add more URLs for other networks if needed
  },
  appName: "TorkPad",
  supportedChainIds: [1,56,97,137,43114], // Add more chain IDs as needed
});

export const connectors = {
  injected: injected,
  walletConnect: walletconnect,
  coinbaseWallet: walletlink,
  bscwallet: bscConnector,
};








