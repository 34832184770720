import React from 'react';
import './Badges.css';
//Levels (FCFS)
const Badges = (props) => {
const { data } = props;
return (
<>

<div className="badges-group">
{data?.openTime==="TBA" ? (
<>
<span className="badge-group badge-secondary">Soon</span>
</>
) : data?.openTime * 1000 > Date.now() ? (
<>
<span className="badge-group badge-secondary">Soon</span>
</>
) : data?.closeTime * 1000 > Date.now() ? (
<>
<span className="badge-group badge-success">
Live
</span>
</>
) : (
<>
<span className="badge-group badge-danger">
<svg className="badge-icon" height="0.75em" width="0.75em" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
<circle cx={8} cy={8} r={8} />
</svg>
Closed
</span>

</>
)}



{data?.saleType.toLowerCase()==="private" ? (
<>
<span className="badge-group badge-secondary">
<svg stroke="currentColor" fill="currentColor" strokeWidth={0} viewBox="0 0 16 16" className="inline overflow-visible" height="0.75em" width="0.75em" xmlns="http://www.w3.org/2000/svg"><path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2zM5 8h6a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1z" /></svg>
{data.saleType}
</span>
</>
) : (
<>
<span className="badge-group badge-success">
  <svg className="badge-icon" height="0.75em" width="0.75em" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
        <path d="M11 1a2 2 0 0 0-2 2v4a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V9a2 2 0 0 1 2-2h5V3a3 3 0 0 1 6 0v4a.5.5 0 0 1-1 0V3a2 2 0 0 0-2-2zM3 8a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V9a1 1 0 0 0-1-1H3z" />
      </svg>
    {data.accessType}
    </span>
</>
)}










  








{data.whitelistRegister &&
<span className="badge-group badge-secondary">Register</span>
}


  {data.kyc &&
  <span className="badge-group badge-warning">KYC</span>
  }



</div>







</>
  );
};

export default Badges;
