import React, { useState, useEffect } from 'react';
import './PhishingWarning.css';
const PhishingWarning = () => {
    // Initialize showWarning state from localStorage or default to true
    const [showWarning, setShowWarning] = useState(
      localStorage.getItem('showWarning') !== 'false'
    );
  
    // Function to hide the warning tab when the close button is clicked
    const handleClose = () => {
      // Hide the warning tab
      setShowWarning(false);
    };
  
    // Update localStorage whenever the showWarning state changes
    useEffect(() => {
      localStorage.setItem('showWarning', showWarning);
    }, [showWarning]);
  
  return (

    showWarning && (

      <div className="phishing-container">
        <div className="phishing-warning-text">
          <strong>Phishing Warning:</strong> please make sure you're visiting{' '}
          <strong>https://torkpad.io</strong> - check the URL carefully
        </div>
        <button className="phishing-button" onClick={handleClose}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path
              d="M6.2253 4.81108C5.83477 4.42056 5.20161 4.42056 4.81108 4.81108C4.42056 5.20161 4.42056 5.83477 4.81108 6.2253L10.5858 12 4.81114 17.7747C4.42062 18.1652 4.42062 18.7984 4.81114 19.1889C5.20167 19.5794 5.83483 19.5794 6.22535 19.1889L12 13.4142L17.7747 19.1889C18.1652 19.5794 18.7984 19.5794 19.1889 19.1889C19.5794 18.7984 19.5794 18.1652 19.1889 17.7747L13.4142 12 19.189 6.2253C19.5795 5.83477 19.5795 5.20161 19.189 4.81108C18.7985 4.42056 18.1653 4.42056 17.7748 4.81108L12 10.5858L6.2253 4.81108Z"
              fill="currentColor"
            ></path>
          </svg>
        </button>
      </div>
  
  
      )

  );
};

export default PhishingWarning;
