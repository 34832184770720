import React from 'react';
import './VestingTable.css';

const VestingTable = () => {
  return (
<table className="table table-sm font-light">
  <caption className="caption">Released: 0 LUV / 0 LUV</caption>
  <thead>
    <tr>
      <th>Percent</th>
      <th>Date</th>
      <th>Amount</th>

      <th className="text-center">
      <button disabled type="button" className="btn btn-outline-secondary btn-sm">Claim all (0)</button>
      </th>

      <th>Status</th>


    </tr>
  </thead>
  <tbody>
    <tr>
      <td>10%</td>
      <td>Feb 25, 12:30 UTC</td>
      <td>0 LUV</td>
      <td>
        <div>Airdropped</div>
      </td>

      <td className="text-center">
      <button disabled type="button" className="btn btn-outline-light btn-sm">Not claimable</button>
      </td>

    </tr>
    <tr>
      <td>15%</td>
      <td>Mar 25, 12:00 UTC</td>
      <td>0 LUV</td>
      <td>
        <div>Airdropped</div>
      </td>
    </tr>
    <tr>
      <td>15%</td>
      <td>Apr 25, 12:00 UTC</td>
      <td>0 LUV</td>
      <td>
        <div>Airdropped</div>
      </td>
    </tr>
    <tr>
      <td>60%</td>
      <td>TBA</td>
      <td>0 LUV</td>
      <td>
        <div>Not airdropped</div>
      </td>
    </tr>
  </tbody>
</table>

  );
};

export default VestingTable;
