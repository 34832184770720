import React from 'react';
import WalletTronCard from './Wallet-Tron';
import WalletSolCard from './Wallet-SOL';
import WalletAptosCard from './Wallet-Aptos';
import WalletSuiCard from './Wallet-Sui';
import WalletTerraCard from './Wallet-TERRA';
import {aptosNetwork,solNetwork,tronNetwork,suiNetwork} from '../../../config';



function WalletComponent({ blockchain , onSubmit}) {
  let walletCard;

  if (blockchain === tronNetwork) {
    walletCard = <WalletTronCard blockchain={blockchain} onSubmit={onSubmit}/>;
  } else if (blockchain === solNetwork) {
    walletCard = <WalletSolCard blockchain={blockchain} onSubmit={onSubmit}/>;
  } else if (blockchain === aptosNetwork) {
    walletCard = <WalletAptosCard />;
  } else if (blockchain === suiNetwork) {
    walletCard = <WalletSuiCard />;
    /*
  } else if (blockchain === 'terra') {
    walletCard = <WalletAptosCard />;
    */
  } else {
    walletCard = <div>Unsupported blockchain</div>;
  }

  return (
    <div>
      {walletCard}
    </div>
  );
}

export default WalletComponent;
