import React,{Fragment} from 'react';

import { Link } from 'react-router-dom'
import {SiteName,TokenSymbol, TokenName,Telegram,kycCountry} from '../../config';
const tokenAddress = process.env.REACT_APP_TOKEN_ADDRESS;
function Faqs() {
return (
<Fragment>
<section className="faq padding-bottom shape-1r" id="faq">
  <div className="container">
    <div className="section-header section-header--middle">
      <div className="section-header__content">
        <div className="section-header__titlebar">
          <p className="section-header__subtitle"> Faq</p>
          <h2 className="section__header__title"> Common Queries</h2>
        </div>
      </div>
    </div>
    <div className="faq__wrapper" data-aos="fade-up" data-aos-duration={1000}>

      <div className="tab-content faq__content" id="pills-tabContent">
        <div className="tab-pane fade show active" id="pills-general" role="tabpanel" aria-labelledby="pills-general-tab">
          <div className="row gy-3 gx-5">
            <div className="col-lg-6">
              <div className="accordion accordion-flush" id="accordionGeneral1">
                <div className="accordion-item">
                  <h6 className="accordion-header" id="general1-headingOne">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#general1-collapseOne" aria-expanded="false" aria-controls="general1-collapseOne">
                      What Are the Advantages for {TokenSymbol} Token Holders? 
                    </button>
                  </h6>
                  <div id="general1-collapseOne" className="accordion-collapse collapse show" aria-labelledby="general1-headingOne" data-bs-parent="#accordionGeneral1">
                    <div className="accordion-body">Having {TokenSymbol} in your wallet provides you with numerous advantages.<br/>
                    &nbsp;- &nbsp;&nbsp;You can participate in our private and public sales by holding the {TokenSymbol}. <br/>
                    &nbsp;- &nbsp;&nbsp;If you stake your {TokenSymbol}, you can earn APY. The higher your Tier, the more allocation you will receive during the sales. 
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h6 className="accordion-header" id="general-headingTwo">
                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#general-collapseTwo" aria-expanded="false" aria-controls="general-collapseTwo">
                      
                    How does the {TokenName} Whitelist function? 

                    </button>
                  </h6>
                  <div id="general-collapseTwo" className="accordion-collapse collapse" aria-labelledby="general-headingTwo" data-bs-parent="#accordionGeneral1">
                    <div className="accordion-body">
                    For IDO participation, all {TokenSymbol} holders must register on the blockchain. You can register by clicking the Whitelist button on the project page on {SiteName}'s website. 
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h6 className="accordion-header" id="general-headingThree">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#general-collapseThree" aria-expanded="false" aria-controls="general-collapseThree">
                    
                    Will KYC be required for all projects? 

                    </button>
                  </h6>
                  <div id="general-collapseThree" className="accordion-collapse collapse" aria-labelledby="general-headingThree" data-bs-parent="#accordionGeneral1">
                    <div className="accordion-body">No, not always. The KYC requirement is determined independently by project leadership. 
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h6 className="accordion-header" id="general-headingFour">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#general-collapseFour" aria-expanded="false" aria-controls="general-collapseFour">
                    
                    Which currency do you accept as payment for IDOs? 

                    </button>
                  </h6>
                  <div id="general-collapseFour" className="accordion-collapse collapse" aria-labelledby="general-headingFour" data-bs-parent="#accordionGeneral1">
                    <div className="accordion-body">It varies depending on the project. To find out which currency the project uses, go to IDO Projects at <Link to={'/project'}>https://app.torkpad.io</Link>. Every project specifies the currency. 
                     </div>
                  </div>
                </div>
                <div className="accordion-item">
<h6 className="accordion-header" id="general-headingFive">
  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#general-collapseFive" aria-expanded="false" aria-controls="general-collapseFive">
  
  Is it necessary for me to register for each IDO? 

  </button>
</h6>
<div id="general-collapseFive" className="accordion-collapse collapse" aria-labelledby="general-headingFive" data-bs-parent="#accordionGeneral1">
  <div className="accordion-body">Yes, each IDO should be accessed individually. There is no restriction on the number of IDOs you can attend. Of course, this is subject to pre-registration for the whitelist and allocation.  
  </div>
</div>
</div>
<div className="accordion-item">
    <h6 className="accordion-header" id="general-headingSix">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#general-collapseSix" aria-expanded="false" aria-controls="general-collapseSix">
      
      Is it possible to stake after each IDO? 

      </button>
    </h6>
    <div id="general-collapseSix" className="accordion-collapse collapse" aria-labelledby="general-headingSix" data-bs-parent="#accordionGeneral1">
      <div className="accordion-body">
      No. As long as your {TokenSymbol} is staked, you are eligible for any IDOs. The staked amount will be matched with the linear participation system. 
      </div>
    </div>
  </div>
  <div className="accordion-item">
    <h6 className="accordion-header" id="general-headingSeven">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#general-collapseSeven" aria-expanded="false" aria-controls="general-collapseSeven">
      
      How do I get involved with IDOs? 

      </button>
    </h6>
    <div id="general-collapseSeven" className="accordion-collapse collapse" aria-labelledby="general-headingSeven" data-bs-parent="#accordionGeneral1">
      <div className="accordion-body">
&nbsp;- &nbsp;&nbsp;Buy {TokenName} on <a href={`https://pancakeswap.finance/swap?outputCurrency=${tokenAddress}`}>PancakeSwap</a> and meet our tiered criteria for guaranteed allocation. Please review TokenName <a href="/#tier">Tiers</a>. <br/>
&nbsp;- &nbsp;&nbsp;IDO Projects can be found at <Link to={'/project'}>https://app.torkpad.io</Link>. Every project specifies the currency.<br/>
&nbsp;- &nbsp;&nbsp;To participate in the upcoming IDO, fund your wallet with the currency indicated on the project. <br/>
&nbsp;- &nbsp;&nbsp;Check out all of the upcoming IDOs on <Link to={'/project'}>https://app.torkpad.io</Link> and register early to get guaranteed allocations. Some projects require KYC, and others may require that your wallet address be whitelisted. So, please do whatever is required. Once completed, KYC is applicable to all future projects. The IDO details page contains all of the information. <br/>
&nbsp;- &nbsp;&nbsp;At the time of IDO's launch, go to <Link to={'/project'}>https://app.torkpad.io</Link> and connect your wallet (prefunded with {TokenSymbol} and currency indicated on project) and then buy the allocated amount of tokens based on your tier or whatever maximum it will allow you. 
</div>
    </div>
  </div>
  <div className="accordion-item">
    <h6 className="accordion-header" id="general-headingEight">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#general-collapseEight" aria-expanded="false" aria-controls="general-collapseEight">
      
      Is my investment secure? 

      </button>
    </h6>
    <div id="general-collapseEight" className="accordion-collapse collapse" aria-labelledby="general-headingEight" data-bs-parent="#accordionGeneral1">
      <div className="accordion-body">
      &nbsp;- &nbsp;&nbsp; All projects launched on {TokenName} will be vetted and audited to protect {TokenName} investors from scams and rugs. <br/>

      &nbsp;- &nbsp;&nbsp;As an investor, you must ensure that you fully understand the project in which you intend to invest, conduct all necessary research, and only invest when you are confident.<br/>

      &nbsp;- &nbsp;&nbsp;{TokenName} will not provide you with financial advice or compel you to invest in any projects. <br/>

      &nbsp;- &nbsp;&nbsp;A general rule in the crypto space is to only invest what you can afford to lose. 
      </div>
    </div>
  </div>
  {/*
  <div className="accordion-item">
    <h6 className="accordion-header" id="general-headingNine">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#general-collapseNine" aria-expanded="false" aria-controls="general-collapseNine">
      
      How long do I have to fund my IDO allocation?

      </button>
    </h6>
    <div id="general-collapseNine" className="accordion-collapse collapse" aria-labelledby="general-headingNine" data-bs-parent="#accordionGeneral1">
      <div className="accordion-body">You will have 24 hours to complete your funding. 
      </div>
    </div>
  </div>
  */}
  <div className="accordion-item">
    <h6 className="accordion-header" id="general-headingTen">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#general-collapseTen" aria-expanded="false" aria-controls="general-collapseTen">
      
      Who is eligible to participate in the First Come, First Serve (FCFS) sale? 

      </button>
    </h6>
    <div id="general-collapseTen" className="accordion-collapse collapse" aria-labelledby="general-headingTen" data-bs-parent="#accordionGeneral1">
      <div className="accordion-body">Participation is open to all participants, including wildcard participants who were not drawn in the lottery. 
      </div>
    </div>
  </div>


              
         

              </div>
            </div>
            <div className="col-lg-6">
              <div className="accordion accordion-flush" id="accordionGeneral2">

             {/* <div className="accordion-item">
    <h6 className="accordion-header" id="general2-headingOne">
      <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#general2-collapseOne" aria-expanded="false" aria-controls="general2-collapseOne">
      What Causes Team Anonymity? 
      </button>
    </h6>
    <div id="general2-collapseOne" className="accordion-collapse collapse show" aria-labelledby="general2-headingOne" data-bs-parent="#accordionGeneral2">
      <div className="accordion-body">The most important characteristics of cryptocurrency, in our opinion, are anonymity, freedom, and decentralization.
Recent history has shown that there were numerous good decentralized crypto projects that performed admirably; however, they were regulated.
It is much more difficult, and sometimes impossible, to centralize  or regulate a project with an anonymous team. 
      </div>
    </div>
  </div>*/}

<div className="accordion-item">
  <h6 className="accordion-header" id="general2-headingTwo">
    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#general2-collapseTwo" aria-expanded="false" aria-controls="general2-collapseTwo">
    
    Is it required that I complete KYC in order to participate in IDO? 

    </button>
  </h6>
  <div id="general2-collapseTwo" className="accordion-collapse collapse" aria-labelledby="general2-headingTwo" data-bs-parent="#accordionGeneral2">
    <div className="accordion-body">
    KYC verification is required to participate in our Strong Holder Offerings by the {TokenName}. 



    </div>
  </div>
</div>
<div className="accordion-item">
  <h6 className="accordion-header" id="general2-headingThree">
    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#general2-collapseThree" aria-expanded="false" aria-controls="general2-collapseThree">
    What happens if a project's soft cap is not reached? 
    </button>
  </h6>
  <div id="general2-collapseThree" className="accordion-collapse collapse" aria-labelledby="general2-headingThree" data-bs-parent="#accordionGeneral2">
    <div className="accordion-body">If the soft cap is not reached, all participants will be able to reclaim their BNB that they put up for sale. This is all done on-chain, so there is no risk of bad actors stealing money if the soft cap is not met. 
    </div>
  </div>
</div>

<div className="accordion-item">
<h6 className="accordion-header" id="general2-headingFour">
<button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#general2-collapseFour" aria-expanded="false" aria-controls="general2-collapseFour">
Is it possible to withdraw or unstake before the deadline? 
</button>
</h6>
<div id="general2-collapseFour" className="accordion-collapse collapse" aria-labelledby="general2-headingFour" data-bs-parent="#accordionGeneral2">
<div className="accordion-body">Yes.
If you unstake before the deadline, you must pay a penalty.
The penalty rate changes depending on when you stake.
It all depends on how much time is still available. 
</div>
</div>
</div>
<div className="accordion-item">
<h6 className="accordion-header" id="general2-headingFive">
<button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#general2-collapseFive" aria-expanded="false" aria-controls="general2-collapseFive">
What are the countries that support KYC? 
</button>
</h6>
<div id="general2-collapseFive" className="accordion-collapse collapse" aria-labelledby="general2-headingFive" data-bs-parent="#accordionGeneral2">
<div className="accordion-body">A complete list of countries supported by our KYC system can be found at <a href={kycCountry}>link</a>.<br/>
<strong>Note : Please keep in mind that this list is subject to change and is constantly updated.</strong>
</div>
</div>
</div>

<div className="accordion-item">
<h6 className="accordion-header" id="general2-headingSix">
<button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#general2-collapseSix" aria-expanded="false" aria-controls="general2-collapseSix">
I am unable to verify my identity. So, what should I do?
</button>
</h6>
<div id="general2-collapseSix" className="accordion-collapse collapse" aria-labelledby="general2-headingSix" data-bs-parent="#accordionGeneral2">
<div className="accordion-body">First, check the current list of permitted countries to ensure that your country is included.
Then, determine which types of documents are accepted by your country's KYC system. 
If you are still having trouble completing a KYC, please join Telegram. <a href={Telegram} rel="noopener noreferrer" target="_blank">{Telegram}</a>
</div>
</div>
</div>

<div className="accordion-item">
    <h6 className="accordion-header" id="general2-headingSeven">
    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#general2-collapseSeven" aria-expanded="false" aria-controls="general2-collapseSeven">
    
    Why don't I see an allocation after I completed all of the steps? 
    
    </button>
    </h6>
    <div id="general2-collapseSeven" className="accordion-collapse collapse" aria-labelledby="general2-headingSeven" data-bs-parent="#accordionGeneral2">
    <div className="accordion-body">You could have skipped one of the steps.
Check that you used the correct link and that it was verified, especially for KYC.
See item 8 for more information.
Check to ensure that the same wallet was used for staking, registration, and KYC. 
    </div>
    </div>
    </div>
    
    <div className="accordion-item">
        <h6 className="accordion-header" id="general2-headingEight">
          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#general2-collapseEight" aria-expanded="false" aria-controls="general2-collapseEight">
          
          An administrator contacted me.
Is this true? 
    
          </button>
        </h6>
        <div id="general2-collapseEight" className="accordion-collapse collapse" aria-labelledby="general2-headingEight" data-bs-parent="#accordionGeneral2">
          <div className="accordion-body">
            
          NO.
Please do not provide any sensitive information (wallet address, passwords, seed phrase, private keys) or click on any links sent to you by anyone who first DMs you.
Admins will never contact you first.
You are most likely conversing with an imposter/scammer.
Please double-check the admin's name on the Telegram member's list.
A star should appear next to the name on a PC, and a tag should appear on a phone (e.g. CEO, COO, admin, mod, ambassador etc.) <br/>
Begin a new chat and ensure that you are redirected to the same page.
Otherwise, report the user and block them.
If you come across the impersonator in the Telegram group, please notify an admin by posting the @handle in the main chat. <br/>
If we want to chat with you privately, we will mention it in the main chat.
You can also send us private messages in DMs. 
    
    
          </div>
        </div>
      </div>
    
    
      <div className="accordion-item">
    <h6 className="accordion-header" id="general2-headingNine">
    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#general2-collapseNine" aria-expanded="false" aria-controls="general2-collapseNine">
    
    How many {TokenSymbol} are required to participate? 
    
    </button>
    </h6>
    <div id="general2-collapseNine" className="accordion-collapse collapse" aria-labelledby="general2-headingNine" data-bs-parent="#accordionGeneral2">
    <div className="accordion-body">You can find the tier system <Link to={'/#tier'}>here</Link>.
    </div>
    </div>
    </div>
    
    
    <div className="accordion-item">
    <h6 className="accordion-header" id="general2-headingTen">
    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#general2-collapseTen" aria-expanded="false" aria-controls="general2-collapseTen">
    
    How long do I have to fund my IDO allocation?
    
    </button>
    </h6>
    <div id="general2-collapseTen" className="accordion-collapse collapse" aria-labelledby="general2-headingTen" data-bs-parent="#accordionGeneral2">
    <div className="accordion-body">You will have 24 hours to complete your funding. 
    </div>
    </div>
    </div>
    



              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

</Fragment>
)
}

export default Faqs


//https://docs.binstarter.io/
//https://docs.starter.xyz/
//https://brandpad.finance/
//https://kccpad.io/
//https://docs.nbox.io/en/roadmap
