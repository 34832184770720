import React,{Fragment,useState } from 'react';
import events from "../events";
import { Calendar, momentLocalizer,Views } from "react-big-calendar";
import moment from "moment";
import Modal from 'react-modal'; // Import react-modal
import "react-big-calendar/lib/css/react-big-calendar.css";
import './calendar.css';


const localizer = momentLocalizer(moment);
Modal.setAppElement('#root'); // Set the app element for react-modal
const MyCalendar = () => {
  const [selectedEvent, setSelectedEvent] = useState(null);

  const [isModalOpen, setIsModalOpen] = useState(false);

  function CustomEvent({ event }) {
    return (
      <div>
        <img src={event.image} alt="Event" style={{ marginRight: '5px', width: '20px', height: '20px' }} />
        {event.title}
      </div>
    );
  }

 // Function to handle event selection and open the modal
 const handleEventSelect = (event) => {
  setSelectedEvent(event);
  setIsModalOpen(true);
};

// Function to close the modal
const closeModal = () => {
  setSelectedEvent(null);
  setIsModalOpen(false);
};




return (
  <Fragment>


<section className="leaderboard padding-top padding-bottom">
  <div className="container">
    <div className="leaderboard__wrapper">
    <Calendar
      events={events}
      localizer={localizer}
      startAccessor="start"
      endAccessor="end"
      defaultView={Views.MONTH}
      views={['month']}
      style={{ height: 600 }}
      onSelectEvent={handleEventSelect}
      components={{
        event: CustomEvent, // Use the custom event component
      }}
    />
     
    </div>
  </div>











 {/*
 
 <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Event Details"
      >
        {selectedEvent && (
          <div>
            <h3>{selectedEvent.title}</h3>
            <p>Start: {selectedEvent.start.toLocaleString()}</p>
            <p>End: {selectedEvent.end.toLocaleString()}</p>
            <button onClick={closeModal}>Close</button>
          </div>
        )}
      </Modal>
*/}




</section>

</Fragment>
)
}

export default MyCalendar