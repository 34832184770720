import React,{useState, useEffect} from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import {getPartnerList} from '../../utils/contractHelpers';

const Partner = () => {

  const [data, setData] = useState([]);


  useEffect(() => {
    const getPartners = async () => {
      const result = await getPartnerList();
      setData(result);
    };
   
   
    getPartners();
   }, []);
   
   
   





return (

    <section className="team padding-bottom shape-1r" id="partners">
    <div className="container">
      <div className="section-header section-header--middle">
        <div className="section-header__content">
          <div className="section-header__titlebar">
            <p className="section-header__subtitle"> Partners</p>
            <h2 className="section__header__title">Partners & Backers</h2>
          </div>
        </div>
      </div>



  
          <div className="roadmap__wrapper">
      <div className="swiper roadmap__slider">
      <Swiper

className="swiper-wrapper"

      modules={[Navigation]}
      spaceBetween={20}
      slidesPerView={1}
      speed={1200}
      loop={true}


   autoplay={{delay:3500,disableOnInteraction:false,}}
   navigation={{nextEl:".roadmap__slider-next",prevEl:".roadmap__slider-prev",}}
      breakpoints={{
        576: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        992: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
        1200: {
          slidesPerView: 4,
          spaceBetween: 24,
        },
        
      }}
      
    >



{data.map((item, index) => (
<SwiperSlide key={index} className="swiper-slide">
<div className="roadmap__item" data-aos="fade-up" data-aos-duration={1500}>
  <div className="roadmap__item-inner roadmap__item-inner--vertical-line">
    <div className="roadmap__item-year">
    <a target="_blank" rel="noopener noreferrer" href={item.website}><img src={item.logoUrl} alt={item.name} /></a>
    </div>
   
  </div>
</div>
</SwiperSlide>
))}


        </Swiper>
      </div>
      <div className="roadmap__slider-prev"><i className="fas fa-angle-left" /></div>
      <div className="roadmap__slider-next"><i className="fas fa-angle-right" /></div>
    </div>



    </div>
  </section>








)
}

export default Partner
