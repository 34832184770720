import React,{Fragment,useState,useEffect} from 'react';
import { Link } from 'react-router-dom'
import logoImg from '../../assets/images/logo/soon.png';
import bannerImg from '../../assets/images/igo/item/01.jpg';
import web3 from "../../utils/web3";

import {isWhatPercentOf,getPoolData,fromWeiToNumber,numFormatter,getContractAddress,networkIcon} from '../../utils/contractHelpers';


function ProjectCard({item}) {
const [cap, setCap] = useState(item?.cap);
const [participent, setCarticipent] = useState(item?.participent);
const [hardCap, setHardCap] = useState(item?.hardCap.replace(",",""));
useEffect(() => {
if(item && item.cap <1){
getPoolInfo(item.saleAddress);
}
}, [item])

const getPoolInfo = async (saleAddress) => {
if(!saleAddress) return;
try {
var result=await getPoolData(saleAddress);
setCap(fromWeiToNumber(result[0]));
} catch (error) {
//console.log(error,'errorerror');
}
} 

return (
<Fragment>

<div className="project__item project__item--completed1">
<div className="project__item-inner">
<div className="project__item-thumb">
<img src={item.bannerUrl? item.bannerUrl:bannerImg} alt="IGO cover" />
<span className="badge">
<img src={networkIcon(item.blockchain)} alt="BlockChain" />
</span>
  </div>
  <div className="project__item-content">
    <div className="project__item-top">
      <div className="project__item-author">
        <a href={void(0)}><img src={item.logoUrl?item.logoUrl:logoImg} alt="author" /></a>
        <h4>{item.tokenName}</h4>
      </div>
    </div>
    <div className="project__item-middle">
      <div className="project__item-amount">
        <p>Raised Ammount</p>
        <h6><span className="color--theme-color">{cap}</span> / <span>{cap==="TBA"?cap:hardCap} {item.currency}</span>
        </h6>
        <div className="progress">
        <div className="progress-bar" role="progressbar" style={{width: isWhatPercentOf(cap,hardCap)+'%'}} aria-valuenow={isWhatPercentOf(cap,hardCap)} aria-valuemin={0} aria-valuemax={100} />
        </div>
      </div>
    </div>
    <div className="project__item-bottom">
      <Link to={item.saleAddress?'/pool/'+item.params:"#"} className="default-btn default-btn--small">View Details</Link>
    </div>
  </div>
</div>
</div>

  </Fragment>
)
}

export default ProjectCard
