import React, { useState } from 'react';
import './Wallet.css';

const ExternalWalletForm = () => {
  const [walletAddress, setWalletAddress] = useState('');
  const [isValidAddress, setIsValidAddress] = useState(true);

  const handleChange = (e) => {
    const address = e.target.value;
    setWalletAddress(address);
    setIsValidAddress(validateAddress(address));
  };

  const validateAddress = (address) => {
    // Add your SOL address validation logic here
    // Return true if the address is valid, false otherwise
    const solAddressRegex = /^SOL[a-zA-Z0-9]{39}$/;
    return solAddressRegex.test(address);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Add your form submission logic here
    if (isValidAddress) {
      // Address is valid, proceed with form submission
      console.log('Submitting form:', walletAddress);
    } else {
      // Address is invalid, display error message or take appropriate action
      console.log('Invalid SOL address:', walletAddress);
    }
  };

  return (
    <div className="external-wallet-form">
      <div className="external-wallet-bg">
        <form className="external-wallet-content" onSubmit={handleSubmit}>
          <div className="external-wallet-group">
            <label className="external-wallet-label" htmlFor="externalWallet">
              Your TERRA wallet address for distribution
            </label>
            <input
              type="text"
              id="externalWallet"
              className={`external-wallet-input ${isValidAddress ? '' : 'invalid'}`}
              placeholder="TERRA wallet"
              value={walletAddress}
              onChange={handleChange}
              required
            />
            {!isValidAddress && <div className="external-wallet-feedback">Invalid TERRA address.</div>}
            <small className="external-wallet-text">
              We recommend using the{' '}
              <a href="https://phantom.app/" target="_blank" rel="noreferrer">
                Terra Station
              </a>{' '}
              (mobile, desktop, chrome extension) or{' '}
              <a href="https://trustwallet.com/" target="_blank" rel="noreferrer">
                Trustwallet
              </a>{' '}
              (mobile) wallets.{' '}
              <a
                href="https://docs.trustpad.io/trustpad/how-to-participate-in-an-ido/how-to-set-up-a-solana-wallet-and-link-it-to-trustpad"
                target="_blank"
                rel="noreferrer"
              >
                Read more in our docs
              </a>
            </small>
          </div>
          <div className="external-wallet-check">
            <input type="checkbox" id="acceptExternal"  className="external-wallet-checkbox" required />
            <label htmlFor="acceptExternal" className="external-wallet-check-label">
              I confirm providing a valid TERRA address. I understand that giving a wrong wallet can result in funds loss.
            </label>
          </div>
          <button type="submit" className="external-wallet-button default-btn default-btn--small">
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default ExternalWalletForm;
