import {SiteName,IgoForm} from '../../config';
import React,{Fragment} from 'react';
import bannerthumb from '../../assets/images/banner/banner-thumb/01.png';
import shape3 from '../../assets/images/banner/banner-thumb/shape3.png';
import shape1 from '../../assets/images/banner/banner-thumb/shape1.png';
import bg from '../../assets/images/banner/bg.png';






import { Link } from 'react-router-dom'
function Banner() {
return (
<Fragment>
<section className="banner" id="home" style={{ backgroundImage: 'url(' + bg + ')' }}>
    <div className="container">
      <div className="banner__wrapper">
        <div className="row g-5 align-items-center">
          <div className="col-lg-6">
            <div className="banner__content" data-aos="fade-right" data-aos-duration={1000}>
              <h1 className="text-uppercase">WEB 3.0 <br /> Launchpad <br />for IGO &amp; IDO</h1>
              <p>{SiteName} is a decentralized multi-chain fundraising platform that allows ventures to raise funds while also providing early stage investors with security.</p>
              <Link to="/project" className="default-btn"><span>VIEW Projects</span></Link>
              &nbsp; &nbsp; 
              <a href={IgoForm} className="default-btn" target="_blank" rel="noopener noreferrer"><span>Apply to launch</span></a>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="banner__thumb" data-aos="fade-up" data-aos-duration={1000}>
              <img src={bannerthumb} alt="banner thumb" />
              <div className="shape-2">
                <img src={shape3} alt="Banner Shape" />
              </div>
              <div className="shape-3">
                <img src={shape1} alt="Banner Shape" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>




</Fragment>
)
}
export default Banner
